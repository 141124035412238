import { gql } from "apollo-angular";

export const GET_CSO_REQUIRED_TRUE = gql`
query getCSORequiredActive($key: String!, $orgId:[UUID]!) {
  claims(
    where: { and: [{ key: { eq: $key } }, { parentId: { in: $orgId } }, { value: { eq: "true" } }] }
  ) {
    nodes {
      id
      key
      value
      parentId
      dateCreated
    }
  }
}
`;

export interface Claim {
  id: string;
  key: string;
  value: string;
  parentId: string
  dateCreated: string
}

export interface ClaimsResponse {
  claims: {
    nodes: Claim[];
  };
}