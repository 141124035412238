import { gql } from "apollo-angular";

/**
 * Gets the organization data, here you'll have 
 * the organization name and ID of the user.
 * You're gonna use these ones for lookup the
 * claim using the orgId if response is null go to: 
 * mutations/create-temp-license-for-org.query.graphql.ts
 * 
 */

export const GET_ORGANIZATION_LICENSES = gql`
 query validateOrgLicenses($orgIds: [UUID]!) {
  license: claims(
    where: { key: { contains: "CSO_Lic" }, and: [{ parentId: { in: $orgIds } }] },
    order: { dateCreated: DESC }
  ) {
    nodes {
      id
      parentId
      key
      value
      dateExpire
      dateStart
      dateCreated
    }
    totalCount
  }
}
`;

export const GET_ORG_NAME_BY_ORG_ID = gql`
query getOrgNameByOrgId($orgIds: [UUID]!) {
  organizations(where: {id: {in:$orgIds}}) {
    nodes {
      id
      orgName
    }
  }
}
`;

export interface LicensesResponse {
  license: LicensesData;
}

export interface LicensesData {
  nodes?: LicensesNodes[];
  totalCount: number;
}
export interface LicensesNodes {
  id: string;
  parentId: string;
  key: string;
  value: string | LicenseInformation ;
  dateExpire?: string;
  dateStart: string;
  dateCreated: string;
}
export interface NodesEntity {
  id: string;
  parentId: string;
  key: string;
  value: string;
  dateExpire: string;
  dateStart: string;
}

export interface Organization {
  id: string;
  orgName: string;
}

export interface OrganizationsData {
  organizations: {
    nodes: Organization[];
  };
}


export interface LicenseInformation {
  CreatedBy: string;
  LicType: string;
  PurchasedBy: string;
  PurchasedOn: string;
  TransactionId?: string;
}