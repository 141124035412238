<div
*ngIf="runningEnvironment !== 'prod'"
class="subscriptions"
>

<!-- Subscriptions Section Title -->
<h5>Subscriptions</h5>

<!-- Subscriptions - Normal View -->
<ng-container *ngIf="userAppsSubscriptions.length; else NoSubscriptionsView">

  <!-- Apps Subscription Images Container -->
  <div class="apps-images-container">

    <!-- Apps Subscription Iterator List -->
    <ng-container *ngFor="let appService of userAppsSubscriptions">

      <!-- App Logo -->
      <img
        [src]="displayAppIconFor(appService.name)"
        alt="{{appService.name}} Logo"
        height="auto"
        width="auto"
      />

    </ng-container>

  </div>

</ng-container>

<!-- Subscriptions - No Results View -->
<ng-template #NoSubscriptionsView>

  <!-- No Subscriptions View - Container -->
  <div class="no-subscriptions-view">

    <span class="no-subscriptions-view-description">
      This user does not have access to any LCPtracker application
    </span>

  </div>

</ng-template>

</div>