<!--IF FOT ANY REASON THE API RESPONSE WITH A ERROR WE DESTROY THE COMPONENT TO AVOID
TEMPLATE ISSUES AND BAD USER EXPERIENCE-->
<section *ngIf="(isServerError$ | async) === false" class="notification-area">
  <ng-container *ngIf="notifications && this.countNotificationsUnRead() !== 0 || (showAllNotifications$ | async)">
    <div *ngIf="(showNotificationBanner$ | async)" class="notification-card">
      <div class="notification-card-header">
          <h5 class="title">
            @if(notifications!.length){
              Notifications
            }@else {
              No Notifications!
            }
          </h5>
          <button (click)="closeNotification()" class="close-button">
              <mat-icon class="property-icon" aria-hidden="false" aria-label="Close button">close</mat-icon>
          </button>
      </div>
      <div class="notification-card-body">
        @if(notifications!.length > 0){
          <ng-container *ngFor="let notification of notifications; let i = index;" >
            <ng-container *ngIf="(showAllNotifications$ | async) === false; else showUnread">
              <div *ngIf="!notification.isRead" class="notification" >
                <div class="notification-body">
                  <div *ngIf="notification.richDescription?.json else elseShortText" class="notification-title" [innerHTML]="notification.richDescription?.json| ContenfultToHtmlPipe">
                    <!--WE ADD THE TEXT USING THE RICH TEXT AND THE CONTENTFUL PIPE-->
                  </div>
                  <ng-template #elseShortText>
                    <div class="notification-title">
                      <p *ngIf="notification.shortDescription" >{{notification.shortDescription}}</p>
                    </div>
                  </ng-template>
                  <div class="notification-actions">
                    <button (click)="markNotificationAsReadInUnReadMenu(notification.title)" mat-button color="primary">
                      <mat-icon>mark_email_read</mat-icon>
                      Mark as Read
                    </button>
                  </div>
                </div>
                <div class="notification-footer">
                  <a
                  *ngIf="notification.labelButton && notification.attachmentButton"  
                  target="_blank" [href]="notification.attachmentButton.url" mat-raised-button color="primary">
                    {{notification.labelButton}}
                  </a>
                </div>
              </div>
            </ng-container>
            <ng-template #showUnread>
              <div class="notification" >
                <div class="notification-body">
                  <div *ngIf="notification.richDescription?.json else elseShortText" class="notification-title" [innerHTML]="notification.richDescription?.json| ContenfultToHtmlPipe">
  
                  </div>
                  <ng-template #elseShortText>
                    <div class="notification-title">
                      <p *ngIf="notification.shortDescription" >{{notification.shortDescription}}</p>
                    </div>
                  </ng-template>
                  <div class="notification-actions">
                    <button *ngIf="!notification.isRead" (click)="markNotificationAsReadInUnAllMenu(notification.title)" mat-button color="primary">
                      <mat-icon>mark_email_read</mat-icon>
                      Mark as Read
                    </button>
                  </div>
                </div>
                <div class="notification-footer">
                  <a
                  *ngIf="notification.labelButton && notification.attachmentButton"  
                  target="_blank" [href]="notification.attachmentButton.url" mat-raised-button color="primary">
                    {{notification.labelButton}}
                  </a>
                </div>
              </div> 
            </ng-template>
          </ng-container>
        }
          @else {
            <p class="no-notification-text">There are not notifications at the moment...</p>
          }
      </div>
  </div>
  </ng-container>
  <!-- CONDITIONAL BUTTON DISPLAY:
  1. Display the button if the user has not added all remaining notifications.
  2. Display the button if the notification menu for ALL is turned off.
  3. Display the button when the user is adding more notifications while marking them as read, decrementing the value for visual consistency. -->
  <ng-container *ngIf="extraNotificationLength > 0 && (showAllNotifications$ | async) === false && !hasAddedAllNotifications && (showNotificationBanner$ | async)">
    <div class="flex justify-end">
      <button (click)="showMoreNotifications()" mat-button color="primary">
        Show More Notifications
      </button>
    </div>
  </ng-container>
</section>


