import { gql } from "apollo-angular";

export const GET_LANDING_PAGE_BANNER = gql`
query getLandingBanner {
    userPortalLandingPageBannerCollection{
      items{
        name
        image{
          url
          title
          description
        }
      }
    }
}`;

export interface LandingImage {
    url: string;
    title: string;
    description: string;
}

export interface LandingBannerItems {
    name: string;
    image: LandingImage;
};

export interface LandingBannerCollection {
    items: LandingBannerItems[];
};

export interface LandingBannerResponse {
    userPortalLandingPageBannerCollection: LandingBannerCollection;
};