import { gql } from "apollo-angular";

/**
 * Mutation to create the temp license for the organization.
 * After we verify that the org has no license, because
 * we do not get answer from the claimByParentId, we have to create 
 * the claim using this mutation.
 * this would be the answer.
 * {
  "data": {
    "createClaim": {
      "data": {
        "id": "1c0d17ed-0532-4240-95ca-3dce3e9e2131",
        "parentId": "e8efdd0d-2b06-4da1-8c0a-81a7bf3eea3e",
        "key": "tempLic",
        "value": "LicType:'FREE', PurchasedBy: 'Mcdonalds', PurchasedOn:'10/31/2023'",
        "dateStart": "2023-10-31T07:36:00.000Z",
        "dateExpire": "2023-10-31T08:36:00.000Z",
        "dateCreated": "2023-11-08T22:31:24.641Z",
        "__typename": "Claim"
      },
      "errors": null
    }
  }
}

after this go to: grapql/get-user-in-org-by-id.query.graphql.ts

Number 2(optional)
 */
export const CREATE_TEMP_LICENSE_ORG_BY_ID = gql`
mutation createTempLicenseForOrg($createTempLicense: ClaimsInputModelInput!) {
  createClaim(input: $createTempLicense) {
    data {
      id
      parentId
      key
      value
      dateStart
      dateExpire
      dateCreated
      __typename
    }
    errors {
      message
    }
  }
}
`;

export interface Claim {
  id: string | null;
  parentId: string | null;
  key: string | null;
  value: string | null;
  dateStart: string | null;
  dateExpire: string | null;
  dateCreated: string | null;
}

export interface CreateLicenseClaimResponse {
  data: Claim;
}

export interface CreateTempLicenseInputModel {

  parentId: string | null;
  key: string | null;
  value: string | null;
  dateStart: string | null;
  dateExpire: string | null;
  isPublic: boolean | null;
}