<!-- No Mobile Support Banner -->

<ng-container *ngIf="showNotSupportBanner">
  <app-no-support-mobile></app-no-support-mobile>
</ng-container>

<div class="pr-2.56 pt-1.14 support-console-card-container" [hidden]="showNotSupportBanner">

  <h3 class="layout-title">Support Console</h3>
  <p class="layout-subtitle">IT tool with full administration of Fortify Users. Search for users, see agencies and contractors, check account states, unlock accounts, recovery codes and reset passwords and more...</p>

  <div class="flex justify-between w-full gap-8 search-container">
    <div class="mb-4 search-users">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Search</mat-label>
        <input #searchSupportInput id="search-support-console" matInput type="text" [formControl]="searchCriteria">
        <mat-icon matSuffix>search</mat-icon>
        <mat-hint class="text-sm search-validation-message" *ngIf="searchCriteria?.errors?.['minlength']">
          Please enter a more specific search query (at least two characters).
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="filter-search-user">
      <mat-form-field appearance="fill">
        <mat-icon class="mr-2" matPrefix>filter_alt</mat-icon>
        <mat-label>Please select the search criteria:</mat-label>
        <mat-select id="filter-support-console" [formControl]="filterSearchCriteria" disableRipple>
          <mat-option *ngFor="let value of FILTER_OPTIONS" [value]="value.criteria">
            {{value.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div [hidden]="!usersDataSignal()?.edges?.length">
    <mat-paginator
      #paginatorTop
      [hidePageSize]="!usersDataSignal()?.edges?.length"
      [pageSizeOptions]="[5,10,20]"
      [pageSize]="paginationStateSignal()!.pageSize"
      [pageIndex]="(combinedPageIndexSubject | async)"
      [length]="paginationStateSignal()!.totalElements"
      (page)="handlePage($event)"
      showFirstLastButtons="true"
      class="mb-5"
      aria-label="Select page">
    </mat-paginator>
  </div>


  <div *ngIf="!(isLoadingSignal()); else elseBlock">
    <mat-accordion multi="false">
        <ng-container *ngFor="let user of usersDataSignal()?.edges">
          <mat-expansion-panel [expanded]="(user.node.id === expandPanelAfterRequest)" (opened)="loadUserDataOnExpansionPanelOpen(user)">
            <mat-expansion-panel-header>
              <mat-panel-title class="grid w-full grid-cols-2">
                <div class="account-user-information">
                  <span class="name">{{user.node.firstName === '--' ? '' : user.node.firstName}} {{user.node.lastName === '--' ? '' : user.node.lastName}}</span>
                  <div class="flex items-center">
                    <span class="email">{{user.node.userName | shortText:40}}</span>
                  </div>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                <!-- Account Overview Header -->
                <div class="account-overview-header">
                  <div class="grid w-full grid-cols-3">
                    <span class="account-state" [ngClass]="(user.node.emailConfirmed) ? 'enabled' : 'disabled'">
                      <mat-icon>mail</mat-icon>
                      Email {{(user.node.emailConfirmed) ? 'Confirmed' : 'Not Confirmed'}}
                    </span>
                    <span class="account-state" [ngClass]="(user.node.twoFactorEnabled) ? 'enabled' : 'disabled'">
                      <mat-icon>key</mat-icon>
                      Two Factor {{(user.node.twoFactorEnabled) ? 'Enabled' : 'is not Enabled'}}
                      <mat-icon class="info" #tooltip="matTooltip"
                        [matTooltip]="twoFAtooltipText" matTooltipPosition="above" 
                        *ngIf="twoFAtooltipText && (user.node.twoFactorEnabled) && (expandPanelClicked === user.node.id)">info</mat-icon>
                    </span>
                    <ng-container *ngIf="lockedEnd(user.node.lockoutEnd); else accountEnabled">
                      <span class="account-state locked">
                        <mat-icon>lock</mat-icon>
                        Locked Out
                      </span>
                    </ng-container>
                    <ng-template #accountEnabled>
                      <span class="account-state enabled">
                        <mat-icon aria-hidden="false">check_circle</mat-icon>
                        Enabled
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- Account Overview Header -->
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="account-body">
              <!-- 1st Column - User Details -->
              <div class="user-detail-column">
                <app-user-detail *ngIf="expandPanelClicked === user.node.id"  
                  (newEmail)="updateUsersFromChild($event)" [user]="user"
                  (twoFactorIndicator)="showTwoFactorIndicator($event)"></app-user-detail>
              </div>
              <!-- 2nd Column - Login History -->
              <div class="login-history">
                <app-login-history-detail *ngIf="expandPanelClicked === user.node.id" [user]="user"></app-login-history-detail>
              </div>
              <!-- 3rd Column - Organizations -->
              <div class="organizations">
                <app-organization-detail [user]="user" ></app-organization-detail>
              </div>
              <!-- 4th Column - Actions and Subscriptions -->
              <div class="actions-and-subscriptions-column">
                <!-- Actions Section Container -->
                <div class="actions">
                  <app-actions-detail
                    *ngIf="expandPanelClicked === user.node.id"
                    [user]="user"
                    (expandPanel)="setExpandPanelAfterRequest($event)"
                  ></app-actions-detail>
                </div>
                <!-- Subscriptions Section Container -->
                <app-subscriptions-detail *ngIf="expandPanelClicked === user.node.id"  [subscriptions]="user.node.usersInOrg" ></app-subscriptions-detail>
                <app-providers-detail *ngIf="expandPanelClicked === user.node.id" [user]="user" ></app-providers-detail>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
  </div>

  <div [hidden]="!usersDataSignal()?.edges?.length">
    <mat-paginator
      [pageSizeOptions]="[5,10,20]"
      [pageSize]="paginatorTop.pageSize"
      [pageIndex]="(combinedPageIndexSubject | async)"
      [length]="this.paginationStateSignal()?.totalElements"
      (page)="handlePage($event)"
      showFirstLastButtons="true"
      class="mb-5"
      aria-label="Select page">
      </mat-paginator>
  </div>
  <ng-container *ngIf="!usersDataSignal()?.edges?.length  && !(isLoadingSignal())">
    <div class="no-results-banner">
      <mat-icon aria-hidden="false">search_off</mat-icon>
      <h5 class="title">No results found</h5>
      <p class="subtitle">Apologies, but we couldn't find any items that match your search criteria or category. Kindly review your search and category selections.</p>
      <button (click)="cleanPaginationAndSearch(true)" mat-button color="primary">Clear search criteria</button>
    </div>
  </ng-container>
  <ng-template #elseBlock>
    <div class="searching-results-banner">
      <mat-spinner class="my-1.14" [diameter]="60" ></mat-spinner>
      <h5 class="title">{{expandPanelAfterRequest ? 'Waiting for Response' : 'Searching Results'}}</h5>
      <p class="subtitle">{{expandPanelAfterRequest ? 'We are working on your request, this may take a moment' : 'Please wait while we search based on your entered criteria...'}}</p>
    </div>
  </ng-template>

</div>