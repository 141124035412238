import { Component, Inject, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../../../consts/consts';
import { Login, LoginHistoryEdge } from '../../../graphql/login-history.queries.graphql';
import { LoginHistoryService } from '../../../services/login-history.service';
import { MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT } from '../../users/users.component';
import { PaginationService } from 'src/app/core/services/pagination.service';

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.scss']
})
export class LoginHistoryComponent implements OnInit, OnDestroy {
  private readonly _paginationService = inject(PaginationService);
  private readonly _loginHistoryService = inject(LoginHistoryService);
  private _breakpointObserver = inject(BreakpointObserver);
  private _dialogRef = inject(MatDialogRef<LoginHistoryComponent>);

  /*Data and Pagination Variables*/
  loginHistoryGraphQL: LoginHistoryEdge[] = [];
  paginationStateSignal = this._loginHistoryService._paginationState;
  paginationVarsSignal = this._loginHistoryService._paginationVars;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  /* Flags */
  isLoadingSignal = this._loginHistoryService._isLoading;
  errorServerSignal = this._loginHistoryService._errorServer;
  shouldDialogBeClosed: boolean = false;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {name: string | null, email: string | null, userId: string, claimedUserName: string},
  ) {
  }

  ngOnInit(): void {
    this.initPaginationValues();
    this.getLoginHistoryData();
    this.forceCloseDialog();
  }

  /**
   * Init the values related to pagination and search text.
   * This method is called to restore initial values for pagination variables,
   * pagination state, and search text in the UsersService.
   */
  initPaginationValues(): void {
    // Init pagination variables to initial values
    this._loginHistoryService._paginationVars.set(INIT_PAGINATION_VARS);
    // Init pagination state to initial values
    this._loginHistoryService._paginationState.set(INIT_PAGINATION_STATE);
    // Init last page size change
    this._loginHistoryService._lastPageSizeChange.set(0);
  }

  /**
   * Calls the service to get a list of loginHistory based on the given User's id
   */
  getLoginHistoryData(): void {
    this._loginHistoryService.getLoginHistory('network-only', this.data.claimedUserName).then((res: Login) => {
      this.loginHistoryGraphQL = res.edges;
    }).catch(error => {
      console.error('Something went wrong getting Login History: ', error);
    });
  }

  /**
   * Validates the text in authType to define the text displayed in the badge
   * @param authType 
   * @returns 
   */
  validateAuthTypeMessage(authType: string): string {
    switch(authType) {
      case 'FAILED_PWD':
        return 'Password Failed';
      case 'FAILACODE':
        return '2FA Code Failed';
      case 'FAILED_OTP':
        return 'Email OTP Failed';
      case 'FAILRECVRY':
        return 'Recovery Code Failed';
      case 'LOCKEDOUT':
        return 'User Locked Out';
      default:
        return 'Login Successful';
    }
  }

  /**
   * Validates if authType contains the text 'FAILED' to define
   * the color of the badge and the icon
   * @param authType 
   * @returns 
   */
  validateAuthTypeBadgeColor(authType: string): boolean {
    if(authType.includes('FAIL') || authType.includes('LOCK'))
    {
      return true;
    }else{
      return false;
    }
  }

  /**
   * Monitors the screen width using BreakpointObserver and closes the dialog
   * if the screen width is below a certain threshold defined by MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT.
   */
  forceCloseDialog(): void {
    this._breakpointObserver
      .observe(`(max-width: ${MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT}px)`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.shouldDialogBeClosed = result.matches;
        if (this.shouldDialogBeClosed) {
          this._dialogRef.close(true); // Close the dialog if shouldDialogBeClosed is true
        }
      });
  }

  /****************************** PAGINATION METHODS **********************************/

  /**
   * Handles the pagination events integrated the global pagination service 
   * to calcute the correct pagination vars based on the page events
   */
  handlePage($event: PageEvent): void {
    let pageMovedToFirstOrLast = false;

    // SET NEW CURRENT PAGE SIZE
    if (this.paginationStateSignal()!.pageSize !== $event.pageSize) {
      const paginationSettings = this._paginationService.handlePageSizeChange($event);
      this._loginHistoryService._paginationVars.set(paginationSettings);
    }

    //FIRST PAGE
    if ($event.pageIndex === 0) {
      pageMovedToFirstOrLast = true;
      const paginationSettings = this._paginationService.goToFirstPage($event);
      this._loginHistoryService._paginationVars.set(paginationSettings);
    }

    //LAST PAGE
    if ($event.pageIndex == this._paginationService.getNumberOfPages(this.paginationStateSignal() ?? INIT_PAGINATION_STATE)) {
      pageMovedToFirstOrLast = true;
      this._loginHistoryService._lastPageSizeChange.set($event.pageSize);
      const paginationSettings = this._paginationService.goToLastPage($event);
      this._loginHistoryService._paginationVars.set(paginationSettings);
    }

    // PREVIOUS PAGE
    if ($event.previousPageIndex !== undefined && $event.pageIndex < $event.previousPageIndex && !pageMovedToFirstOrLast) {
      const paginationSettings = this._paginationService.handlePreviousPage($event, this.paginationStateSignal() ?? INIT_PAGINATION_STATE);
      this._loginHistoryService._paginationVars.set(paginationSettings);
    }

    // NEXT PAGE
    if ($event.previousPageIndex !== undefined && $event.pageIndex > $event.previousPageIndex && !pageMovedToFirstOrLast) {
      const paginationSettings = this._paginationService.handleNextPage($event, this.paginationStateSignal() ?? INIT_PAGINATION_STATE);
      this._loginHistoryService._paginationVars.set(paginationSettings);
    }

    this.getLoginHistoryData();
  }
  /****************************** END OF PAGINATION METHODS **********************************/

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
