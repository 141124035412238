<main class="container">
  <!-- Desktop layout -->
  <ng-container *ngIf="isDesktop | async; else mobileLayout">
    <app-desktop-layout [menuItems]="menu">
      <router-outlet></router-outlet>
    </app-desktop-layout>
  </ng-container>

  <!-- Mobile layout -->
  <ng-template #mobileLayout>
    <app-mobile-layout [menuItems]="menu">
      <router-outlet></router-outlet>
    </app-mobile-layout>
  </ng-template>

  <ng-container *ngIf="isEnvAllowed">
    <app-user-services-agreement></app-user-services-agreement>
  </ng-container>
</main>

