import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CsoPlans } from './azure.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AzureService {
  private _http= inject(HttpClient);
  private url = environment.azureServices;

  /*Subjects*/
  contentSubject = new BehaviorSubject<string>('');
  content$ = this.contentSubject.asObservable();

  csoPlansSubject = new BehaviorSubject<CsoPlans>({free: '', monthly: '', annual: ''});
  csoPlans$ = this.csoPlansSubject.asObservable();

  spaceSubject = new BehaviorSubject<string>('');
  space$ = this.spaceSubject.asObservable();

  constructor() { }

  getAzureApiLandingInfo() {
    const csoInfo = { free: '', monthly: '', annual: '' };
    const contentful$ = this._http.post<any>(`${this.url}contentful`, {});
    const contentSpace$ = this._http.post<any>(`${this.url}spaceId`, {});
    const freePlan$ = this._http.post<any>(`${this.url}csoDevFreePlan`, {});
    const monthlyPlan$ = this._http.post<any>(`${this.url}csoDevMonthlyPlan`, {});
    const annualPlan$ = this._http.post<any>(`${this.url}csoDevAnnualPlan`, {});
  
    return forkJoin([contentful$, freePlan$, monthlyPlan$, annualPlan$, contentSpace$]).pipe(
      switchMap(([contentfulData, freePlanData, monthlyPlanData, annualPlanData, spaceData]) => {
        csoInfo.free = freePlanData.data;
        csoInfo.monthly = monthlyPlanData.data;
        csoInfo.annual = annualPlanData.data;
  
        this.csoPlansSubject.next(csoInfo);
        this.contentSubject.next(contentfulData.data);
        this.spaceSubject.next(spaceData.data);
  
        return of(true);
      }),
      catchError(error => {
        console.error('Error in getAzureApiLandingInfo', error);
        return of(false); 
      })
    );
  }
}
