import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-support-mobile',
  template: `
  <div class="no-mobile-support">
  <mat-icon>app_blocking</mat-icon>
  <div class="info-wrapper">
    <h5>This feature does not have mobile support</h5>
    <p>Please open up the application on a larger screen or try using landscape orientation.</p>
  </div>
</div>
`,
  styleUrls: ['./no-mobile-support.component.scss']
})
export class NoMobileSupportComponent {

  constructor(
  ) { }

}
