<div class="info-card-container vtg-box-shadow" *ngIf="infoCardData">
  <div class="info-card-container-wrapper">
    <header class="info-card-container-header">
      <div class="info-card-container-header-title">
        <h3 data-angular="information-card-title" class="information-card-title">{{infoCardData.title}}</h3>
      </div>
      <div class="info-card-container-header-legend" data-angular="information-card-hours">
        <p>{{ infoCardData.hours}}</p>
      </div>
    </header>

    <div class="info-card-container-content">
      <div class="info-card-container-content-contact">
        <mat-icon data-angular="information-card-icon-one">{{infoCardData.iconOne}}</mat-icon>
        <div class="info-card-container-content-contact-info">
          <h5 data-angular="information-card-phone">{{infoCardData.phone}}</h5>
        </div>
      </div>

      <div class="mt-2 info-card-container-content-contact">
        <mat-icon data-angular="information-card-icon-two">{{infoCardData.iconTwo}}</mat-icon>
        <div class="info-card-container-content-contact-info">
          <h5 data-angular="information-card-email"><a href="mailto:{{infoCardData.email}}">{{infoCardData.email}}</a></h5>
        </div>
      </div>

      <div class="info-card-container-content-text">
        <p data-angular="information-card-description">
          {{infoCardData.description}}
        </p>
        <p data-angular="information-card-closing-statement">
          {{infoCardData.closingStatement}}
        </p>
      </div>
    </div>
  </div>
</div>
