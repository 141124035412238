import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

//Avoids user to bypasses the onboarding  process
export const SessionActiveGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);

  const userPortalAuthData = authenticationService.getAuthDataFromLocalStorage();

  const endSession = parseInt(userPortalAuthData.expirationDate || '0', 10);

  if (!endSession) {
    return true;
  }

  const endSessionDate = new Date(endSession);
  const currentDate = new Date();
  const remaining = endSessionDate.getTime() - currentDate.getTime();
  if (remaining > 0) {
    router.navigate(['/login/sso']);
    return false;
  }
  return true;
};
