import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { AzureService } from 'src/app/core/services/azure.service';

@Injectable()
export class ContentfulInterceptor implements HttpInterceptor {

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private authenticationService: AuthenticationService,
        private azureService: AzureService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.includes(environment.graphql.contentful)) {
            const contentSpace = this.azureService.spaceSubject.value;

            if (!contentSpace) {
                return throwError(() => new HttpErrorResponse({
                    error: 'Contentful Key is required but not present',
                    status: 400,
                    statusText: 'Bad Request'
                }));
            }

            const contentfulUrl = `${environment.graphql.contentful}${contentSpace}`;

            // Initialize the Contentful token (needs to be fetched from a secure source)
            const contenfultToken = this.azureService.contentSubject.value; // Here we need to get the value of the Contentful Key Subject

            // If the Contentful token is missing, return an error response
            if (!contenfultToken) {
                // Return a new HttpErrorResponse with the modified error message.
                return throwError(() => {
                    const httpResponse = {
                        error: 'Contentful Key is required but not present',
                        status: 400,
                        statusText: 'Bad Request',
                    }
                    return httpResponse;
                });
            }

            // Clone the original request and add the Authorization header with the Contentful token
            const modifiedRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${contenfultToken}`,
                },
                url: contentfulUrl,
            });

            // Pass the modified request to the next handler in the chain
            return next.handle(modifiedRequest);
        } else {
            // If the request is not to the Contentful endpoint, pass it through unmodified
            return next.handle(request);
        }
    }

}
