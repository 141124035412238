import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { AzureService } from 'src/app/core/services/azure.service';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss'],
})
export class LoginSSOComponent implements OnInit {
  paramId!: string;
  usernameFromRedirect: string;
  providerFromRedirect!: string;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private azureService: AzureService
  ) {
    this.usernameFromRedirect = (this.route.snapshot.paramMap.get('username') || '').trim();
    this.providerFromRedirect = (this.route.snapshot.paramMap.get('scheme') || '').trim();
  }

  ngOnInit(): void {
    this.loadAzureInfoAndLogin();
  }

  loadAzureInfoAndLogin(): void {
    this.azureService.getAzureApiLandingInfo().subscribe({
      next: () => {
        if (this.usernameFromRedirect || this.providerFromRedirect) {
          this.authenticationService.login(this.usernameFromRedirect, this.providerFromRedirect);
        } else {
          this.authenticationService.login();
        }
      },
      error: () =>{
        this.authenticationService.login();
      }
    }

    );
  }

}
