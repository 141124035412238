import { Injectable, inject } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { GET_FOOTER_COMPONENT, GET_INFO_CARD_COMPONENT, GET_NOTIFICATION_COMPONENT } from '../../graphql/shared-data.query';
import { GraphqlHostService } from '../graphql-host/graphql-host.service';
import { ErrorHandleService } from '../error-handle/error-handle.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationBanner, NotificationFromContentfulResponse, NotificationState } from '../../components/notification/notification';

@Injectable({
  providedIn: 'root'
})
export class PortalSharedService {

  /*Injection Dependency By Angular 14 Feature*/
  private graphqlHost = inject(GraphqlHostService);
  private errorHandleService = inject(ErrorHandleService);

  private isServerErrorSubject = new BehaviorSubject<boolean>(false);
  isServerError$ = this.isServerErrorSubject.asObservable();
  
  private showNotificationBannerSubject = new BehaviorSubject<boolean>(true);
  showNotificationBanner$ = this.showNotificationBannerSubject.asObservable();

  private showAllNotificationsSubject = new BehaviorSubject<boolean>(false);
  showAllNotifications$ = this.showAllNotificationsSubject.asObservable();

  private notificationSubject = new BehaviorSubject<NotificationBanner[] | []>([]);
  notifications$ = this.notificationSubject.asObservable();
  notifications!: NotificationBanner[] | null;

  applyPolicy!: WatchQueryFetchPolicy;

  constructor() {
    this.notifications$.subscribe((response) => { this.notifications = response;})
  }

  /**
   * Fetches the footer User Portal data pulled from Contentful Graphql API.
   * @param policy The FetchPolicy to apply for the API request.
   * @returns A Promise that resolves with the footer data.
   */
  /**
 *
 * @param policy
 * @returns The footer User Portal data pulled from Contentful Graphql API
 */
  fetchFooterComponent(policy: FetchPolicy): Promise<any> {
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_FOOTER_COMPONENT,
        null,
        optionalContext,
        this.applyPolicy
      ).then((response) => {
        resolve(response);
      }).catch((error) => {
        // Handle errors and resolve with an error response to avoid potential resolver issues.
        const errorRejected = this.errorHandleService.serverErrorResponse('Contentful', 'fetchFooterComponent', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    })
  }

  /**
   *
   * @param policy
   * @returns The Info Cards User Portal data pulled from Contentful Graphql API
   */
  fetchCardComponent(policy: FetchPolicy): Promise<any> {
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const optionalContext = null;
    const vars = { sectionId: 'Contact' };

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_INFO_CARD_COMPONENT,
        vars,
        optionalContext,
        this.applyPolicy
      ).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  }

  /**
  *
  * @param policy
  * @returns The Info Cards User Portal data pulled from Contentful Graphql API
  */
  fetchNotificationsFromContentfult(policy: FetchPolicy): Promise<NotificationBanner[]> {
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_NOTIFICATION_COMPONENT,
        null,
        optionalContext,
        this.applyPolicy
      ).then((response: NotificationFromContentfulResponse) => {
        resolve(response.notificationsBannerCollection.items);
      }).catch((error) => {
        this.isServerErrorSubject.next(true);
        reject(error);
      })
    })
  }

  getNotificationStatesFromLocalStorage(): NotificationState[] {
    try {
      // Retrieve notification states from local storage
      const notificationStatesJSON = localStorage.getItem('user_portal_notifications');

      if (notificationStatesJSON) {
        // Parse the JSON and return the notification states
        return JSON.parse(notificationStatesJSON) as NotificationState[];
      } else {
        // If no data found in local storage, return an empty array
        return [];
      }
    } catch (error) {
      console.error('Error while getting notification states from local storage:', error);
      // Handle the error as needed, e.g., throw an error or return a default value
      throw error;
    }
  }

  setShowAllNotificationValue(value: boolean): void {
    this.showAllNotificationsSubject.next(value)
  }

  setShowNotificationBannerValue(value: boolean): void {
    this.showNotificationBannerSubject.next(value)
  }


  async setNotificationStatesInLocalStorage(): Promise<void> {
    try {
      // Fetch notifications
      const notificationResponse: NotificationBanner[] = await this.fetchNotificationsFromContentfult('network-only');
      const notificationFromLocal = this.getNotificationStatesFromLocalStorage();

      if (!notificationResponse.length) {
        // If there are no notifications in the response, clear local storage
        localStorage.removeItem('user_portal_notifications');
        return;
      }

      // Create an array of notification states from the Contentful response
      const notificationStateFromResponse: NotificationState[] = notificationResponse.map(notification => ({
        title: notification.title,
        isRead: false,
      }));

      notificationStateFromResponse.forEach(notificationFromResponse => {
        const matchingLocalNotification = notificationFromLocal.find(localNotification => localNotification.title === notificationFromResponse.title);
        if (!matchingLocalNotification) {
          notificationFromLocal.push({ title: notificationFromResponse.title, isRead: false });
        }
      });


      // Update isRead property based on local storage
      notificationResponse.forEach(notification => {
        const matchingLocalNotification = notificationFromLocal.find(localNotification => localNotification.title === notification.title);
        if (matchingLocalNotification) {
          notification.isRead = matchingLocalNotification.isRead;
        } else {
          // If there's no matching local notification, assume it's unread
          notification.isRead = false;
        }
      });

      // Find and remove notifications that exist in local storage but not in the Contentful response
      const notificationsToRemove = notificationFromLocal.filter(localNotification =>
        !notificationResponse.some(responseNotification => responseNotification.title === localNotification.title)
      );

      if (notificationsToRemove.length > 0) {
        // Remove notifications that no longer exist in Contentful from local storage
        notificationsToRemove.forEach(notificationToRemove => {
          const indexToRemove = notificationFromLocal.indexOf(notificationToRemove);
          notificationFromLocal.splice(indexToRemove, 1);
        });

        // Save the updated local storage without removed notifications
        localStorage.setItem('user_portal_notifications', JSON.stringify(notificationFromLocal));
      }
      // Save the updated local storage with new notifications.
      this.notificationSubject.next(notificationResponse);
      localStorage.setItem('user_portal_notifications', JSON.stringify(notificationFromLocal));
    } catch (error) {
      console.error('Error while setting notification states in local storage:', error);
    }
  }


  markNotificationAsReadByTitleId(title: string): void {
    // Find the notification by its title
    const notificationState = this.getNotificationStatesFromLocalStorage();
    const notificationIndex = notificationState.findIndex(notification => notification.title === title);

    if (notificationIndex !== -1) {
      // Mark the notification as read by updating the isRead property
      notificationState[notificationIndex].isRead = true;

      // Update the notifications isRead State without removing from notification
      const updatedNotifications = this.notifications?.map(notification => {
        if (notification.title === title) {
          return { ...notification, isRead: true };
        }
        return notification;
      });

      // Notify subscribers of the updated notifications
      this.notificationSubject.next(updatedNotifications || []);

      // Save the updated notification states in local storage
      localStorage.setItem('user_portal_notifications', JSON.stringify(notificationState));
    }
  }


  countUnreadNotifications(): number {
    try {
      const notificationStates = this.getNotificationStatesFromLocalStorage();

      // Filter the notifications that are unread (isRead === false)
      const unreadNotifications = notificationStates.filter(notification => !notification.isRead);

      // Return the count of unread notifications
      return unreadNotifications.length;
    } catch (error) {
      console.error('Error while counting unread notifications:', error);
      // Handle the error as needed, e.g., return 0 or throw an error
      return 0; // Return 0 by default
    }
  }

  countAllNotifications(): number {
    const AllNotifications = this.getNotificationStatesFromLocalStorage();
    return AllNotifications.length;
  }
}
