import { gql } from 'apollo-angular';
import { PageInfo } from './support-console.queries.graphql';

export const LOGIN_HISTORY_QUERY = gql`
    query getLoginHistory(
        $claimedUserName: String
        $date: DateTime
        $first: Int
        $after: String
        $last: Int
        $before: String
    ){
        userLoginHistory(
            first: $first
            after: $after
            last: $last
            before: $before
            where: {
                claimedUserName: { eq: $claimedUserName }
                event: { gt: $date }
                or: [
                    { authType: { eq: "AUTH_CODE" } }
                    { authType: { eq: "FAILACODE" } }
                    { authType: { eq: "PASSWORD" } }
                    { authType: { eq: "FAILED_PWD" } }
                    { authType: { eq: "EMAIL_OTP" } }
                    { authType: { eq: "FAILED_OTP" } }
                    { authType: { eq: "SSO" } }
                    { authType: { eq: "RECOVERY" } }
                    { authType: { eq: "FAILRECVRY" } }
                    { authType: { eq: "LOCKEDOUT" } }
                ]
            }
            order: { event: DESC }
        ) 
        {
            pageInfo {
                startCursor
                hasPreviousPage
                hasNextPage
                endCursor
                __typename
            }
            edges{
                node {
                    authType
                    browserInformation
                    event
                    gatewayIPAddress
                    isAuthenticated
                    __typename
                }
                __typename
            }
            totalCount
            __typename
        }
    }
`;

export interface LoginHistoryQueryVars {
    first: number | null;
    after: string | null;
    last: number | null;
    before: string | null;
    claimedUserName?:string;
    date?: Date
}

export interface Node {
    browserInformation: string
    gatewayIPAddress: string;
    isAuthenticated: boolean;
    event: Date;
    authType: string;
    __typename?: string;
}

export interface LoginHistoryEdge {
    node: Node;
    cursor: string;
    __typename?: string;
}

export interface Login {
    pageInfo: PageInfo;
    edges: LoginHistoryEdge[];
    totalCount: number;
    __typename: string;
}

export interface LoginHistoryResponse {
    userLoginHistory: Login;
}

export interface AuthType {
    state: boolean;
    message: string;
}