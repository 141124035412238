import { gql } from "apollo-angular";

export const SUPPORT_CONSOLE_GET_CHANGE_LOGS = gql`
query getChangeLogs(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $userId: UUID
  $dateAt: DateTime
) {
  editLogs (
    first: $first
    after: $after
    last: $last
    before: $before
    where: {userId:{eq:$userId} , changeDate:{ lte: $dateAt }}
    order: { changeDate: DESC }
  ) {
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
      __typename
    }
    edges {
      node {
        id
        rowNumber
        userId
        fieldsChanged
        changedByUserName
        changeDate
        __typename
      }
      cursor
      __typename
    }
    totalCount
    __typename
  }
}`;


export const SUPPORT_CONSOLE_SAVE_CHANGE_LOG = gql`
mutation saveChangeLogs(
  $userId: UUID!
  $changedById: UUID!
  $changedVia: String!
  $fieldsChanged: String!
) {
  createLogEntry(
    input: {
      userId: $userId
      changedById: $changedById
      changedVia: $changedVia
      fieldsChanged: $fieldsChanged
    }
  ) {
    data {
      id
      userId
      fieldsChanged
      changedById
      changedVia
      changeDate
    }
    errors {
      message
    }
  }
}`;

export interface ChangeLogQueryVars {
  dateAt?: string | null,
  first: number | null,
  after: string | null,
  last: number | null,
  before: string | null,
  userId?:string,
}

export interface PageInfo {
  startCursor: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  endCursor: string;
  __typename: string;
}

export interface Node {
  id: string;
  rowNumber: number;
  userId: string;
  fieldsChanged: string;
  changedByUserName: string;
  changeDate: string;
  username?:string;
  __typename: string;
}

export interface EditLogsEdge {
  node: Node;
  cursor: string;
  __typename: string;
}

export interface ChangeLogs {
  pageInfo: PageInfo;
  edges: EditLogsEdge[];
  totalCount: number;
  __typename: string;
}

export interface ChangeLogResponse {
  editLogs: ChangeLogs;
}

export interface CreateChangeLog {
  changedVia: string;
  fieldsChanged: string;
  userId: string;
  changedById: string;
  id?: string;
  changeDate?: string;
}

export interface UserEditedData {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
}

export interface UserIdsResponse {
  usersByIds: UserEditedData[];
}

export interface UserNameByIds {
  userNameByIds: string[];
}
