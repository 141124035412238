<div class="actions-information">
    <h5>Actions</h5>

    <div class="actions-information-buttons-wrapper">

        <!-- Send Verification Code -->
        <button type="button" mat-stroked-button color="primary"
            (click)="openVerificationCodeDialog(user.node.userName, user.node.id)"
            (keydown.enter)="openVerificationCodeDialog(user.node.userName, user.node.id)"
            id="send-verification-code-action"
            >
            <mat-icon fontIcon="mark_email_read"></mat-icon>
            Send Verification Code
        </button>

        <!-- Unlock Account -->
        <button type="button" mat-stroked-button color="primary" (click)="unlockUser(user.node.userName, user.node.id)"
            (keydown.enter)="unlockUser(user.node.userName, user.node.id)" *ngIf="lockedEnd(user.node.lockoutEnd)"
            id="unlock-user-action"
        >
            <mat-icon fontIcon="lock_open"></mat-icon>
            Unlock Account
        </button>

        <!-- 2FA Recovery -->
        <button type="button" mat-stroked-button color="primary" 
            (click)="getRecoveryCode(user.node.id, user.node.id, user.node.userName)"
            (keydown.enter)="getRecoveryCode(user.node.id, user.node.id, user.node.userName)" 
            id="send-recovery-code-action" *ngIf="user.node.twoFactorEnabled"
        >
            <mat-icon fontIcon="devices"></mat-icon>
            2FA recovery
        </button>

        <!-- Reset Password -->
        <button type="button" mat-stroked-button color="primary"
            (click)="resetPassword(user.node.userName, user.node.id)"
            (keydown.enter)="resetPassword(user.node.userName, user.node.id)" 
            id="reset-password-action" *ngIf="user.node.emailConfirmed"
        >
            <mat-icon fontIcon="key"></mat-icon>
            Reset password
        </button>

        <!-- Close Sessions -->
        <button type="button" mat-stroked-button color="primary"
            (click)="closeSessions(user.node.id, user.node.userName)"
            (keydown.enter)="closeSessions(user.node.id, user.node.userName)"
            id="close-sessions-action"
        >
            <mat-icon fontIcon="phonelink_off"></mat-icon>
            Close sessions
        </button>

        <!-- Restart Onboarding -->
        <button type="button" mat-stroked-button color="primary"
            (click)="restartOnboarding(user.node.userName, user.node.id)"
            (keydown.enter)="restartOnboarding(user.node.userName, user.node.id)"
            id="restart-onboarding-action"
        >
            <mat-icon fontIcon="replay"></mat-icon>
            Restart Onboarding
        </button>

        <!-- Reset 2FA Auth App -->
        <button type="button" mat-stroked-button color="primary"
            (click)="enableEmailOtp(user.node.id, user.node.userName)"
            (keydown.enter)="enableEmailOtp(user.node.id, user.node.userName)"
            id="reset-auth-app-method-action" *ngIf="enableEmailOtpButton && user.node.twoFactorEnabled"
        >
            <mat-icon fontIcon="mail_lock"></mat-icon>
            Enable Email OTP
        </button>
    </div>
</div>