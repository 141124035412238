import { CanActivateFn } from '@angular/router';
import { GetUserDataByIDService } from '../services/get-user-data-by-id.service';
import { environment } from 'src/environments/environment';
import { LoginHistoryService } from 'src/app/features/support-console/services/login-history.service';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { inject } from '@angular/core';
import { AuthCanaryService } from 'src/app/shared/services/auth-canary/auth-canary.service';

export const userDataGuard: CanActivateFn = async () => {

  const getUserDataByIDService = inject(GetUserDataByIDService);
  const loginHistoryService = inject(LoginHistoryService);
  const authenticationService = inject(AuthenticationService);
  const authenticationCanaryService = inject(AuthCanaryService);

  try {
    const userName = authenticationService.userName;
    // const responseFromFortify = await authenticationService.getUserDataFromFortify();
    const responseFromRBS = await getUserDataByIDService.fetchUserDataFromRBSByID('network-only');
    const lastSession = await loginHistoryService.getLastSessionMethod('network-only', userName.value);

    //Evaluating the authentication type of the user
    if (lastSession.edges[0].node.authType === "SSO") {
      return responseFromRBS ? true : false;;
    } else if (responseFromRBS.isPasswordExpired) {
      //Evaluating if user Password is expired
      //Redirecting to Fortify to change expired Password
      document.location.href = environment.onboardingClaims.password;
      return false;
    }

    const userId = authenticationService.userId;
    const orgs = getUserDataByIDService.getContractorsValue;

    // Check Canary Status for Users and Organizations
    // Note: This conditional block will be removed once canary features are fully deployed in production.
    // Until then, we are bypassing these requests in the production environment.
    if (environment.env !== 'prod') {
      await authenticationCanaryService.verifyUserCanary(userId.value);
      await authenticationCanaryService.verifyOrgCanary(orgs);
    }

    return responseFromRBS ? true : false;

  } catch (error) {
    console.error('Error fetching user data:', error);
    return false;
  }
};
