import { gql } from "apollo-angular";

/**
 * After we verify that UserInOrg agreement
 * exists and we get the response is null
 * we have to use this mutation whe user accepts 
 * the agreement.
 * after this, check if agreement exits with:
 * graphql/get-claim-user-in-org
 * 
 * Number 5
 */
export const ADD_AGREEMENT_CLAIM_TO_ORG = gql`
mutation addAgreementClaimToOrganization(
  $addAgreementParameters: ClaimsInputModelInput!
) {
  createClaim(input: $addAgreementParameters) {
    data {
      id
      parentId
      key
      value
      dateStart
      dateExpire
      dateCreated
      __typename
    }
    errors {
      message
    }
  }
}
`;

export interface DataClaim {
  parentId: string;
  key: string;
  value: string;
  isPublic: boolean;
  dateStart: string;
  dateExpire: string;
}

export interface PayloadOfClaim {
  data: DataClaim;
}