import { Injectable, inject } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { GET_PROFILE_INFO, Org, UserPersonalData, UserProfileResponse } from 'src/app/features/dashboard/graphql/profile-info.query';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';
import { ErrorHandleService } from 'src/app/shared/services/error-handle/error-handle.service';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetUserDataByIDService {

  private graphqlHost = inject(GraphqlHostService);
  private authenticationService = inject(AuthenticationService);
  private userPortalDialogService = inject(UserPortalDialogService);
  private errorHandleService = inject(ErrorHandleService);
  private _contractors = new BehaviorSubject<Org[] | Org>([]);
  private _userPersonalData = new BehaviorSubject<UserPersonalData | null>(null);
  applyPolicy!: WatchQueryFetchPolicy;


  fetchUserDataFromRBSByID(policy: FetchPolicy = 'network-only'): Promise<any> {
    this.applyPolicy = policy;
    const optionalContext = null;

    const vars = { id: this.authenticationService.userId.value };

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_PROFILE_INFO,
        vars,
        optionalContext,
        this.applyPolicy,
      ).then((response: UserProfileResponse) => {
        this.authenticationService.userName.next(response.userById.userName || '');
        this.authenticationService.findRole(response.userById);
        const { firstName, lastName, email, phoneNumber, userName, twoFactorEnabled } = response.userById;
        this._userPersonalData.next({ firstName, lastName, email, phoneNumber, userName, twoFactorEnabled })
        // Filters the users in the organization to get unique users based on their organization ID.
        const uniqueUsersInOrg = response.userById.usersInOrg.length? response.userById.usersInOrg.filter((org, index, self) =>
          index === self.findIndex((element) => (
            element.org.id === org.org.id
          ))
        ): [];
        this._contractors.next(uniqueUsersInOrg)
        resolve(response.userById);
      }).catch((error: any) => {
        let userPortalCatchRbsError = this.errorHandleService.getStoredRbsError();
        if (!userPortalCatchRbsError) {
          const dialogRef = this.userPortalDialogService.userPortalDialog(
            'Welcome to the User Portal. ',
            `<p class="card-message-error">
                There seems to be an issue on our side. Please log out and log back in. If the problem persists, contact our 
                <a href="mailto:fortify@lcptracker.net"> Support team</a> or check out our
                <a href="https://lcptracker.com/contact/contact-support" target="_blank">Contact Page<a/>.
                <br>
                <p>To help us assist you more efficiently, please provide the following code <strong>Internal Service Error</strong> to our support agents.</p>
            </p>`,
            'Confirm',
            'Refresh'
          );

          dialogRef.afterClosed().subscribe(response => {
            if (response) {
              this.errorHandleService.saveRbsErrorToLocalStorage();
            } else {
              this.authenticationService.login();
            }
          })
        }
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'fetchUserDataByID', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    })
  }


  get getContractors(): Observable<Org[] | Org> {
    return this._contractors.asObservable();
  }
  get getContractorsValue(): Org[] | Org {
    return this._contractors.value;
  }


  get getUserPersonalData(): Observable<UserPersonalData | null> {
    return this._userPersonalData.asObservable();
  }

  get getUserPersonalDataValue(): UserPersonalData | null {
    return this._userPersonalData.value;
  }

}