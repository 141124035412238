import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { UserPortalDialogService } from '../../services/dialog/user-portal-dialog.service';
import { PortalSharedService } from '../../services/portal-shared/portal-contentful.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GetUserDataByIDService } from '../../layouts/portal/services/get-user-data-by-id.service';
import { UserPersonalData } from 'src/app/features/dashboard/graphql/profile-info.query';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  /*Injection Dependency By Angular 14 Feature*/

  public dialog = inject(MatDialog);
  private _authenticationService = inject(AuthenticationService);
  private _userPortalDialogService = inject(UserPortalDialogService);
  private _userPortalShareService = inject(PortalSharedService);
  private _getUserByIdService = inject(GetUserDataByIDService);
  //
  // Header Settings
  //
  showAllNotifications: boolean = false;

  @Output() openNav = new EventEmitter<boolean>();
  @Output() headerAction = new EventEmitter<Event>();
  @Input() isOpen = false;
  @Input() animateBell = false;
  @Input() showNotify: boolean = true;                      // this.contentful.getNotifications() error handling will always override. Test there..
  streamNotify!: Subscription;                             // to remove the observable when it leaves.
  @Input() notifyCount: number = 99;                        // Receives the amount of unread notifications
  @Input() loading: boolean = false;
  //
  // Logo
  //
  @Input() logo: string = './assets/images/logo.png';       // Stores the logo image path

  //
  // Application Settings
  //
  @Input() hasApplication: boolean = true;                  // Manages the display of the Application Button
  @Input() applicationName: string = 'QA v2 LCP';           // Stores the value to show in the Application Button
  @Input() hasRole: boolean = true;                         // Manages the display of the Role Button

  //
  // User Settings Icons
  //
  @Input()
  additionalLinks: boolean = true;
  @Input() search: boolean = true;                          //Enables search icon
  @Input() apps: boolean = true;                            //Enables app grid icon
  @Input() account: boolean = true;                         //Enables user account icon 
  @Input() settings: boolean = true;                        //Enables settings icon
  //
  // Presentation Settings (additional storybook)
  //
  @Input() default: boolean = true;                        // Standard Header Navigation to copy
  @Input() mobileOnly: boolean = false;                    // Mobile only presentation. 
  /**
   * npm run testOnce ran into security problems with onlyMobile. 
   * Looks like Angular reserves the prefix 'on' as their own click handlers and supresses others under the hood.
   * This causes the binds for mobileLayout to fail. changed the word to be mobileOnly.
   * https://stackoverflow.com/questions/57181653/binding-to-event-property-onlycurrentmonth-is-disallowed-for-security-reasons
   * https://github.com/CirclonGroup/angular-tree-component/issues/223
   * https://github.com/highcharts/highcharts-angular/issues/191
   * 
   */


  isServerError$ = new Observable();
  userData:UserPersonalData | null = null;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor() { }


  ngOnInit(): void {
    this.getPersonalUserData();               
    this.catchErrorRequestInTemplate();
  }

  getPersonalUserData():void{
    this.userData = this._getUserByIdService.getUserPersonalDataValue;
  }

  catchErrorRequestInTemplate(): void {
    this.isServerError$ = this._userPortalShareService.isServerError$.pipe(
      takeUntil(this._unsubscribeAll)
    );
  }


  countUnReadNotifications(): number {
    return this._userPortalShareService.countUnreadNotifications();
  }

  countAllNotifications(): number {
    return this._userPortalShareService.countAllNotifications();
  }

  openNotify(value: boolean): void {
    this._userPortalShareService.setShowNotificationBannerValue(true);
    this._userPortalShareService.setShowAllNotificationValue(value);
  }


  toggleHam(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.openNav.emit(true);
    } else {
      this.openNav.emit(false);
    }
  }

  /**
   * Triggers the action to logout the user from the identity server
   */
  logout(): void {
    this._authenticationService.logout();
  }

  /**
   * Opens the Confirmation Dialog with the data a object that contains its content
   */
  openLogoutConfirmationDialog(): void {

    // Creates the Dialog and stores its reference
    const logoutConfirmationDialogRef = this._userPortalDialogService.userPortalDialog(
      'Are you sure you want to leave?',
      `
        <p style="padding: 0.57rem 0 0 0">
          You are logging out of all LCPtracker applications.
        </p>
      `,
      'Confirm',
      'Cancel'
    );

    // Listens to the dialog, so when it closes it does an action
    logoutConfirmationDialogRef.afterClosed().subscribe(value => {

      // If the value is truthy, the user confirmed the action
      if (value) {
        // Logs out
        this.logout();
      }
      // Else, the user canceled the action
    })

  }

}
