import { Component, inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-logout',
  template: `<ng-container></ng-container>`,
  styleUrls: ['./post-logout.component.scss']
})
export class PostLogoutComponent implements OnInit {

  private _authenticationService = inject(AuthenticationService);
  private _router = inject(Router);
  logo: string = 'assets/images/lcptracker-full-color.svg';

  ngOnInit(): void {
    this.logout();
    this._authenticationService.isAuthenticated$.subscribe({
      next: Authenticated => {
        if (!Authenticated) {
          this._router.navigateByUrl('./');
        }
      },
      error: err => {
        throw new Error(err);
      },
    });
  }

  /**
   * Triggers the action to logout the user from the identity server
   */
  logout(): void {
    this._authenticationService.logoutLocal();
  }

}
