<div class="login-history-information">
  <h5>Login history</h5>
  <mat-progress-bar *ngIf="isLoadingProgressBar" mode="indeterminate"></mat-progress-bar>
  <ng-container *ngIf="!isLoadingProgressBar">
    <ng-container *ngFor="let item of loginHistoryGraphQL; let i = index">
      <ng-container *ngIf=" !showFullHistory || showFullHistory && i < 2 ">
        <div class="login-wrapper pl-0.57 flex flex-col">
          <div class="login-time-wrapper">
            <div class="icon-wrapper">
              <mat-icon>{{item.node.browserInformation | handleUserAgent:'Device'}}</mat-icon>
            </div>

            <div class="login-time-date">
              <p>{{item.node.event | date}} at {{item.node.event | date: 'shortTime'}}</p>
            </div>

            <div class="login-time-state">
              <span *ngIf="!showFullAuthTypeBadge"
                class="inline-flex items-center justify-center px-2 mx-2 font-medium rounded-md state-badge ring-1 ring-inset"
                [ngClass]="validateAuthTypeBadgeColor(item.node.authType) ? 'bg-red-50 text-red-700 ring-red-600/10' : 'bg-green-50 text-green-700 ring-green-600/20'">
                <mat-icon class="m-0 text-xl">{{validateAuthTypeBadgeColor(item.node.authType) ? 'close'
                  :'done'}}</mat-icon>
                {{validateAuthTypeMessage(item.node.authType)}}
              </span>
              <span class="state-badge-icon"
                [ngClass]="validateAuthTypeBadgeColor(item.node.authType) ? 'text-red-700 ring-red-600/10' : 'text-green-700 ring-green-600/20'">
                <mat-icon class="m-0 text-xl">{{validateAuthTypeBadgeColor(item.node.authType) ? 'close'
                  :'done'}}</mat-icon>
              </span>
            </div>
          </div>

          <div class="login-system-detail">
            <div class="login-system-detail-element">
              <p class="label">IP</p>
              <p class="value">{{item.node.gatewayIPAddress}}</p>
            </div>
            <div class="login-system-detail-element">
              <p class="label">Browser</p>
              <p class="value">{{item.node.browserInformation | handleUserAgent:'Browser'}}</p>
            </div>
            <div class="login-system-detail-element">
              <p class="label">Operative System</p>
              <p class="value">{{item.node.browserInformation | handleUserAgent:'Os'}}</p>
            </div>
          </div>
          <div class="divider">
            <mat-divider></mat-divider>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <button mat-button *ngIf="loginHistoryGraphQL.length > 4"
    (click)="openLoginHistoryDialog(user.node.firstName, user.node.lastName, user.node.email, user.node.id, user.node.userName)">Show
    Details</button>
  <p class="results-not-found" *ngIf="!loginHistoryGraphQL.length && !isLoadingProgressBar">No login history records
    were found for this user.</p>
</div>