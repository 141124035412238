import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { LoginSSOComponent } from './pages/login-sso/login-sso.component';
import { LoginSSOOnboardedComponent } from './pages/login-sso-onboarded/login-sso-onboarded.component';
import { CheckValidSessionGuard } from './guards/authentication.guard';
import { PostLogoutComponent } from './pages/post-logout/post-logout.component';

const BROWSER_TAB_TITLE = 'Login - User Portal - LCPtracker';

const routes: Routes = [
  {
    path: 'success',
    component: LoginCallbackComponent,
    title: BROWSER_TAB_TITLE,
    canActivate: [CheckValidSessionGuard]
  },
  {
    path: 'sso',
    component: LoginSSOComponent,
    title: BROWSER_TAB_TITLE,
    canActivate: [CheckValidSessionGuard]
  },
  {
    path: 'sso-onboarded',
    component: LoginSSOOnboardedComponent,
    title: BROWSER_TAB_TITLE,
  },
  {
    path: 'sso/:username',
    component: LoginSSOComponent,
    title: BROWSER_TAB_TITLE,
    pathMatch: 'full',
    canActivate: [CheckValidSessionGuard]
  },
  //set new property to call in fortify
  {
    path: 'sso-provider/:scheme',
    component: LoginSSOComponent,
    title: BROWSER_TAB_TITLE,
    pathMatch: 'full',
    canActivate: [CheckValidSessionGuard]
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
