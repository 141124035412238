import { Component, OnInit } from '@angular/core';
import { InformationCard } from '../../../../shared/components/info-card/info-card';
import { ActivatedRoute } from '@angular/router';
import { AboutUs } from '../../components/about-us/about-us.interface';
import { ERROR_CATEGORY_CODE } from 'src/app/shared/components/error-handle/error-handle-const';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  ERROR_CATEGORY_CODE = ERROR_CATEGORY_CODE;
  hasErrorOccurred:boolean = false;
  informationCards: InformationCard[] | null = [];
  aboutUs: AboutUs[] | null = [];
  

  constructor(private _route: ActivatedRoute) { }

  ngOnInit(): void {
    const routeContactSubscription = this._route.data.subscribe({
      next: (data) =>{
        if(data['contact'].infoCardCollection && data['contact'].aboutUsCollection){ 
          this.hasErrorOccurred = false;
          this.informationCards = data['contact'].infoCardCollection.items;
          this.aboutUs = data['contact'].aboutUsCollection.items;
        }else {
          this.hasErrorOccurred = true;
          this.informationCards = data['contact'].data;
          this.aboutUs = data['contact'].data;
        }
      },
      error: (error) => {
        routeContactSubscription.unsubscribe();
      },
      complete: () => {routeContactSubscription.unsubscribe()}
    })
  }
}

