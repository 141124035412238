import { inject, Injectable } from '@angular/core';
import { GraphqlHostService } from '../graphql-host/graphql-host.service';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ErrorHandleService } from '../error-handle/error-handle.service';
import { environment } from 'src/environments/environment';
import { ApplicationService, ApplicationServicesResponse, BUILD_CHECK_ORG_CANARY, CanaryNodes, CHECK_USER_CANARY, CLAIMS_QUERY_FOR_CANARY, ClaimsCanaryEntity, ClaimsCanaryResponse, GET_USERPORTAL_SERVICE, UserCanaryResponse } from './graphql/canary-get-feature-experimental.query';
import { BehaviorSubject } from 'rxjs';
import { Org } from 'src/app/features/dashboard/graphql/profile-info.query';

@Injectable({
  providedIn: 'root'
})
export class AuthCanaryService {

  private _graphqlHostService = inject(GraphqlHostService);
  private _errorHandleService = inject(ErrorHandleService);

  private _isCanaryUserSubject = new BehaviorSubject<boolean>(false);
  _isCanaryUser$ = this._isCanaryUserSubject.asObservable();

  private _isCanaryOrgSubject = new BehaviorSubject<boolean>(false);
  _isCanaryOrg$ = this._isCanaryOrgSubject.asObservable();

  applyPolicy!: WatchQueryFetchPolicy;

  constructor() { }

  get getCanaryUserValue(): boolean {
    return this._isCanaryUserSubject.value;
  }

  get getCanaryOrgValue(): boolean {
    return this._isCanaryOrgSubject.value;
  }


  getClaimsForCanary(policy: FetchPolicy = 'network-only', key: string, parentId: string): Promise<ClaimsCanaryEntity[]> {
    this.applyPolicy = policy;
    // Define variables for the GraphQL query
    const vars = { parentId, key };
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          CLAIMS_QUERY_FOR_CANARY,
          vars,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: ClaimsCanaryResponse) => {
          resolve(response.claims.nodes);
        })
        .catch((error) => {
          // Handle errors that occur during the query
          // Log and resolve with a formatted error response
          const errorRejected = this._errorHandleService.serverErrorResponse(
            'RBS', // Error service identifier
            'getClaimsForCanary', // Method identifier
            error, // Original error object
          );
          console.error(errorRejected); // Log the error
          reject(errorRejected.data); // Resolve with the error data
        });
    });
  }


  checkUserCanary(policy: FetchPolicy = 'network-only', userId: string): Promise<CanaryNodes[]> {
    this.applyPolicy = policy;
    //This is the default key to indentify Canary Users.
    const featureName = 'Canary-User';
    // Define variables for the GraphQL query
    const vars = { featureName, userId };
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          CHECK_USER_CANARY,
          vars,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: UserCanaryResponse) => {
          resolve(response.userCanary.canaryNodes);
        })
        .catch((error) => {
          // Handle errors that occur during the query
          // Log and resolve with a formatted error response
          const errorRejected = this._errorHandleService.serverErrorResponse(
            'RBS', // Error service identifier
            'getClaimsForCanary', // Method identifier
            error, // Original error object
          );
          console.error(errorRejected); // Log the error
          reject(errorRejected.data); // Resolve with the error data
        });
    });
  }

  checkOrgCanary(policy: FetchPolicy = 'network-only', QUERY: any): Promise<any> {
    this.applyPolicy = policy;
    // Define variables for the GraphQL query
    const optionalContext = null;
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          QUERY,
          null,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          // Handle errors that occur during the query
          // Log and resolve with a formatted error response
          const errorRejected = this._errorHandleService.serverErrorResponse(
            'RBS', // Error service identifier
            'checkOrgCanary', // Method identifier
            error, // Original error object
          );
          console.error(errorRejected); // Log the error
          reject(errorRejected.data); // Resolve with the error data
        });
    });
  }

  async shouldBeOneOrgCanary(orgs: Org[]): Promise<void> {
    const response = await this.checkOrgCanary('network-only', orgs);
  }


  getUserPortalServiceId(policy: FetchPolicy = 'network-only'): Promise<ApplicationService> {
    this.applyPolicy = policy;
    // Define variables for the GraphQL query
    const optionalContext = null;
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          GET_USERPORTAL_SERVICE,
          null,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: ApplicationServicesResponse) => {
          resolve(response.applicationServices.app[0]);
        })
        .catch((error) => {
          // Handle errors that occur during the query
          // Log and resolve with a formatted error response
          const errorRejected = this._errorHandleService.serverErrorResponse(
            'RBS', // Error service identifier
            'getUserPortalServiceId', // Method identifier
            error, // Original error object
          );
          console.error(errorRejected); // Log the error
          reject(errorRejected.data); // Resolve with the error data
        });
    });
  }

  /**
   * Verifies if the user is a canary user.
   * @param userId - The ID of the user to verify.
   * @returns A promise that resolves when the verification is complete.
   */
  async verifyUserCanary(userId: string): Promise<void> {
    try {
      // Call the service to check if the user is a canary user
      const userCanary = await this.checkUserCanary('network-only', userId);
      // Check if the response indicates the user is a canary user
      if (userCanary && userCanary[0]?.key === 'Canary-User') {
        // Set the state to true if the user is a canary user
        this._isCanaryUserSubject.next(true);
      } else {
        // Set the state to false if the user is not a canary user
        this._isCanaryUserSubject.next(false);
      }
    } catch (error) {
      // Handle any errors that occur during verification
      console.error('Error in method: ', error);
      // Set the state to false in case of error
      this._isCanaryUserSubject.next(false);
    }
  }

  /**
   * Verifies if any of the given organizations are canary organizations.
   * @param orgs - An array of organizations or a single organization to verify.
   * @returns A promise that resolves when the verification is complete.
   */
  async verifyOrgCanary(orgs: Org[] | Org): Promise<void> {
    try {
      // Verify that orgs is an array and not empty
      if (!Array.isArray(orgs) || orgs.length === 0) {
        // console.error('Error: orgs is not an array or is empty', orgs);
        return;
      }

      // Build the GraphQL query to verify canary organizations
      const QUERY = BUILD_CHECK_ORG_CANARY(orgs);
      if (!QUERY) {
        console.error('Error building GraphQL query');
        return;
      }

      // Call the service to verify canary organizations
      const orgCanary = await this.checkOrgCanary('network-only', QUERY);

      if(!orgCanary){
        this._isCanaryOrgSubject.next(false);
        return
      }

      // Array para recolectar todos los canaryNodes
      const allCanaryNodes = [];
      for (let index = 0; index < orgs.length; index++) {
        const canaryNodes = orgCanary[`orgCanary${index}`]?.canaryNodes;
        if (canaryNodes && canaryNodes.length > 0) {
          // console.log(`Organización ${index} tiene nodos canary: `, canaryNodes);
          // Agrega los nodos canary al array
          allCanaryNodes.push(...canaryNodes);
        } 
      }

      if (allCanaryNodes.some((item) => item.key === 'Canary-Org')) {
        this._isCanaryOrgSubject.next(true);
      } else {
        this._isCanaryOrgSubject.next(false);
      }

    } catch (error) {
      // Handle any errors that occur during verification
      console.error('Error verifying canaries: ', error);
    }
  }

  isFeatureEnabled(claims: ClaimsCanaryEntity[]): boolean {
    return claims.some((claim) => claim.value.trim() === 'true');
  }
}
