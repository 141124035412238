export const ERROR_CATEGORY_CODE = {
  Missing_Onboarding_Information: 'Missing Onboarding Information',
  Content_Retrieval_Error: 'Content Retrieval Error',
  Invalid_Session_Identifier: 'Invalid Session Identifier',
  Authentication_Status_Problem: 'Authentication Status Problem',
  Incomplete_Session_Identifier: 'Incomplete Session Identifier',
  Internal_Service_Error: 'Internal Service Error'
}

export interface ErrorMessage {
  errorName: string;
  errorTitle?: string;
  errorMessage: string;
  errorCode: string;
  button?: string
}

export const ERROR_LIST_DATA: ErrorMessage[] = [
  {
    errorName: 'Missing onboarding claim(s)',
    errorTitle: 'Oops!',
    errorMessage: `
        <p class="card-message-error">
        There's information missing from your onboarding process. Please try again.
        If the problem persists, contact our <a href="mailto:fortify@lcptracker.net">
        Support team</a> or check out our <a href="https://lcptracker.com/contact/contact-support" target="_blank">Contact Page.</a>
        </p>
        <br>
        <p class="card-message-error">To help us assist you more efficiently,
        please provide the following code to our Support team.</p>
      `,
    errorCode: 'Missing Onboarding Information',
    button: 'Refresh'
  },
  {
    errorName: 'Contentful API error',
    errorTitle: 'Oops!',
    errorMessage: `
        <p class="card-message-error">
        One of our services is temporarily unavailable due to maintenance. Please try again in a few minutes.
        If the problem continues, contact our <a href="mailto:fortify@lcptracker.net">
        Support team</a> or check out our <a href="https://lcptracker.com/contact/contact-support" target="_blank">Contact Page<a/>.</p>
        <br>
        <p class="card-message-error">To make assistance smoother, please provide the following code to our Support team.</p>
      `,
    errorCode: 'Content Retrieval Error',
    button: 'Refresh'
  },
  {
    errorName: 'Undefined Token',
    errorTitle: 'Oops!',
    errorMessage: `
        <p class="card-message-error">
        There seems to be an issue with your session. Please log out and log back in.
        If the problem persists, contact our <a href="mailto:fortify@lcptracker.net">
        Support team</a> or check out our <a href="https://lcptracker.com/contact/contact-support" target="_blank">Contact Page<a/.></p>
        <br>
        <p class="card-message-error">To help us assist you more efficiently,
        please provide the following code to our Support team.</p>
      `,
    errorCode: 'Invalid Session Identifier',
    button: 'Refresh'
  },
  {
    errorName: 'RBS API error',
    errorTitle: 'Oops!',
    errorMessage: `
        <p class="card-message-error">
         There seems to be an issue on our side. Please log out and log back in. If the problem persists, contact our
         <a href="mailto:fortify@lcptracker.net">
        Support team</a> or check out our <a href="https://lcptracker.com/contact/contact-support" target="_blank">Contact Page<a/>.</p>
        <br>
        <p class="card-message-error">To help us assist you more efficiently, please provide the following code to our Support team.</p>
      `,
    errorCode: 'Internal Service Error',
    button: 'Refresh'
  },
  {
    errorName: 'UNKNOWN_ERROR',
    errorTitle: 'Oops!',
    errorMessage: `
        <p class="card-message-error">
          One of our services is currently down for maintenance.
          The content will be available later. Please try again later.
          If the issue persists, please contact support for assistance.
        </p>
      `,
    errorCode: 'UNKNOWN_ERROR',
  }
];