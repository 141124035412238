<div class="external-providers-information">
  <h5>Linked SSO</h5>
  <ng-container *ngIf="providersByUser && providersByUser.length; else NoProvidersView">
    <ng-container *ngIf="(isLoading$ | async) === false && (isServerError$ | async) === false">
      <div *ngFor="let item of providersByUser" class="provider-wrapper">
        <mat-icon>vpn_lock</mat-icon>
        <div class="provider-details"> 
          <span class="value">({{item.providerDisplayName.trim() | replace: '_' : ' '}})</span> 
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(isLoading$ | async) && (isServerError$ | async) === false">
        <p class="loading-text">Please wait while we check for providers...</p>
    </ng-container>
  </ng-container>

  <!-- Subscriptions - No Results View -->
  <ng-template #NoProvidersView>
  <!-- No Subscriptions View - Container -->
  <div class="no-providers-view">
    <span *ngIf="(isServerError$ | async) === false; else serverErrorMessage" class="no-providers-view-description">
      This user does not have an SSO Linked.
    </span>
    <ng-template #serverErrorMessage>
      <span class="no-providers-view-description">
        We apologize, but we're currently facing difficulties 
        retrieving provider data for this user. Please attempt 
        the operation again later.
      </span>
    </ng-template>
  </div>
  </ng-template>
</div>