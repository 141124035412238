import { Component, Input, OnInit, inject } from '@angular/core';
import { UsersEdge } from '../../../graphql/support-console.queries.graphql';
import { ProvidersByUserService } from '../../../services/providers-by-user.service';
import { UserExternalLogin } from '../../../graphql/providers-by-userId';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-providers-detail',
  templateUrl: './providers-detail.component.html',
  styleUrls: ['./providers-detail.component.scss']
})
export class ProvidersDetailComponent implements OnInit {
  private _providerByUserService = inject(ProvidersByUserService);
  constructor() { }

  @Input() user!: UsersEdge;
  providersByUser!:UserExternalLogin[];
  isLoading$ = new Observable<boolean>();
  isServerError$ = new Observable<boolean>();
  private _unsubscribeAll: Subject<void> = new Subject<void>();


  ngOnInit(): void {
    this.initLoadingAndServerState();
    if (this.user) {
      this.getProvidersByUser(this.user.node.id);
    } 
  }

    /**
  * Initializes the loading var
  */
    initLoadingAndServerState(): void {
      if (this._providerByUserService.isLoading$) {
        this.isLoading$ = this._providerByUserService.isLoading$.pipe(
          takeUntil(this._unsubscribeAll),
        );
      }
      if (this._providerByUserService.isServerError$) {
        this.isServerError$ = this._providerByUserService.isServerError$.pipe(
          takeUntil(this._unsubscribeAll),
        );
      }

    }

  /**
* Calls the service to get a list of users
*/
  async getProvidersByUser(userId: string): Promise<void> {
      this.providersByUser = [];
    try {
      const response = await this._providerByUserService.getExternalProvidersByUser('network-only', userId);
      this.providersByUser = response;
    } catch (error) {
      console.error('Something went wrong for getLoginsPreview', error);
    }
  }

}
