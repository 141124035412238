<div class="error-handle-container">
  <div class="error-handle-card sm:mx-2.28">
      <img src="assets/images/lcptracker-full-color.svg" alt="LCPtracker Logo" width="auto" height="auto">
      <h4>{{catchErrorInfo.errorTitle}}</h4>
      <div class="error-card-body" [innerHTML]="catchErrorInfo.errorMessage">
        <div *ngIf="true" class="progress-spinner-container">
          <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate" diameter="50">
          </mat-progress-spinner>
        </div>
      </div>
      <div class="error-card-code">
        <strong>Error Code: {{catchErrorInfo.errorCode}}</strong>
        <button class="error-copy-button" color="primary"
        (click)="copyToClipboard(catchErrorInfo.errorCode)"
        matTooltip="Copy"><mat-icon class="p-0 m-0 text-2xl">content_copy</mat-icon>
        </button>
      </div>
      <div class="error-card-button-container">
        <button *ngIf="catchErrorInfo.button" class="error-card-button" type="button" mat-stroked-button color="primary"
        (click)="clickMethodBasedOnErrorCode(catchErrorInfo.errorCode) === 're-authenticate' ? retryAuth() : refreshContent()">{{catchErrorInfo.button}}
        <mat-icon class="error-icon-button">refresh</mat-icon>
      </button>
      <button *ngIf="logOutMethodBasedOnErrorCode(catchErrorInfo.errorCode)" class="error-card-button" type="button" mat-stroked-button color="primary"
        (click)="logOut()">
        Log Out
        <mat-icon class="error-icon-button">logout</mat-icon>
      </button>
      </div>
  </div>
</div>