import { gql } from 'apollo-angular';

export const GET_PROVIDER_INFO_BY_USER_ID = gql`
query getProviderByUserId($userId: String!){
  userExternalLoginsByUserId(userId:$userId){
    id
    loginProvider
    providerDisplayName
    userId
    user{
      userName
    }
  }
}
`;

export interface UserExternalLogin {
  id: string;
  loginProvider: string;
  providerDisplayName: string;
  userId: string;
  user: {
    userName: string;
  };
}

export interface UserExternalLoginResponse {
  userExternalLoginsByUserId: UserExternalLogin[];
}
