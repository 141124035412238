import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { environment } from 'src/environments/environment';
import { switchMap, take } from 'rxjs';

@Injectable()
export class GraphqlRBSInterceptor implements HttpInterceptor {

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private authenticacionService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.includes(environment.graphql.rbs)) {
            return this.authenticacionService.isAuthenticated$.pipe(
                take(1),
                switchMap(isAuthenticated => {
                    if (isAuthenticated) {
                        return this.oidcSecurityService.getAccessToken().pipe(
                            switchMap(accessToken => {
                                const modifiedRequest = request.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                });
                                return next.handle(modifiedRequest);
                            })
                        );
                    } else {
                        console.error('User is not authenticated.');
                        return next.handle(request);
                    }
                })
            );
        } else {
            return next.handle(request); // No modify other requests.
        }
    }
}
