import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { MENU, MenuNodeElement } from './../menu';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FooterResponse } from 'src/app/shared/components/footer/footer-interface';
import { fadeInLeft, fadeOutLeft, } from 'src/app/shared/animations/fade';


@Component({
  selector: 'app-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
  animations: [
    fadeInLeft,
    fadeOutLeft,
  ]
})
export class DesktopLayoutComponent implements OnInit {
  /**
  * These are the route elements used to build the virtual router and to render the list on the sidebar
  */
  @Input()
  routes: MENU[] = [];

  // This will be removed in the future because it is no longer necessary, it was not removed so as not to break the components that use it
  @Input()
  routerNodes: MenuNodeElement[] = [];
  @Input() overlay: boolean = true;
  @Input() menuItems: MENU[] = [];
  secondLevelNode!: MenuNodeElement | null;
  thirdLevelNode!: MenuNodeElement | null;
  isExpanded!: boolean;
  thirdLevelLabel = '';
  currentActive = '';
  flatRouterNodes: MENU[] = [];
  filteredRoutes: MENU[] = [];
  isThirdLevel: boolean = false;
  @Input() observerMode: boolean = false;
  private unsubscribe = new Subject<boolean>();
  footer!: FooterResponse[];
  hasErrorOccurred: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private _router: Router
  ) {
    this._route.data.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data['footer'].footerCollection) {
          this.hasErrorOccurred = false;
          this.footer = data['footer'].footerCollection.items;
        } else {
          this.hasErrorOccurred = true;
          this.footer = data['footer'].data;
        }
      },
      error: (error) => { }
    })
  }

  ngOnInit(): void {
    this.sidebarStatusHandler();
  }

  /**
   * Creates dynamically virtual routes for this component
   */
  sidebarStatusHandler(name?: string, isCloseClick?: boolean): void {
    if (isCloseClick) {
      this.isExpanded = false;
      this.secondLevelNode = null;
    }

    if (this.routes.length) {
      if (name) {
        this.currentActive = name;
      }

      if (!isCloseClick) {
        this.isExpanded = false;
      }
    }
  }

  /**
   * Set the current node to show in the secondary sidebar
   */

  setCurrentNode(currentNode: MenuNodeElement, name?: string) {
    if (this.secondLevelNode === currentNode) {
      this.isExpanded = !this.isExpanded;
    } else {
      this.isExpanded = true;
    }
    this.secondLevelNode = currentNode;
    if (name) {
      this.currentActive = name;
    }

    if (this.isThirdLevel) {
      this.isThirdLevel = false;
    }
  }

  setLevelNavigation(label?: string, node?: MenuNodeElement) {
    if (node) {
      this.thirdLevelNode = node;
    }

    if (label) {
      this.thirdLevelLabel = label;
    }
    this.isThirdLevel = !this.isThirdLevel;
  }

  isUserCreatorListRoute(): boolean {
    return this._router.url === '/user-creator/list';
  }

  /**
   * Triggers the action to logout the user from the identity server
   */
  logout(): void {
    this.authenticationService.logout();
  }

}
