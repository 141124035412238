import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoBlankStart]'
})
export class NoBlankStartDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string): void {
    this.onInputChange(event);
  }

  onInputChange(event: string): void {
    if (!event) {
      return;
    }
    if (event.trim() === '') {
      this.ngControl.valueAccessor!.writeValue('');
    }

  }
}
