<!-- Contact Layout Container -->
<section class="contact-layout-container" [ngClass]="{ 'contact-layout-get-errors': hasErrorOccurred }">
  <div class="contact-layout-body">

    <!-- Contact Components Section -->
    <!-- This section displays the contact components if no error has occurred. -->
    <ng-container *ngIf="!hasErrorOccurred">

      <!-- Information Cards Section -->
      <div *ngIf="informationCards" class="grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-2.28 sm:gap-1.14 body-info-cards">
        <ng-container *ngFor="let item of informationCards">
          <!-- Info Card Component -->
          <app-info-card [data]="item"></app-info-card>
        </ng-container>
      </div>

      <!-- About Us Section -->
      <!-- This section displays information about us. -->
      <div *ngIf="aboutUs" class="mt-2.28 body-about-us">
        <!-- About Us Component -->
        <app-about-us [data]="aboutUs"></app-about-us>
      </div>

    </ng-container>

    <!-- Error Handle Component Section -->
    <!-- This section displays the error handle component if an error has occurred. -->
    <ng-container *ngIf="hasErrorOccurred">
      <!-- Error Handle Component -->
      <!-- This component displays an error message based on the given error category code. -->
      <error-handle [errorCategoryCode]="ERROR_CATEGORY_CODE.Content_Retrieval_Error"></error-handle>
    </ng-container>

  </div>
</section>
