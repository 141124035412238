<section mat-dialog-content class="change-log-container">
  <div class="change-log-header">
    <h5>User Change Log</h5>
    <div class="flex items-center justify-between">
      <div class="">
        <p class="name">{{data.name}}</p>
        <p class="email">{{data.email}}</p>
      </div>
      <div class="date-picker-container">
        <mat-form-field appearance="fill">
          <mat-label>Search By Date</mat-label>
          <input matInput [formControl]="searchDateControl" [matDatepicker]="picker" [max]="currentDate"
            onkeydown="javascript:void(0)" (click)="picker.open()" readonly>
          <mat-datepicker-toggle *ngIf="!searchDateControl.value" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint class="text-sm">MM/DD/YYYY</mat-hint>
          <button mat-icon-button matSuffix *ngIf="searchDateControl.value" aria-label="Clear"
            (click)="resetDateSearchAndPagination()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="change-log-body">
    <mat-progress-bar *ngIf="isLoadingSignal()" mode="indeterminate"></mat-progress-bar>
    <div class="login-history">
      <div class="project-wage-table-card">
        <table mat-table aria-label="Table Template" class="project-wage-table-card-data" [dataSource]="dataSource" matSort>
          <ng-container>
            <ng-container class="project-wage-table-card-data-container" [matColumnDef]="tableColsRefConsts.note">
              <th class="project-wage-table-card-data-container-header" scope="col" mat-header-cell *matHeaderCellDef
                mat-sort-header>Fields Changed</th>
              <td mat-cell *matCellDef="let element">{{element.node.fieldsChanged }}</td>
            </ng-container>
            <ng-container class="project-wage-table-card-data-container" [matColumnDef]="tableColsRefConsts.changedBy">
              <th class="project-wage-table-card-data-container-header" scope="col" mat-header-cell *matHeaderCellDef
                mat-sort-header>Changed By</th>
              <td mat-cell *matCellDef="let element">{{element.node.changedByUserName}}</td>
            </ng-container>
            <ng-container class="project-wage-table-card-data-container" [matColumnDef]="tableColsRefConsts.date">
              <th class="project-wage-table-card-data-container-header" scope="col" mat-header-cell *matHeaderCellDef
                mat-sort-header>Date/Time</th>
              <td mat-cell *matCellDef="let element">{{element.node.changeDate | date:'MMM dd, YYYY'}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="tableHeaderReferences"></tr>
            <tr mat-row *matRowDef="let row; columns: tableHeaderReferences" class="project-wage-table-card-data-row">
            </tr>
          </ng-container>
        </table>
        <div *ngIf="changeLogDataSignal.length === 0 && hasBeenSearching() && !isErrorServerSignal()"
          class="no-results-table-banner">
          <p class="title">The current criteria did not yield any matching results.</p>
          <button (click)="resetDateSearchAndPagination()" mat-button color="primary">Clear search criteria</button>
        </div>
        <div *ngIf="changeLogDataSignal.length === 0 && !hasBeenSearching() && !isLoadingSignal() && !isErrorServerSignal()"
          class="no-results-table-banner">
          <p class="title">The current user does not have any change log data available yet.</p>
        </div>
        <div *ngIf="isErrorServerSignal()" class="no-results-table-banner-error">
          <p class="title">Apologies, an error occurred while retrieving the change log data. Please try again later.
          </p>
          <p class="subtitle">If the issue persists, please contact our support team for assistance.</p>
        </div>
      </div>

    </div>
  </div>
  <mat-dialog-actions align="end" class="snap-always">
    <div class="flex flex-col justify-end gap-2 align-items-end">
      <div [hidden]="changeLogDataSignal.length === 0">
        <mat-paginator #paginator [length]="this.paginationStateSignal().totalElements"
          [pageSize]="this.paginationStateSignal().pageSize" [pageSizeOptions]="[5, 10]" aria-label="Select page"
          showFirstLastButtons="true" (page)="handlePage($event)">
        </mat-paginator>
      </div>
      <div>
        <input cdkFocusInitial type="text" class="hidden" />
        <button mat-button [mat-dialog-close]="true">Close</button>
      </div>
    </div>
  </mat-dialog-actions>
</section>