import { gql } from 'apollo-angular';
import { Org } from 'src/app/features/dashboard/graphql/profile-info.query';


export const CLAIMS_QUERY_FOR_CANARY = gql`
  query findClaimsByKey($key: String!, $parentId: UUID!) {
        claims(where: {key: {contains: $key}, parentId: {eq: $parentId}}) {
          nodes {
            id
            parentId
            key
            value
        }
      }
    }
  `;

export interface ClaimsCanaryEntity {
  __typename: "Claim";
  id: string;
  parentId: string;
  key: string;
  value: string;
}

export interface ClaimsConnection {
  __typename: "ClaimsConnection";
  nodes: ClaimsCanaryEntity[];
}

export interface ClaimsCanaryResponse {
  claims: ClaimsConnection;
}


//#########################################################################################
//#########################################################################################

export const GET_USERPORTAL_SERVICE = gql`
  query getUserPortalServiceId{
  applicationServices(
    where: {claimPrefix:{contains:"User Portal"}}
    ){
    app: nodes{
      id
      claimPrefix
      dateStart
      dateExpire
      dateCreated
    }
  }
}
`;


// Interface for an individual application service item
export interface ApplicationService {
  id: string;
  claimPrefix: string;
  dateStart: string;  // ISO 8601 date string
  dateExpire: string | null;  // Nullable ISO 8601 date string
  dateCreated: string;  // ISO 8601 date string
}

export interface ApplicationServices {
  app: ApplicationService[];
}

export interface ApplicationServicesResponse {
  applicationServices: ApplicationServices;
}

//#########################################################################################
//#########################################################################################


export const CHECK_USER_CANARY = gql`
  query checkCanaryByUser($featureName: String!, $userId: UUID!) {
  userCanary: claims(
    where: { key: { contains: $featureName }, parentId: { eq: $userId } }
  ) {
   canaryNodes: nodes {
      id
      parentId
      key
      value
    }
  }
}
`;

export interface CanaryNodes {
  id: string;
  parentId: string;
  key: string;
  value: string;
}

export interface UserCanary {
  canaryNodes: CanaryNodes[];
}

export interface UserCanaryResponse {
  userCanary: UserCanary;
}


export const CHECK_ORG_CANARY = gql`
  query checkCanaryByUser($featureName: String!, $userId: UUID!) {
  userCanary: claims(
    where: { key: { contains: $featureName }, parentId: { eq: $userId } }
  ) {
   canaryNodes: nodes {
      id
      parentId
      key
      value
    }
  }
}
`;

export const BUILD_CHECK_ORG_CANARY = (orgs: Org[]): any => {
  if (!Array.isArray(orgs)) {
    // console.log('Error: orgs no es un array'); 
    return null;
  }

  let ORG_CANARY_QUERY_BUILT = 'query checkAllCanaryOrgs {';

  for (let index = 0; index < orgs.length; index++) {
    const orgId = orgs[index].org.id;

    const ORG_CANARY_QUERY = `
    orgCanary${index}: claims(
      where: { key: { contains: "Canary-Org" }, parentId: { eq: "${orgId}" } }
    ) {
      canaryNodes: nodes {
        id
        parentId
        key
        value
      }
    }`;

    ORG_CANARY_QUERY_BUILT += ORG_CANARY_QUERY;
  }

  ORG_CANARY_QUERY_BUILT += '}';

  return gql`${ORG_CANARY_QUERY_BUILT}`;
};