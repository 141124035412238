import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { PortalSharedService } from '../../services/portal-shared/portal-contentful.service';
import { NotificationBanner } from './notification';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  /*Injection Dependency By Angular 14 Feature*/
  private _userPortalSharedService = inject(PortalSharedService);


  @Input() title = 'Notifications';
  @Input() showNotify: boolean = true;
  showAllNotifications$: Observable<boolean> = new Observable<boolean>();
  showNotificationBanner$: Observable<boolean> = new Observable<boolean>();

  notifications!: NotificationBanner[] | null;
  extraNotifications: NotificationBanner[] | [] = [];
  extraNotificationLength: number = 0;
  hasAddedMoreNotifications: boolean = false;
  hasAddedAllNotifications = false;
  syncUpNotificationRemaining: boolean = false;

  isServerError$ = new Observable();
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.initNotificationMenu();
    this.synchronizeContentfulWithLocal();
    this.showAllNotificationStateAndBanner();
    this.getNotificationsBasedOnMenu();
    this.catchErrorRequestInTemplate();
  }

  catchErrorRequestInTemplate(): void {
    this.isServerError$ = this._userPortalSharedService.isServerError$.pipe(
      takeUntil(this._unsubscribeAll)
    );
  }

  getNotifications(viewAll: boolean = false): void {
    // Subscribe to the notifications observable
    const subscription = this._userPortalSharedService.notifications$.subscribe({
      next: (response) => {
        if (viewAll) {
          // If viewAll is true, display all notifications
          this.handleAllNotifications(response);
        } else {
          // If viewAll is false, display limited notifications
          this.handleLimitedNotifications(response);
        }
      },
      error: (error) => {
        this.handleError(error);
      },
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private handleAllNotifications(response: NotificationBanner[]): void {
    this.hasAddedAllNotifications = true;
    this.notifications = response;
  }

  private handleLimitedNotifications(response: NotificationBanner[]): void {
    const filteredNotifications = this.sortNotificationByUnReadState(response);
    const unreadCount = filteredNotifications.filter(item => !item.isRead).length;

    if (unreadCount > 2 && !this.hasAddedAllNotifications) {
      // If there are more than 2 unread notifications, display limited notifications
      this.handleLimitedNotificationsWithOverflow(filteredNotifications);
    } else {
      // Otherwise, display all filtered notifications
      this.handleLimitedNotificationsWithoutOverflow(filteredNotifications);
    }
  }

  private handleLimitedNotificationsWithOverflow(filteredNotifications: NotificationBanner[]): void {
    // Check if the user has already added notifications from the remaining
    if (!this.hasAddedMoreNotifications) {
      // Check if we need to sync up the notification remaining length (only the first time)
      if (!this.syncUpNotificationRemaining) {
        this.syncUpNotificationRemaining = true;
        // Calculate and set the extra notification length based on the remaining notifications
        this.extraNotificationLength = filteredNotifications.filter(item => !item.isRead).slice(2).length;
      }

      // Set the extra notifications to the notifications beyond the first two
      this.extraNotifications = filteredNotifications.slice(2);

      // Display the first two notifications
      filteredNotifications = filteredNotifications.slice(0, 2);
    } else {
      // If the user has added more notifications, set the extra notifications accordingly
      this.extraNotifications = filteredNotifications.slice(2);

      // Display notifications based on the current length of this.notifications
      filteredNotifications = filteredNotifications.slice(0, this.notifications?.length);
    }

    // Update the notifications to display
    this.notifications = filteredNotifications;
  }


  private handleLimitedNotificationsWithoutOverflow(filteredNotifications: NotificationBanner[]): void {
    this.extraNotifications = [];
    this.notifications = filteredNotifications;
  }

  private handleError(error: any): void {
    console.error('Something went wrong with notification Observable', error);
  }

  getNotificationsBasedOnMenu(): void {
    const subscription = this._userPortalSharedService.showAllNotifications$.subscribe({
      next: (response) => {
        if (response) {
          this.getNotifications(true);
        } else {
          this.getNotifications(false);
        }
      },
      error: () => {
        console.error('Something went wrong with resetNotificationLenght Observable')
      },
      complete: () => {
        subscription.unsubscribe();
      }
    })
  }

  // Function to move a notification from overflowNotifications to notifications
  showMoreNotifications(): void {
    this.hasAddedMoreNotifications = true;
    this.reduceNotificationRemaining();
    if (this.extraNotifications && this.extraNotifications.length > 0) {
      this.extraNotifications.length === 1 ? this.hasAddedAllNotifications = true : this.hasAddedAllNotifications = false;
      // Remove the first notification from overflowNotifications
      const movedNotification = this.extraNotifications.shift();
      if (movedNotification && this.notifications) {
        this.notifications.push(movedNotification);
      }
    }
  }

  sortNotificationByUnReadState(notifications: NotificationBanner[]): NotificationBanner[] {
    return notifications?.sort((a, b) => {
      if (a.isRead === false && b.isRead === true) {
        return -1;
      } else if (a.isRead === true && b.isRead === false) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  showAllNotificationStateAndBanner(): void {
    this.showAllNotifications$ = this._userPortalSharedService.showAllNotifications$.pipe(takeUntil(this._unsubscribeAll));
    this.showNotificationBanner$ = this._userPortalSharedService.showNotificationBanner$.pipe(takeUntil(this._unsubscribeAll));
  }

  countNotificationsUnRead(): number {
    return this._userPortalSharedService.countUnreadNotifications();
  }

  markNotificationAsReadInUnReadMenu(title: string): void {
    this.reduceNotificationRemaining();
    this._userPortalSharedService.setShowAllNotificationValue(false);
    this._userPortalSharedService.markNotificationAsReadByTitleId(title);
  }

  reduceNotificationRemaining(): void {
    this.extraNotificationLength = this.extraNotificationLength - 1;
  }

  markNotificationAsReadInUnAllMenu(title: string): void {
    this._userPortalSharedService.setShowAllNotificationValue(true);
    this._userPortalSharedService.markNotificationAsReadByTitleId(title);
  }

  synchronizeContentfulWithLocal(): void {
    this._userPortalSharedService.setNotificationStatesInLocalStorage();
  }
  initNotificationMenu(): void {
    this._userPortalSharedService.setShowAllNotificationValue(false);
  }

  closeNotification(): void {
    this._userPortalSharedService.setShowNotificationBannerValue(false);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.unsubscribe();
  }
}
