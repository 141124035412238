import { Injectable, inject } from '@angular/core';
import { FetchPolicy } from '@apollo/client/core';
import { ErrorHandleService } from 'src/app/shared/services/error-handle/error-handle.service';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { CSOStatusResponse, GET_CSO_STATUS } from '../graphql/get-cso-status.query.graphql';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Claim, ClaimsResponse, GET_CSO_REQUIRED_TRUE } from '../graphql/get-cso-required.query.graphql';
import { GET_ORGANIZATION_LICENSES, GET_ORG_NAME_BY_ORG_ID, LicensesNodes, LicensesResponse, Organization, OrganizationsData } from '../graphql/get-organization-data.query.graphql';
import { CREATE_TEMP_LICENSE_ORG_BY_ID, CreateLicenseClaimResponse, CreateTempLicenseInputModel } from '../graphql/mutations/create-temp-license-for-org.query.graphql';
import { Agreement, AgreementResponse, VERIFY_LIC_AGREEMENT } from '../graphql/verify-lic-agreement-user-in-org.query.graphql';
import { ADD_AGREEMENT_CLAIM_TO_ORG, DataClaim, PayloadOfClaim } from '../graphql/mutations/add-agreement-claim-to-org.query.graphql';

@Injectable({
  providedIn: 'root'
})
export class CsoPaymentService {

  graphqlHost = inject(GraphqlHostService);
  errorHandleService = inject(ErrorHandleService);
  private _csoLicenseAppStatus = new BehaviorSubject<string>('');
  private _orgsWithCsoRequired = new BehaviorSubject<Claim[]>([]);
  private _orgLicenses = new BehaviorSubject<LicensesNodes[]>([]);
  private _purchaseInformation = new BehaviorSubject<CreateLicenseClaimResponse | null>(null);
  public _userAgreements = new BehaviorSubject<Agreement[] | string[]>([]);
  private _orgName = new BehaviorSubject<Organization[]>([]);
  orgLicenses$ = this._orgLicenses.asObservable();

  getCSOStatus(policy: FetchPolicy = 'cache-first'): Promise<string> {
    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_CSO_STATUS,
        null,
        policy,
      ).then((response: CSOStatusResponse) => {
        const status = response.claims.nodes?.[0]?.value ?? '';
        this._csoLicenseAppStatus.next(status)
        resolve(status);
      }).catch(error => {
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'getCSOStatus', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    })
  }


  getCsoRequiredStatusByOrg(orgId: string[], policy: FetchPolicy = 'network-only'): Promise<Claim[]> {
    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_CSO_REQUIRED_TRUE,
        { orgId, key: 'CSORequired' },
        policy,
      ).then((response: ClaimsResponse) => {
        const filteredClaims = this.filterAndOrderClaims(response.claims.nodes)
        this._orgsWithCsoRequired.next(filteredClaims)
        resolve(filteredClaims);
      }).catch(error => {
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'getCsoRequiredStatusByOrg', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    })
  }


  getTypeOfLicensesByOrgIds(orgIds: string[], policy: FetchPolicy = 'network-only'): Promise<LicensesNodes[]> {
    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_ORGANIZATION_LICENSES,
        { orgIds },
        policy,
      ).then((response: LicensesResponse) => {
        const filteredLicenses = this.filterDuplicatedLicense(response.license.nodes!)
        this._orgLicenses.next(filteredLicenses)
        resolve(filteredLicenses);
      }).catch(error => {
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'getTypeOfLicensesByOrgIds', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    })
  }


  createTempLicense(createTempLicenseInputModel: CreateTempLicenseInputModel) {
    return new Promise((resolve, reject) => {
      this.graphqlHost.getMutationResults(
        environment.graphqlServerName.rbs,
        CREATE_TEMP_LICENSE_ORG_BY_ID,
        { createTempLicense: createTempLicenseInputModel },
      ).then((response: CreateLicenseClaimResponse) => {
        this._purchaseInformation.next(response)
        resolve(response);
      }).catch(error => {
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'getTypeOfLicensesByOrgIds', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    })
  }

  getUserServicesAgreement(usersInOrgs: string[], policy: FetchPolicy = 'cache-first'): Promise<Agreement[]> {
    return new Promise((resolve) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        VERIFY_LIC_AGREEMENT,
        { usersInOrgs },
        policy
      ).then((response: AgreementResponse) => {
        this._userAgreements.next(response.Agreements.AgreementByOrg);
        resolve(response.Agreements.AgreementByOrg);
      }).catch(error => {
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'getUserServicesAgreement', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    });
  }

  getOrgNameByOrgId(orgIds: any, policy: FetchPolicy = 'cache-first'): Promise<Organization[]> {
    return new Promise((resolve) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_ORG_NAME_BY_ORG_ID,
        { orgIds },
        policy
      ).then((response: OrganizationsData) => {
        this._orgName.next(response.organizations.nodes);
        resolve(response.organizations.nodes);
      }).catch(error => {
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'getOrgNameByOrgId', error);
        console.error(errorRejected);
        resolve(errorRejected);
      })
    })
  }

  addUserAgreements(addAgreementParameters: DataClaim): Promise<DataClaim> {
    const vars = {
      addAgreementParameters: {
        ...addAgreementParameters
      }
    };
    return new Promise((resolve, reject) => {
      this.graphqlHost
        .getMutationResults(
          environment.graphqlServerName.rbs,
          ADD_AGREEMENT_CLAIM_TO_ORG,
          vars
        )
        .then((response: PayloadOfClaim) => {
          resolve(response.data);
        })
        .catch((error: string) => {
          const getError = {
            origin: 'Add User Agreements',
            error,
          };
          reject(getError);
        });
    });
  }
  //helper methods
  filterAndOrderClaims(inputData: Claim[]): Claim[] {
    if (!inputData.length) {
      return []
    }
    const latestEntriesMap = new Map<string, Claim>();
    inputData.forEach(entry => {
      const currentLatestEntry = latestEntriesMap.get(entry.parentId);

      if (!currentLatestEntry || entry.dateCreated > currentLatestEntry.dateCreated) {
        latestEntriesMap.set(entry.parentId, entry);
      }
    });
    const uniqueData: Claim[] = Array.from(latestEntriesMap.values());
    uniqueData.sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1));
    return uniqueData;
  }


  filterDuplicatedLicense(licensesNodes: LicensesNodes[]): LicensesNodes[] {

    if (!licensesNodes.length) return licensesNodes;

    const parsedLicense = licensesNodes.map(obj => {
      let parsedValue;
      try {
        parsedValue = JSON.parse(obj.value as string);
      } catch (error) {
        console.error("Error parsing JSON string for:", obj.value as string);
      }
      return { ...obj, value: parsedValue };
    });

    const licenseSortedByETLPriorityAndDate = [...parsedLicense].sort((a, b) => {
      if (a.value.CreatedBy === 'ETL' && b.value.CreatedBy !== 'ETL') {
        return -1;
      }
      if (a.value.CreatedBy !== 'ETL' && b.value.CreatedBy === 'ETL') {
        return 1;
      }
      const dateA = new Date(a.value.dateCreated);
      const dateB = new Date(b.value.dateCreated);

      return dateA.getTime() - dateB.getTime();
    });

    const filteredLicense = licenseSortedByETLPriorityAndDate.filter((obj, index, arr) => {
      return (
        arr.findIndex(
          item =>
            item.parentId === obj.parentId &&
            item.value.LicType === obj.value.LicType
        ) === index
      );
    });
    return filteredLicense;
  }

  //  getters
  get csoAppStatusSubject(): Observable<string> {
    return this._csoLicenseAppStatus.asObservable();
  }
  get csoAppStatusValue(): string {
    return this._csoLicenseAppStatus.value;
  }

  get getOrgsWithCsoRequiredSubject(): Observable<Claim[]> {
    return this._orgsWithCsoRequired.asObservable();
  }
  get getOrgsWithCsoRequiredValue(): Claim[] {
    return this._orgsWithCsoRequired.value;
  }

  get getCsoLicensesSubject(): Observable<LicensesNodes[]> {
    return this._orgLicenses.asObservable();
  }
  get getCsoLicensesValue(): LicensesNodes[] {
    return this._orgLicenses.value;
  }

  get getPurchaseDataSubject(): Observable<any> {
    return this._purchaseInformation.asObservable();
  }
  get getPurchaseDataValue(): Claim | any {
    return this._purchaseInformation.value?.data;
  }
}
