<!-- Modal Component Wrapper -->
<div class="modal-wrapper">
  <!-- Modal Title -->
  <h5 mat-dialog-title>Authentication Rule</h5>

  <!-- Modal Content Container -->
  <main mat-dialog-content class="modal-content">
    <!-- Modal Content/Text -->
    <div class="body-row">
      <!-- <p>
        Ensure the selected Authentication Rule aligns with your organization's policies.
      </p> -->
      <ng-container [ngSwitch]="orgTpaInputValue.value">
        <p class="tpa-description" *ngSwitchCase="'NO_ENFORCEMENT'">
          <strong>Rule Description:</strong> No enforcement
        </p>
        <p class="tpa-description" *ngSwitchCase="'ADMINS_ONLY'">
          <strong>Rule Description:</strong> Enforced on admin type users associated to your organization.
        </p>
        <p class="tpa-description" *ngSwitchCase="'EVERYONE'">
          <strong>Rule Description:</strong> Enforced on admin and contractor type users associated to your organization.
        </p>
      </ng-container>
      <div class="w-full">
        <mat-form-field class="w-full" appearance="fill">
          <mat-label>Select the rule</mat-label>
          <mat-select [formControl]="orgTpaInputValue">
            <mat-option
              *ngFor="let item of TpaRules"
              [value]="item.value"
              [disabled]=""
            >
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Modal Actions/Buttons -->
    <div mat-dialog-actions class="buttons-row">
      <!-- Modal Cancel/Close Button -->
      <button mat-button mat-dialog-close color="primary">Cancel</button>

      <!-- Modal Confirm Button -->
      <button
        [disabled]="!orgTpaInputValue.value || orgTpaInputValue.value === tpaValue"
        (click)="onConfirm()"
        mat-raised-button
        color="primary"
      >
        Select
      </button>
    </div>
  </main>
</div>
