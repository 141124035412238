import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractLicType'
})
export class ExtractLicTypePipe implements PipeTransform {
  transform(value: string): string {

    if (value.toLocaleLowerCase().includes("free") || value.toLocaleLowerCase().includes("basic")) {
      return "Basic Support License";
    }
    if (value.toLocaleLowerCase().includes("monthly") || value.toLocaleLowerCase().includes("annual")) {
      return "Premium Support License";
    }
    return "License";
  }
}