<!-- Footer Container -->
<footer class="footer-container">

  <!-- Footer Links Section -->
  <div *ngIf="!hasContentfulError && headers?.length" class="footer-links">

    <!-- Footer Link Types/Group -->
    <ul *ngFor="let header of headers">

      <!-- Footer Link Type/Group Name -->
      <li class="link-header">{{ header.label }}</li>
      
      <!-- Footer Links -->
      <ng-container *ngFor="let item of links">

        <!-- Footer Link -->
        <li class="link-option" *ngIf="item.belongsTo === header.label">

          <a href="{{ item?.link }}" target="{{ item?.target }}">
            {{ item?.label }}</a
          >

        </li>

      </ng-container>

    </ul>

  </div>

  <!-- Copyright and Policies Section -->
  <div class="footer-copyright-policy">
    
    <!-- Copyright Section / Left Side -->
    <ul class="footer-copyright">

      <li>Copyright © {{currentYear}} LCPtracker Inc.</li>
      <li>All Rights Reserved.</li>

    </ul>

    <!-- Policies Section / Right Side -->
    <div class="footer-container-policy">

      <!-- 1st Column -->
      <ul class="footer-policy">
        <li><a href="https://lcptracker.com/ccpa/" target="_blank" rel="noopener">CA Privacy Policy</a></li>
      </ul>

      <!-- 2nd Column -->
      <ul class="footer-policy">
        <li><a href="https://lcptracker.com/privacy-policy-for-lcptracker" target="_blank" rel="noopener">Privacy Policy</a></li>
      </ul>

    </div>

  </div>

</footer>
