import { AuthenticationService } from './../../authentication/services/authentication.service';
import { inject } from '@angular/core';
import { CanActivateFn, Router, } from '@angular/router';
import { firstValueFrom } from 'rxjs';

export const whitelistGuard: CanActivateFn = async () => {
  const auth = inject(AuthenticationService);
  const router = inject(Router);
  try {
    const response = await firstValueFrom(auth.isUserIpInTheWhitelist());
    const isUserIpInTheWhitelistState = response;
    if (isUserIpInTheWhitelistState) {
      return true;
    } else {
      router.navigate(['/dashboard']);
      return false;
    }
  } catch (error) {
    router.navigate(['/dashboard']);
    return false;
  }
}

