import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Apollo, ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpHeaders, provideHttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

const contentfulGraphQLURI = environment.graphql.contentful;
const RBSGraphQL = environment.graphql.rbs;
@NgModule({
  imports: [CommonModule, BrowserModule, ApolloModule],
  providers: [
    provideHttpClient(),
    Apollo,
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink): NamedOptions => ({
        //We can set up here the graphql endpoints that we need
        ContentfulGraphQL: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: contentfulGraphQLURI,
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            })
          }),
        },
        RBSGraphQL: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: RBSGraphQL,
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            })
          }),
        },
      }),
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
