import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[only-numbers]',
})
export class OnlyNumbersDirective {
    @Input() allowDecimals = false;
    constructor(private readonly el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {

        const e = event;
        const allowed = [
            'Delete',
            'Backspace',
            'ArrowRight',
            'ArrowLeft',
        ];

        if(e.code === 'Space'){
            e.preventDefault();
           return;
        }
        if (this.allowDecimals) {
            allowed.push('Period');
            allowed.push('NumpadDecimal');
        }

        if (!isNaN(Number(e.key))) {
            return;
        }

        if (allowed.indexOf(e.code) > -1) {
            return;
        }
        e.preventDefault();
    }
}
