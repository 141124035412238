import { MarketingAdvertisementComponent } from './components/marketing-advertisement/marketing-advertisement.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialsModule } from 'src/app/core/materialIo.module';
import { DashboardRoutingModule } from './dashboard.routing';

import { AppCardComponent } from './components/app-card/app-card.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { HowCanIComponent } from './components/how-can-i/how-can-i.component';
import { SlideShowComponent } from './components/slide-show/slide-show.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxUsefulSwiperModule } from 'src/app/shared/lib/ngx-useful-swiper/ngx-useful-swiper.module';


@NgModule({
  declarations: [
    AppCardComponent,
    SlideShowComponent,
    DashboardComponent,
    ProfileCardComponent,
    MarketingAdvertisementComponent,
    HowCanIComponent,
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    DashboardRoutingModule,
    NgxUsefulSwiperModule,
    SharedModule
  ],
})
export class DashboardModule { }
