<section class="custom-dialog-container">
  <!-- Modal Title -->
  <h5 class="dialog_title" mat-dialog-title>{{title}}</h5>
  
  <!-- Modal Component Wrapper -->
  <div mat-dialog-content  class="modal-wrapper">
  
    <!-- Modal Content Container -->
    <main class="modal-content">
      <!-- Modal Content/Text -->
      <div class="body-row">
        <p *ngIf="hasOrgs"> Are you sure you would like to process the <strong>{{username}}</strong> user, with the following
        organization and roles associated?</p>
        <p *ngIf="!hasOrgs"> Are you sure you would like to create the <strong>{{username}}</strong> user, without assigning any organization or role?</p>
          <ul *ngIf="hasOrgs" >
            <li *ngFor="let item of orgName; let i = index;">
              <mat-icon>apartment</mat-icon> {{item | shortText:45}} | <span>{{this.roleName[i] }}</span>
            </li>
          </ul>
      </div>
      <div class="flex justify-center py-1" *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <!-- Modal Actions/Buttons -->
      <div *ngIf="confirmButton || cancelButton" mat-dialog-actions class="buttons-row">
  
          <!-- Modal Cancel/Close Button -->
          <button *ngIf="cancelButton" mat-button mat-dialog-close color="primary"
            [id]="'cancel-' + username"
            >{{cancelButton}}
          </button>
  
          <!-- Modal Confirm Button -->
          <button *ngIf="confirmButton" (click)="onConfirm(true)" mat-raised-button color="primary"
            [id]="'confirm-' + username"
            >{{confirmButton}}
          </button>
        </div>
  
    </main>
  </div>
  </section>
  
  