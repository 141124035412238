<!-- Header Component Wrapper -->
<div class="header-wrapper">

  <!-- Header Component - Desktop View Container -->
  <ng-container *ngIf="default">

    <!-- Header Container -->
    <header class="absolute w-full">


      <div class="inline-block header-mobile lg:hidden">
        
        <!-- Loading bar -->
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

        
        <div class="header-mobile-company p-2.28">
          
          <!-- Header Logo Container -->
          <div class="header-logo">

            <!-- Logo -->
            <div class="logo">

              <!-- Logo redirection route -->
              <a tabindex="0" title="Logo" [routerLink]="['/dashboard']">

                <!-- Logo Image -->
                <img alt="Lcptracker Logo" src="assets/images/logo_white.png" width="auto" height="auto"/>

              </a>

            </div>

          </div>

          <!-- Header Actions Container -->
          <div class="header-actions">


            <div id="nav-trigger">
              
              <!-- Notification Container -->
              <ng-container>

                <!-- Notification Button / Icon Container -->
                <a 
                  id="notify-bell"
                  tabindex="0" 
                  [ngClass]="{ 'notify-bell header-notify' : true}"
                >
                  
                  <!-- Notifications Badge Implementation -->
                  <div class="badge badge-mobile">

                    <!-- Notification Icon - Bell -->
                    <mat-icon
                      class="text-white"
                      matBadgeColor="accent"
                      matBadgeSize="medium"
                    >
                      notification_important
                    </mat-icon>

                    <!-- Notification Alternative Text -->
                    <span class="cdk-visually-hidden">
                        <!-- You have {{unreadNotifications}} Notifications -->
                    </span>

                  </div>

                </a>

              </ng-container>

            </div>

            <!-- Hamburger Menu - Mobile Display Only -->
            <div [ngClass]="{'mobile-menu': true, 'close-nav': isOpen === true}">

              <!-- Hamburger Menu Icon -->
              <div class="icon" (click)="toggleHam()" (keydown)="toggleHam()">

                <!-- Hamburger Menu Icon Element -->
                <div class="bar b1"><span></span></div>

                <!-- Hamburger Menu Icon Element -->
                <div class="bar b2"><span></span></div>

                <!-- Hamburger Menu Icon Element -->
                <div class="bar b3"><span></span></div>

              </div>
              
            </div>

          </div>

        </div>

      </div>


      <div class="hidden header-container lg:flex lg:flex-col lg:justify-start">
        <div class="header-app-bar">
          
          <!-- Header Left Side Content -->
          <div class="header-desk-left">
            
            <!-- Logo Button -->
            <a [routerLink]="['/dashboard']" class="header-desk-logo">

              <!-- Logo Image -->
              <img alt="lcptracker-logo" [attr.src]="logo" class="desk-logo-image" width="auto" height="auto" />

            </a>
            <!-- Role dropdown -->
            <!-- <div class="header-role" *ngIf="hasApplication"> -->
              <!-- Account  -->
              <!-- <span class="label-indicator">Account:</span>
              <button mat-button type="button">
                {{applicationName}} <span class="material-icons md-14">arrow_drop_down</span>
              </button> -->
            <!-- </div> -->
          </div>

          <!-- Header Right Side Content -->
          <div class="header-desk-right">

            <!-- Notifications Button Container -->
            <div *ngIf="(isServerError$ | async) === false"  class="header-notify">

              <!-- Notifications Button -->
              <button
                class="bell-icon-button"
                [matMenuTriggerFor]="menu"
                aria-label="notification-button"
                color="accent"
                mat-mini-fab>

                <!-- Notifications Button Icon -->
                <mat-icon
                  class="text-white notification-bell"
                  matBadgeColor="accent"
                  matBadgeOverlap="true"
                  matBadgeSize="medium"
                  [matBadge]="countUnReadNotifications()"
                >
                  notification_important
                </mat-icon>

              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item
                (click)="openNotify(false)"
                (keyup.enter)="openNotify(false)">
                  <mat-icon class="mark_email_unread" >mark_email_unread</mat-icon>
                  <span>Unread Notifications <span> ({{countUnReadNotifications()}})</span> </span>
                </button>
                <button mat-menu-item
                (click)="openNotify(true)"
                (keyup.enter)="openNotify(true)">
                  <mat-icon>mark_email_read</mat-icon>
                  <span>View All Notifications<span> ({{countAllNotifications()}})</span> </span>
                </button>
              </mat-menu>

            </div>

            <!-- Logout Button -->
            <app-button
              buttonType="header"
              label="Logout"
              prefixIcon="logout"
              (buttonClicked)="openLogoutConfirmationDialog()"
            >
            </app-button>

          </div>

        </div>

        <!-- Actions Bar -->
        <div class="header-user-bar">

        <!-- User Profile Card -->
        <div class="user-avatar-name-container">
          <button color="primary" mat-button [matMenuTriggerFor]="menu" >
            <mat-icon  aria-hidden="false" aria-label="Example home icon" fontIcon="account_circle"></mat-icon>
            {{userData?.userName}}</button>
            <mat-menu class="user-menu-card" #menu="matMenu" xPosition="before">
              <app-user-profile-card [user]="userData"></app-user-profile-card>
            </mat-menu>
        </div>
          <!-- Update Additional Links -->
          <div *ngIf="additionalLinks" class="header-user-console"></div>

        </div>

      </div>

    </header>

  </ng-container>

  <!-- PRESENTATION ONLY - NOT FOR COPY / PASTE -->
  <!-- Header Component - Mobile View Container -->
  <ng-container *ngIf="mobileOnly">
    <header class="w-full m-auto">
      <div class="header-mobile">
        <mat-progress-bar *ngIf="loading === true" mode="indeterminate"></mat-progress-bar>
        <div class="header-mobile-company p-1.14">
            <div class="header-logo">
                <div class="logo">
                    <a tabindex="0" title="Logo" [routerLink]="['/home']">
                      <img alt="Lcptracker Logo" src="assets/images/logo_white.png" width="auto" height="auto" />
                    </a>
                </div>
            </div>
            <div class="header-actions">
              <div id="nav-trigger">
                <ng-container *ngIf="(isServerError$ | async) === false">
                  <a  [matMenuTriggerFor]="menu"  id="notify-bell" tabindex="0"
                      [ngClass]="{ 'notify-bell header-notify' : true}"
                      >
                      <div class="badge badge-mobile">
                        <mat-icon [matBadge]="countUnReadNotifications()"
                                matBadgeColor="accent"
                                matBadgeSize="medium"
                                class="text-white"
                                >notification_important
                        </mat-icon>
                        <span class="cdk-visually-hidden">
                            You have {{countUnReadNotifications()}} Notifications
                        </span>
                      </div>
                  </a>
                  <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item
                    (click)="openNotify(false)"
                    (keyup.enter)="openNotify(false)">
                      <mat-icon class="mark_email_unread" >mark_email_unread</mat-icon>
                      <span>Unread Notifications <span> ({{countUnReadNotifications()}})</span> </span>
                    </button>
                    <button mat-menu-item
                    (click)="openNotify(true)"
                    (keyup.enter)="openNotify(true)">
                      <mat-icon>mark_email_read</mat-icon>
                      <span>View All Notifications<span> ({{countAllNotifications()}})</span> </span>
                    </button>
                  </mat-menu>
                </ng-container>
              </div>
              <div id="mobile-menu" [ngClass]="{'mobile-menu': true, 'close-nav': isOpen === true}">
                <div class="icon" (click)="toggleHam()" (keydown)="toggleHam()">
                    <div class="bar b1"><span></span></div>
                    <div class="bar b2"><span></span></div>
                    <div class="bar b3"><span></span></div>
                </div>
              </div>

            </div>
          </div>
      </div>
    </header>
  </ng-container>
</div>
