import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot,
} from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { DashboardContentfulPage } from '../graphql/dashboard-data.query';

export const dashboardContentResolver: ResolveFn<DashboardContentfulPage> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(DashboardService).fetchDashboardPage('cache-first');
}

export const profileUserContentResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(DashboardService).fetchProfileCardInfo('cache-first');
}

export const appTilesContentResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(DashboardService).fetchAppTiles('cache-first');
}
