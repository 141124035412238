import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-confirmation-bottom-sheet',
  template: `
  <app-bottom-sheet (closeComponent)="handleCloseEvent($event)"></app-bottom-sheet>
  `,
})
export class ConfirmationBottomSheetComponent {

  constructor(private _bottomSheetRef: MatBottomSheetRef<ConfirmationBottomSheetComponent>) { }

  handleCloseEvent($event: any): void {
    this._bottomSheetRef.dismiss();
  }
}
