import { Component, Input, ViewChild } from '@angular/core';

import { CarouselItem } from '../../graphql/dashboard-data.query';
import { SwiperComponent } from 'src/app/shared/lib/ngx-useful-swiper/swiper/swiper.component';
import { SwiperOptions } from 'swiper/types';
@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.scss'],
})
export class SlideShowComponent{

  @ViewChild('usefulSwiper', { static: false }) usefulSwiper!: SwiperComponent;
  @Input() slides: CarouselItem[] = [];


  @Input() config: SwiperOptions = {
    autoplay: { delay: 2500, disableOnInteraction: true },
    pagination: { el: '.swiper-pagination', clickable: true },
    coverflowEffect: {
      rotate: 30,
      slideShadows: false,
    },
    centeredSlides: false,
    height: 250,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    observer: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },

      576: {
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      1025: {
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    },
  };

  constructor() { }

  getStyles(index: number) {
    let result = {
      'background-image': 'url(' + this.slides[index].asset.url + ')',
    };
    return result;
  }

  callToAction(slide: any) {
    switch (slide.linktype) {
      case 'redirect':
        window.open(slide.link, slide.target);
        break;
      case 'movie':
        break;
      case 'docs':
        break;
    }
  }
}
