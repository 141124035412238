import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, catchError, filter, of, switchMap, tap } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from 'src/environments/environment';
import { ERROR_CATEGORY_CODE } from 'src/app/shared/components/error-handle/error-handle-const';
import { getTargetUrl } from 'src/app/shared/helpers/env.helper';

type onboardingClaim = string | number | boolean | string[];
@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss'],
})
export class LoginCallbackComponent implements OnInit, OnDestroy {

  ERROR_CATEGORY_CODE = ERROR_CATEGORY_CODE;
  ERROR_CODE!: string;
  hasErrorOccurred: boolean = false;
  logo: string = 'assets/images/lcptracker-full-color.svg';
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  diameter = 40;

  header: string = `Loading...`;
  tagline: string = '';
  responseTimer: number = 0;
  interval!: ReturnType<typeof setInterval>
  private authenticationSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.startTimerLoadingPage();
    this.initSubscriptions();
  }

  initSubscriptions() {
   this.authenticationSubscription = this.oidcSecurityService.isAuthenticated$
      .pipe(
        // Filter only for cases where the user is authenticated
        filter(
          (isAuthenticatedResult: AuthenticatedResult) =>
            isAuthenticatedResult.isAuthenticated
        ),
        // Handle errors that might occur in the authentication process
        catchError((error) => {
          this.hasErrorOccurred = true;
          this.ERROR_CODE = ERROR_CATEGORY_CODE.Authentication_Status_Problem;
          return of(null); // Return an observable to continue the stream
        }),
        // Switch to the getPayloadFromIdToken() observable
        switchMap(() =>
          this.oidcSecurityService.getPayloadFromIdToken().pipe(
            catchError((error) => {
              this.hasErrorOccurred = true;
              this.ERROR_CODE = ERROR_CATEGORY_CODE.Incomplete_Session_Identifier;
              return of(null); // Return an observable to continue the stream
            })
          )
        )
      )
      .subscribe({
        next: (response: Record<string, onboardingClaim>) => {
          // clearInterval(this.interval);
          // Check if the token is empty
          if (this.tokenIsEmpty(response)) {
            this.hasErrorOccurred = true;
            this.ERROR_CODE = ERROR_CATEGORY_CODE.Invalid_Session_Identifier;
            return;
          }
          // Get authentication data from local storage
          const userPortalAuthData = this.authenticationService.getAuthDataFromLocalStorage();
          // Parse storage info from session storage
          const storageInfo = JSON.parse(
            sessionStorage.getItem('0-UserPortSpa') || '{}'
          );

          // Update the expirationDate in userPortalAuthData if not set and storageInfo has access_token_expires_at
          if (storageInfo.hasOwnProperty('access_token_expires_at') && !userPortalAuthData.expirationDate) {
            const currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 12);
            userPortalAuthData.expirationDate = currentDate.getTime().toString();
            this.authenticationService.setNewAuthenticationDataObjectInLocalStorage(userPortalAuthData);
          }

          // Handle different cases based on response properties
          if (response['LastOnboarded']) {
            // Update user information and redirect to dashboard
            this.header = `You've logged in`;
            this.tagline = 'You will be redirected in a moment...';
            setTimeout(() => {
              this.router.navigateByUrl(getTargetUrl());
            }, 2000);
          } else {
            // Show message for incomplete profile and initiate onboarding
            this.header = 'Almost done!';
            this.tagline = 'Just a couple more steps to finish your new profile!';
            setTimeout(() => {
              this.handleMissingOnboardingClaims(response);
            }, 3000);
          }
        },
      });
  }


  handleMissingOnboardingClaims(response: Record<string, onboardingClaim>) {
    // Check if LastTermsAccepted claim is missing
    if (!response['LastTermsAccepted']) {
      // Redirect to the agreements page for onboarding
      window.location.href = environment.onboardingClaims.agreements;
      return;
    }

    // Check if LastProfileChanged claim is missing or has a specific value
    if (!response['LastProfileChanged'] || response['LastProfileChanged'] == '01/01/9999') {
      // Redirect to the last profile change page for onboarding
      document.location.href = environment.onboardingClaims.lastProfileChange;
      return;
    }

    // Check if LastPasswordChanged claim is missing or has a specific value
    if (!response['LastPasswordChanged'] || response['LastPasswordChanged'] == '01/01/9999') {
      // Redirect to the password change page for onboarding
      document.location.href = environment.onboardingClaims.password;
      return;
    }

    // Check if TwoFactorType claim is missing
    if (!response['TwoFactorType']) {
      // Redirect to the two-factor authentication page for onboarding
      document.location.href = environment.onboardingClaims.twoFactorAuthentication;
      return;
    }

    // If none of the missing claims matched, handle the case of other missing onboarding claims
    this.onboardingClaimIsMissing();
  }


  // Function to handle the case when onboarding claim is missing
  onboardingClaimIsMissing(): void {
    // Set error status and code for missing onboarding claim
    this.hasErrorOccurred = true;
    this.ERROR_CODE = ERROR_CATEGORY_CODE.Missing_Onboarding_Information;
  }

  // Function to check if a token object is empty
  tokenIsEmpty(value: any): boolean {
    return Object.keys(value).length === 0;
  }

  // Function to start a timer for loading page
  startTimerLoadingPage(): void {
    this.interval = setInterval(() => {
      this.responseTimer++; // Increment the counter
      if (this.responseTimer > 15 && this.responseTimer < 25) {
        this.tagline = 'This is taking longer than expected, please be patient...';
      }
      if (this.responseTimer >= 25) {
        this.tagline = 'Please ensure that you have a stable network connection, or feel free to reach out to our support team for assistance.';
      }
      if (this.responseTimer >= 26) {
        clearInterval(this.interval);
      }
    }, 1000); // Execute the interval every 1 second
  }

  refreshWorkFlow(): void {
    window.location.reload();
  }


  ngOnDestroy(): void {
    if (this.authenticationSubscription) {
      this.authenticationSubscription.unsubscribe();
    }
  }
}