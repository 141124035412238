import { Component, Inject, Input, OnDestroy, OnInit, ViewChild, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../../../consts/consts';
import { ChangeLogService } from '../../../services/change-log.service';
import { MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT } from '../../users/users.component';
import { PaginationService } from 'src/app/core/services/pagination.service';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit, OnDestroy {
  private readonly _paginationService = inject(PaginationService);
  private _changeLogService = inject(ChangeLogService);
  private _breakpointObserver = inject(BreakpointObserver);
  private _dialogRef = inject(MatDialogRef<ChangeLogComponent>);

  //Pagination Signals
  paginationStateSignal = this._changeLogService._paginationState;
  paginationVarsSignal = this._changeLogService._paginationVars;
  
  //Data Signal
  changeLogDataSignal: any = [];
  dataSource!: ChangeLogDataSource;

  //Loading Signals
  isLoadingSignal = this._changeLogService._loading;
  isErrorServerSignal = this._changeLogService._errorServer;

  /*Variables*/
  searchDateControl: FormControl = new FormControl<string>('', { nonNullable: false });
  shouldDialogBeClosed: boolean = false;
  hasBeenSearching = signal<boolean>(false);
  currentDate: Date = new Date();
  resettingField = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  /* Columns Ref Constants */
  tableColsRefConsts = {
    note: 'Note',
    changedBy: 'Changed By',
    date: 'Date',
  }

    /** * Names of the properties of the Data Source * * @required */ @Input()
  tableHeaderReferences: string[] = [
    'Note',
    'Changed By',
    'Date',
  ];

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string, name: string | null; email: string | null, userName: string }
  ) { }

  ngOnInit(): void {
    this.initValues();
    this.createDataSource();
    this.onSearchCriteriaFieldChanges();
    this.getChangeLogs();
    this.loadChangeLogData();
    this.forceCloseDialog();
  }

  /**
   * Initializes the values for pagination and search in the ChangeLogService.
   * This method sets initial values for pagination variables, pagination state, and search text.
   */
  initValues(): void {
    // Init pagination variables to initial values
    this._changeLogService._paginationVars.set(INIT_PAGINATION_VARS);
    // Init pagination state to initial values
    this._changeLogService._paginationState.set(INIT_PAGINATION_STATE);
    // Init last page size change
    this._changeLogService._lastPageSizeChange.set(0);
    // Clear the search text by setting it to an empty string
    this._changeLogService._onSearchText.set('');
  }


  /**
  * Handles the search criteria from the form input
  */
  onSearchCriteriaFieldChanges(): void {
    this.searchDateControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(formValue => {
        if (formValue && !this.resettingField) {
          const date = new Date(formValue);
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // add 1 to get 1-based index
          const day = date.getDate();
          const formattedDate = `${year}-${month}-${day}`;
          this._changeLogService.searchBy(formattedDate);
          this.hasBeenSearching.set(true);
          this.paginator.firstPage();
          this.getChangeLogs();
        }
      });
  }

  getChangeLogs(): void {
    this._changeLogService.getChangeLogs('network-only',this.data.id);
  }

  loadChangeLogData(): void {
    this._changeLogService.onChangeLogDataChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: response => {
        this.changeLogDataSignal = response;
      }
    });
  }

  /**
  * Creates a new data source for the ChangeLogComponent.
  * Initializes the data source with an instance of ChangeLogDataSource,
  * passing the ChangeLogService as a dependency.
  */
  createDataSource(): void {
    this.dataSource = new ChangeLogDataSource(this._changeLogService);
  }

  /**
   * Monitors the screen width using BreakpointObserver and closes the dialog
   * if the screen width is below a certain threshold defined by MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT.
   */
  forceCloseDialog(): void {
    this._breakpointObserver
      .observe(`(max-width: ${MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT}px)`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        this.shouldDialogBeClosed = result.matches;
        if (this.shouldDialogBeClosed) {
          this._dialogRef.close(true); // Close the dialog if shouldDialogBeClosed is true
        }
      });
  }

  resetDateSearchAndPagination(): void {
    this.resettingField = true;
    this.hasBeenSearching.set(false);
    this.searchDateControl.reset();
    this._changeLogService._onSearchText.set('');
    this.resettingField = false; 
  }

  /**
    * Resets the pagination to the initial page forcefully
  **/
  resetPaginationToInitialPage(): void {
    this._changeLogService._paginationVars.set(INIT_PAGINATION_VARS);
    this.changeLogDataSignal.length ? this.paginator.firstPage() : null;
  }

  /****************************** PAGINATION METHODS **********************************/

  /**
   * Handles the pagination events integrated the global pagination service 
   * to calcute the correct pagination vars based on the page events
   */
  handlePage($event: PageEvent): void {
    let pageMovedToFirstOrLast = false;

    // SET NEW CURRENT PAGE SIZE
    if (this.paginationStateSignal()!.pageSize !== $event.pageSize) {
      const paginationSettings = this._paginationService.handlePageSizeChange($event);
      this._changeLogService._paginationVars.set(paginationSettings);
    }

    //FIRST PAGE
    if ($event.pageIndex === 0) {
      pageMovedToFirstOrLast = true;
      const paginationSettings = this._paginationService.goToFirstPage($event);
      this._changeLogService._paginationVars.set(paginationSettings);
    }

    //LAST PAGE
    if ($event.pageIndex == this._paginationService.getNumberOfPages(this.paginationStateSignal() ?? INIT_PAGINATION_STATE)) {
      pageMovedToFirstOrLast = true;
      this._changeLogService._lastPageSizeChange.set($event.pageSize);
      const paginationSettings = this._paginationService.goToLastPage($event);
      this._changeLogService._paginationVars.set(paginationSettings);
    }

    // PREVIOUS PAGE
    if ($event.previousPageIndex !== undefined && $event.pageIndex < $event.previousPageIndex && !pageMovedToFirstOrLast) {
      const paginationSettings = this._paginationService.handlePreviousPage($event, this.paginationStateSignal() ?? INIT_PAGINATION_STATE);
      this._changeLogService._paginationVars.set(paginationSettings);
    }

    // NEXT PAGE
    if ($event.previousPageIndex !== undefined && $event.pageIndex > $event.previousPageIndex && !pageMovedToFirstOrLast) {
      const paginationSettings = this._paginationService.handleNextPage($event, this.paginationStateSignal() ?? INIT_PAGINATION_STATE);
      this._changeLogService._paginationVars.set(paginationSettings);
    }

    this.getChangeLogs();
  }
  /****************************** END OF PAGINATION METHODS **********************************/


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

/**
 * Custom data source for ChangeLogComponent.
 * Extends the DataSource class from Angular Material.
 * Connects to the ChangeLogService's onChangeLogDataChanged observable to provide data for the table.
 */
export class ChangeLogDataSource extends DataSource<any[]> {
  constructor(private _changeLogService: ChangeLogService) {
    super();
  }

  /**
   * Connects to the onChangeLogDataChanged observable of the ChangeLogService.
   * @returns An observable that emits the change log data when it changes.
   */
  connect(): Observable<any[]> {
    return this._changeLogService.onChangeLogDataChanged;
  }

  /**
   * Disconnects the data source.
   */
  disconnect(): void { }
}