<div class="main-container">
  <div class="header-container">
    <app-header></app-header>
  </div>
  <main class="drawer-container">
    <div class="drawer-sidebar">
      <!-- secondary-sidebar -->
      <ng-container *ngIf="secondLevelNode && secondLevelNode.children && isExpanded">
        <div class="secondary-sidebar" *ngIf="!isThirdLevel" [@fadeInLeft]="isExpanded"
          [@fadeOutLeft]="!isExpanded ? null : ''">
          <header>
            <h5>{{secondLevelNode.label}}</h5>
          </header>
          <div routerLinkActive="item-selected" (click)="sidebarStatusHandler()" (keypress)="sidebarStatusHandler()"
            *ngFor="let secondTierNode of secondLevelNode.children">
            <!-- second level routes -->
            <!-- for links to an external url -->
            <ng-container *ngIf="!secondTierNode.node && secondTierNode.target !== 'user-portal'">
              <a id={{secondTierNode.label}} class="item ripple" [attr.href]="secondTierNode.route"
                [attr.target]="'_self'" (click)="isExpanded=false">
                <span class="sidebar-item">
                  {{secondTierNode.label}}
                </span>
              </a>
            </ng-container>
            <!-- for inner app navigation -->
            <ng-container *ngIf="!secondTierNode.node && secondTierNode.target === 'user-portal'">
              <a class="item ripple" [routerLink]="secondTierNode.route" (click)="isExpanded=false">
                <ng-container *ngIf="secondTierNode.target === 'user-portal'">
                  <span id={{secondTierNode.label}} class="sidebar-item ">
                    {{secondTierNode.label}}
                  </span>
                </ng-container>
              </a>
            </ng-container>
            <!-- for handling sub menu -->
          </div>
        </div>
        <div class="backdrop-drawer-main-container" (click)="isExpanded = false" (keydown)="isExpanded = false"></div>
      </ng-container>

      <!-- Main 1st level navigation -->
      <div class="drawer-list">
        <div>
          <ng-container *ngFor="let menu of menuItems">
            <!-- for links to an external url -->
            <ng-container *ngIf="!menu.node && menu.target !== 'user-portal'" (click)="isExpanded=false">
              <a id={{menu.label}} class="item ripple" [attr.href]="menu.route" [attr.target]="'_self'">
                <span class="sidebar-item">
                  {{menu.label}}
                </span>
              </a>
            </ng-container>
            <!-- for inner app navigation -->
            <ng-container *ngIf="!menu.node && menu.target === 'user-portal'">
              <a routerLinkActive="item-selected" class="item ripple" [routerLink]="menu.route"
                (click)="isExpanded=false" slideInTop>
                <ng-container *ngIf="menu.target === 'user-portal'">
                  <span id={{menu.label}} class="sidebar-item ">
                    {{menu.label}}
                  </span>
                </ng-container>
              </a>
            </ng-container>
            <!-- for handling sub menu -->
            <ng-container *ngIf="menu.label  && menu.node">
              <a class="item" (click)="setCurrentNode(menu.node , menu.node.label )"
                (keydown)="setCurrentNode(menu.node, menu.node.label)">
                <span class="sidebar-item ripple">
                  {{menu.label}}
                </span>
                <mat-icon aria-hidden="false" aria-label="Chevron">chevron_right</mat-icon>
              </a>
            </ng-container>
          </ng-container>
        </div>
        <!-- con-browser button -->
        <div class="co-browse-desktop">
          <a class="monarch-button-co-browse monarch-button--header" tabindex="0"
            onkeypress="window.coBrowsingInstance && coBrowsingInstance.showPopup();return false;"
            onclick="window.coBrowsingInstance && coBrowsingInstance.showPopup();">
            <mat-icon>
              ads_click
            </mat-icon> Co-Browse
          </a>
        </div>

        <ng-container *ngFor="let route of routes; let i = index">
          <!-- Routes -->
          <ng-container *ngIf="route.label && route.route && !route.node">
          </ng-container>
          <!-- Nodes -->
          <ng-container *ngIf="route.label && !route.route && route.node">
            <div class="sidebar-item ripple" [ngClass]="{'item-selected': currentActive === route.label}"
              (click)="setCurrentNode(route.node, route.label )"
              (keydown)="setCurrentNode(route.node, route.node.label)">
              <a>
                {{route.label}}
                <mat-icon aria-hidden="false" aria-label="Example home icon">chevron_right</mat-icon>
              </a>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <!-- app content -->
    <div class="drawer-content" [ngClass]="[isExpanded && !overlay ? 'tight-content':'expanded-content', isUserCreatorListRoute() ? 'remove-padding' : '']">
      <app-notification></app-notification>
      <!-- Router outlet projection -->
      <ng-content></ng-content>
      <!--End Router outlet projection -->
      <div class="side-footer-content" >
        <app-footer [hasContentfulError]="hasErrorOccurred" [footerData]="footer"></app-footer>
      </div>
    </div>
  </main>
</div>