<div *ngIf="user" class="user-profile-container">
  <div *ngIf="user.firstName && user.lastName" class="header">
    <div *ngIf="avatarName; else elseBlock"
      class="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-full avatar-container">
      <span class="font-medium">{{ avatarName }}</span>
    </div>
    <div class="username">
      <p>{{fullName! | shortText:50}}</p>
    </div>
    <ng-template #elseBlock>
      <mat-icon
        aria-hidden="false"
        aria-label="Example home icon"
        fontIcon="account_circle"
      ></mat-icon>
    </ng-template>
  </div>
  <div class="body">
    <div class="username-info">
      <div>
        <p>Username: <strong>{{user.userName! | shortText:50}}</strong></p>
        <p>Contact Email: <strong>{{user.email ? (user.email! | shortText:50) : '--'}}</strong></p>
      </div>
      <div class="flex items-center">
        <p class="flex items-center gap-2">
          <mat-icon *ngIf="user.twoFactorEnabled" class="text-green-600" aria-hidden="false" aria-label="Example home icon" fontIcon="lock"></mat-icon> 
          <mat-icon *ngIf="!user.twoFactorEnabled" class="text-red-600" aria-hidden="false" aria-label="Example home icon" fontIcon="lock_open"></mat-icon> 
          2FA is turned {{ user.twoFactorEnabled ? 'ON': 'OFF' }}
        </p>
      </div>
      <a id="edit-profile-dashboard" [href]="profileFortifyUrl" target="_blank" rel="noopener noreferrer">Edit Profile</a>
    </div>
  </div>
</div>
