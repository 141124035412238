import { gql } from "apollo-angular";

export const GET_CSO_STATUS = gql`
query{
  claims(
   where: {key : {eq: "CSOStatus"}},
   order: { dateCreated: DESC }
  ){
    nodes {
      id
      key
      value
      dateCreated
    }
  }
}`;

export interface CSOStatusResponse {
  claims: Claims;
}
export interface Claims {
  nodes?: (NodesEntity)[] | null;
}
export interface NodesEntity {
  id: string;
  key: string;
  value: string;
  dateCreated: string;
}

