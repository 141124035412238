import { environment } from 'src/environments/environment';
import { LogLevel } from 'angular-auth-oidc-client';
import { OpenIdConfiguration } from './auth-config';

export const configUserPortSpa: OpenIdConfiguration = {
    // The authority is the URL of the identity provider (e.g., authentication server).
    authority: environment.authentication.issuer,

    // The client ID is a unique identifier for your Angular SPA.
    clientId: 'UserPortSpa',

    // The route to navigate to if a user is forbidden (access denied).
    forbiddenRoute: '/not-authorized',

    // The URL to redirect to after the user logs out.
    postLogoutRedirectUri: environment.authentication.postLogoutRedirectUri,

    // The URL to redirect to after a successful login.
    redirectUrl: environment.authentication.redirectUrl,

    // The type of response expected from the authorization server (code for code flow).
    responseType: 'code',

    // The scope of the access requested.
    scope: environment.authentication.scope,

    // Set to true to trigger an event when authorization result is received.
    triggerAuthorizationResultEvent: true,

    // The route to navigate to if a user is unauthorized (not authenticated).
    unauthorizedRoute: '/login',

    // **************** Refresh Token Properties *******************************
    useRefreshToken: true, // Enable refresh token support.

    // Automatically renew tokens using a silent renew (background token refresh).
    silentRenew: true,

    // The interval (in seconds) to refresh the access token using a refresh token.
    tokenRefreshInSeconds: 30,

    // The time (in seconds) before the token expires to initiate a silent renew.
    renewTimeBeforeTokenExpiresInSeconds: environment.authentication.renewTimeBeforeTokenExpiresValue, // This property silently initiates the request for a refresh token, aligning it with the ID TOKEN's expiration timeframe.


    // The maximum time (in seconds) to wait for a response during silent renew.
    silentRenewTimeoutInSeconds: 30,
};
