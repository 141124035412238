<ng-container *ngIf="data">
  <div *ngFor="let item of data" class="about-us-container vtg-box-shadow">
    <h3 class="about-us-title">About us</h3>
    <p class="about-us-description">
      {{this.item.description}}
    </p>

    <div class="grid about-us-body" [ngClass]="gridValue">
      <div *ngFor="let image of item.images.items; let i = index" class="sm:col-span-1 md:col-span-1 about-us-office">
        <img class="img-office" src="{{image.url}}"  alt="Office in {{item.officeLocation[i]}}" height="auto" width="auto" />
        <h5>{{item.officeLocation[i]}}</h5>
        <div class="flex flex-col items-start about-us-actions">
          <a color="primary"
            href="mailto:{{item.email[i]}}"
            id="send-verification-code-action">
              <mat-icon fontIcon="mail"></mat-icon>
            Email us
          </a>
          <a color="primary"
              href="mailto:{{item.email[i]}}"
              id="send-verification-code-action">
                <mat-icon fontIcon="support_agent"></mat-icon>
          Call us
          </a>
        </div>
        <!-- <ul> -->
          <!-- <li><a"><mat-icon class="icon-size">mail</mat-icon>Email us</a></li> -->
          <!-- <li><a href="tel:{{item.phoneNumber[i]}}"><mat-icon class="icon-size">support_agent</mat-icon>Call us</a></li> -->
        <!-- </ul> -->
      </div>
    </div>
  </div>
</ng-container>
