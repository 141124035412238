import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PaginationVars } from 'src/app/features/support-console/graphql/support-console.queries.graphql';
import { GlobalPaginationState, GlobalPaginationVars } from '../interfaces/global-interfaces';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor() { }

  /**
   * Handles the logic for updating the global pagination variables when the page size changes.
   *
   * @param event - The `PageEvent` object containing the new page size and pagination settings.
   *
   * @returns An object containing the updated pagination variables:
   *          - `first`: The new page size (number of items to load).
   *          - `after`, `before`, `last`: Set to `null` since this method is specific to a page size change and not for navigation.
   *
   * This method is useful for cases where only the page size needs to be adjusted without altering the current page position or direction.
   */
  handlePageSizeChange(event: PageEvent): GlobalPaginationVars {
    return {
      first: event.pageSize,
      after: null,
      before: null,
      last: null,
    };
  }

  /**
 * Handles the logic for navigating to the next page in a paginated list.
 *
 * @param event - The PageEvent object containing information about the current page and pagination settings.
 * @param paginationState - The current state of the global pagination, containing information such as the end cursor.
 *
 * @returns A new set of pagination variables to be used for fetching the next page of data.
 *          The returned object includes the first, after, before, and last properties, which represent the pagination settings.
 *          In this case, the first property is set to the page size, the after property is set to the end cursor from the pagination state,
 *          and the before and last properties are set to null.
 */
  handleNextPage(event: PageEvent, paginationState: GlobalPaginationState): GlobalPaginationVars {
    return {
      first: event.pageSize,
      after: paginationState.endCursor,
      before: null,
      last: null,
    };
  }

  /**
 * Handles the logic for navigating to the previous page in a paginated list.
 *
 * @param event - The PageEvent object containing information about the current page and pagination settings.
 * @param paginationState - The current state of the global pagination, containing information such as the start cursor.
 *
 * @returns A new set of pagination variables to be used for fetching the previous page of data.
 *          The returned object includes the first, after, before, and last properties, which represent the pagination settings.
 *          In this case, the first and after properties are set to null, the before property is set to the start cursor from the pagination state,
 *          and the last property is set to the page size.
 */
  handlePreviousPage(event: PageEvent, paginationState: GlobalPaginationState): GlobalPaginationVars {
    return {
      first: null,
      after: null,
      before: paginationState.startCursor,
      last: event.pageSize,
    };
  }

  /**
 * Handles the logic for navigating to the first page in a paginated list.
 *
 * @param event - The PageEvent object containing information about the current page and pagination settings.
 *
 * @returns A new set of pagination variables to be used for fetching the first page of data.
 *          The returned object includes the first, after, before, and last properties, which represent the pagination settings.
 *          In this case, the first property is set to the page size, the after and before properties are set to null.
 */
  goToFirstPage(event: PageEvent): GlobalPaginationVars {
    return {
      first: event.pageSize,
      after: null,
      before: null,
      last: null,
    };
  }

  /**
   * Handles the logic for navigating to the last page in a paginated list.
   *
   * @param event - The PageEvent object containing information about the current page and pagination settings.
   * @param paginationState - The current state of the global pagination, containing information such as the total elements and page size.
   *
   * @returns An object containing two properties:
   *          - paginationValues: A new set of pagination variables to be used for fetching the last page of data.
   *            The returned object includes the first, after, before, and last properties, which represent the pagination settings.
   *            In this case, the first and after properties are set to null, the before property is set to null,
   *            and the last property is set to the last page size.
   *          - lastPageSizeChange: The size of the last page.
   */
  goToLastPage(event: PageEvent): GlobalPaginationVars {
    let paginationValues: PaginationVars = {
      first: null,
      after: null,
      before: null,
      last: null,
    };

    // Calculate the appropriate last value based on the total length and page size
    if (event.length % event.pageSize !== 0) {
      //If the condition is true, it means there are remaining items that cannot form a complete page.
      paginationValues.last = event.length % event.pageSize;
    } else {
      //If the condition is false, it means the total length is evenly divisible by the page size, indicating 
      //that there are no remaining items.
      paginationValues.last = event.pageSize;
    }
    return paginationValues;
  }

  /**
 * Calculates the total number of pages based on the provided pagination state.
 *
 * @param paginationState - The current state of the global pagination, containing information such as the total elements and page size.
 *
 * @returns The total number of pages, calculated by dividing the total elements by the page size and rounding up to the nearest whole number.
 *          If either the total elements or page size is not provided, the function returns 0.
 */
  getNumberOfPages(paginationState: GlobalPaginationState): number {
    if (paginationState.totalElements && paginationState.pageSize) {
      return Math.ceil(paginationState.totalElements / paginationState.pageSize) - 1;
    }
    return 0;
  }
}