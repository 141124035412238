/**
 * Generates a formatted string representing the current date and time.
 *
 * @param {number} [timeOffset] - An optional time offset in minutes to adjust the current date and time.
 * @returns {string} - A string representing the formatted date and time in "YYYY-MM-DD HH:mm:ss" format.
 */
export const formatDateTimeToLocaleString = (timeOffset = 0) => {
    const date = new Date();
    date.setUTCMinutes(date.getUTCMinutes() + timeOffset);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
