import { LoginResponse } from "angular-auth-oidc-client/lib/login/login-response";
import { AuthenticationService } from "./features/authentication/services/authentication.service";
import { Component, HostListener, inject, OnDestroy, OnInit } from "@angular/core";
import { AzureService } from "./core/services/azure.service";
import { cleanSessionStorage, retrieveDecryptedFromSessionStorage, saveEncryptedToSessionStorage, verifySessionItem } from "./shared/helpers/sessionStorage.helper";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "User Portal";

  private azureService = inject(AzureService);
  private authenticationService = inject(AuthenticationService);

  constructor() {
    this.authenticationService.checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        const { isAuthenticated, userData, accessToken, idToken, configId, errorMessage } = loginResponse;
        // this.authenticationService.isAuthenticated$.next(isAuthenticated);
        //console.log(loginResponse);
        /*...*/
      });

    // this.authenticationService.sessionStateFromIDPListener();
    this.authenticationService.refreshTokenEventListener();
    this.authenticationService.IsAuthenticatedAlert();
    this.authenticationService.getUserDataFromFortify();
  }

  ngOnInit(): void {
    if (verifySessionItem('contentful')) {
      const contentful = retrieveDecryptedFromSessionStorage('contentful');
      this.azureService.contentSubject.next(contentful as string);
      cleanSessionStorage(['contentful']);
    }
    if(verifySessionItem('contentSpace')) {
      const contentSpace = retrieveDecryptedFromSessionStorage('contentSpace');
      this.azureService.spaceSubject.next(contentSpace as string);
      cleanSessionStorage(['contentSpace']);
    }
    if(verifySessionItem('csoPlans')) {
      const cso = retrieveDecryptedFromSessionStorage('csoPlans') as string;
      this.azureService.csoPlansSubject.next(JSON.parse(cso));
      cleanSessionStorage(['csoPlans']);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event): void {
    if (this.azureService.contentSubject.value != '') {
      saveEncryptedToSessionStorage('contentful', this.azureService.contentSubject.value);
    }
    if(this.azureService.spaceSubject.value != '') {
      saveEncryptedToSessionStorage('contentSpace', this.azureService.spaceSubject.value);
    }
    if (this.azureService.csoPlansSubject.value.free != '') {
      saveEncryptedToSessionStorage('csoPlans', JSON.stringify(this.azureService.csoPlansSubject.value));
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }
}
