<div class="callback-page-container">
    <ng-container *ngIf="!hasErrorOccurred">
        <div class="callback-page-card sm:mx-2.28">
            <img [src]="logo" alt="LCPtracker Logo" width="135px" height="auto">
            <div class="progress-spinner-container">
                <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [diameter]="diameter">
                </mat-progress-spinner>
            </div>
            <h4>{{header}}</h4>
            <p>{{tagline}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="hasErrorOccurred">
        <error-handle [errorCategoryCode]="ERROR_CODE" ></error-handle>
     </ng-container>
     <footer></footer>
</div>