import { AuthModule } from 'angular-auth-oidc-client';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialsModule } from 'src/app/core/materialIo.module';
import { AuthenticationRoutingModule } from './authentication.routing';

import { LandingComponent } from './pages/landing/landing.component';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { UsernameCredentialsComponent } from './components/username-credentials/username-credentials.component';
import { LoginDialogWrapperComponent } from './components/login-dialog-wrapper.component';
import { LoginBottomSheetWrapperComponent } from './components/login-bottom-sheet-wrapper.component';
import { LoginSSOComponent } from './pages/login-sso/login-sso.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoginSSOOnboardedComponent } from './pages/login-sso-onboarded/login-sso-onboarded.component';
import { PostLogoutComponent } from './pages/post-logout/post-logout.component';



@NgModule({
  imports: [
    AuthenticationRoutingModule,
    CommonModule,
    MaterialsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    LandingComponent,
    LoginCallbackComponent,
    UsernameCredentialsComponent,
    LoginBottomSheetWrapperComponent,
    LoginDialogWrapperComponent,
    LoginSSOComponent,
    LoginSSOOnboardedComponent,
    PostLogoutComponent
  ],
  exports: [AuthModule]
})
export class AuthenticationModule { }
