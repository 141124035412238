import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthCanaryService } from '../auth-canary.service';

export const handleCanaryGuard: CanActivateFn = async (next: ActivatedRouteSnapshot): Promise<boolean> => {

  // Extracting featureKey from the route's data.
  const featureKey = next.data['featureKey'];
  const router = inject(Router);
  const authCanaryService = inject(AuthCanaryService);

  try {

    const userPortalId = await authCanaryService.getUserPortalServiceId();
    const canaryUser = authCanaryService.getCanaryUserValue;
    const canaryOrg = authCanaryService.getCanaryOrgValue;

    // Check if the feature is enabled for the portal user.
    const claimResponse = await authCanaryService.getClaimsForCanary('network-only', featureKey, userPortalId.id);

    // If the feature is not enabled or no claims have a value of "true", redirect to the unauthorized page.
    // Can you create a method for this?
    const isFeatureEnabled =  authCanaryService.isFeatureEnabled(claimResponse);
    if (!isFeatureEnabled) {
      router.navigate(['/']);
      return false;
    }

    // If the user is not part of a canary organization or is not a canary user, restrict access.
    if (!(canaryOrg || canaryUser)) {
      router.navigate(['/']);
      return false;
    }

    // Return true as the feature is enabled and access is allowed.
    return true;
  } catch (error) {
    // Log the error and redirect to the unauthorized page.
    console.error('Error in handleCanaryGuard:', error);
    router.navigate(['/']);
    return false;
  }

}
