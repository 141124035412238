import { gql } from "apollo-angular";

/**
 * Evaluates if exists a claim between user and org
 * and if the user accepted the legal agreement, if the answer is 
 * null you have to add the agreement-->
 * this is in: mutations/add-agreement-claim-to-org.query.graphql.ts
 * If this got an answer like: 
 * {
  "data": {
    "claimsByParentId": {
      "id": "843feebf-8c88-4329-b2a7-c4445d131024",
      "parentId": "c71665df-ada3-417f-a0a8-27af86a3f6ac",
      "key": "CSO_AcceptedUserLic",
      "value": "true - User has accepted the new agreement.",
      "dateCreated": "2023-11-09T14:27:07.625Z",
      "dateExpire": null,
      "__typename": "Claim"
    }
  }
}
user already did the agreement validation.

number 4
 */

export const VERIFY_LIC_AGREEMENT = gql`
query verifyTermsAgreement($usersInOrgs:[UUID]!) {
  Agreements: claims(
    where: { and: [{ key: { eq: "CSO_AcceptedUserLic" } }, { parentId: { in: $usersInOrgs } }] }
  ) {
    AgreementByOrg: nodes {
      id
      key
      value
      parentId
      dateCreated
    }
  }
}
`;

export interface Agreement {
  id: string;
  key: string;
  value: string;
  parentId: string;
  dateCreated: string;
}

export interface AgreementResponse {
  Agreements: {
    AgreementByOrg: Agreement[];
  };
}