import { environment } from 'src/environments/environment'

export interface MENU {
  label: string;
  target: string;
  route: string;
  node: MenuNodeElement | null;
}

export interface MenuNodeElement {
  label: string;
  children: MENU[]
}


/*Common Module -> For All Users (Not needed any special role or condition):
  * Dashboard
  * Contact
  * Support
  * Profile
   */
export const COMMON_MENU_ITEMS: MENU[] = [
  {
    label: 'Dashboard',
    target: 'user-portal',
    route: '/dashboard',
    node: null
  },
  {
    label: 'Contact',
    target: 'user-portal',
    route: '/contact',
    node: null
  },
  {
    label: 'Support',
    target: 'user-portal',
    route: '/support',
    node: null
  },
  {
    label: 'Profile',
    target: 'fortify',
    route: environment.onboardingClaims.lastProfileChange,
    node: null
  },
]

/*Authentication Controls:
  * Password Management -> SupportStaff Role and Whitelist
  * SSO Integration -> IT Security Manager or IT Security Tester
  */
export const AUTHORIZATION_CONTROLS_MENU_OPTIONS: MENU = {
  label: 'Authentication Controls',
  target: '',
  route: '',
  node: {
    label: 'Authentication Controls',
    children: [],
  },
}

export const AUTHORIZATION_CONTROLS_PASSWORD_CHILD: MENU = {
  label: 'Password Management',
  target: 'user-portal',
  route: '/authorization-control/password-management/',
  node: null
}

export const AUTHORIZATION_CONTROLS_SSO_CHILD: MENU = {
  label: 'SSO Integration',
  target: 'user-portal',
  route: '/authorization-control/sso-integration/',
  node: null
}

/* Support Console:
  * Console -> SupportStaff Role and Whitelist and Valid Email (@cikume / @lcptracker)
  */
export const SUPPORT_CONSOLE_MENU_OPTION: MENU = {
  label: 'Support Console',
  target: 'user-portal',
  route: `console`,
  node: null
}


/* User Creator Module:
  * Console -> Create newUserInUserPortal role and whitelist.
  */
export const USER_CREATOR_OPTION: MENU = {
  label: 'User Creator',
  target: 'user-portal',
  route: '/user-creator/list',
  node: null
}

/* Delete Users Module:
  * Access -> RemoveUsers role
  */
export const DELETE_USERS_OPTION: MENU = {
  label: 'Delete Users',
  target: 'user-portal',
  route: '/delete-user',
  node: null
}

/* Delete Users Module:
* Access -> RemoveUsers role
*/
export const FEATURE_PANEL_OPTION: MENU = {
  label: 'Feature Management',
  target: 'user-portal',
  route: '/feature-management',
  node: null
}

/* Canary Users Module:
* Access -> Canary User/Org and Feature Enabled
*/
export const CANARY_OPTION: MENU = {
  label: 'Canary Module',
  target: 'user-portal',
  route: '/test-module',
  node: null
}
