import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SupportQuestion } from './support-question-card.interface';

@Component({
  selector: 'app-support-question-card',
  templateUrl: './support-question-card.component.html',
  styleUrls: ['./support-question-card.component.scss']
})
export class SupportQuestionCardComponent  {
  @ViewChild('expand') expand! : ElementRef;
  openExpandable: boolean = false;
  @Input() question! : SupportQuestion;

  constructor() { }

  /**
   * Opens / Close at the Mat Header Level, and stops DOM Bubbling.
   */
  openPanel(event : Event ) : void {
    // console.log(event);
    event.stopPropagation();
    this.openExpandable = !this.openExpandable;
  }

  /**
   * Sets the Browser to accept the link as a new entity not a route.
   */
  actionLink() : void {
    if(this.question.assetFile?.url){
      window.open(this.question.assetFile?.url, this.question.target);
      return
    }
    if(this.question.externalLink){
      window.open(this.question.externalLink, this.question.target);
      return
    }
  }

}
