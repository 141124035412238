import { gql } from "apollo-angular";

export const GET_PROFILE_INFO = gql`
  query getUserDataByUserId($id: String!) {
  userById(id: $id) {
    id
    firstName
    lastName
    email
    userName
    prefix
    suffix
    twoFactorEnabled
    emailConfirmed
    lockoutEnabled
    phoneExtension
    phoneNumber
    primaryIdentifierNumber
    suffix
    isPasswordExpired
    usersInOrg {
      userInOrgId: id
      ## ORGS BY USER
      org {
        id
        orgName
        tpaRule
        claimPrefix
        orgType
        dateStart
        orgCode
        parentOrgs {
          id
          parentOrgId
          dateExpire
          dateStart
          claimPrefix
          managementPrivilege
        }
        childOrgs {
          id
          childOrgId
          dateStart
          dateExpire
          dateCreated
        }
      }
      isStaff
      orgUsersForOrgRole {
        roleOrgId
        orgRole {
          ## ROLES BY SERVICE (USER PORTAL)
          roles: serviceRole {
            id
            name
            claimPrefix
            isTemplate
            dateStart
            dateExpire
          }
        }
      }
    }
  }
}
`;

export const GET_APP_TILES = gql`
  query getApps {
    homePageLinks(where: { isEnabled: { eq: true } }, order: { order: ASC }) {
      tilesNodes: nodes {
        name
        description
        uri
        order
        className
        requireAuth
        isPublicLink
        isEnabled
        staffOnly
      }
    }
  }
`;

export interface UserProfileResponse {
  userById: UserProfileData;
}
export interface UserProfileData {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  userName: string;
  prefix?: string | null;
  suffix?: string | null;
  twoFactorEnabled: boolean;
  emailConfirmed: boolean;
  lockoutEnabled: boolean;
  phoneExtension?: string | null;
  phoneNumber: string;
  primaryIdentifierNumber?: string | null;
  isPasswordExpired: boolean;
  usersInOrg: Org[];
}

export interface Org {
  userInOrgId: string;
  org: {
    id: string;
    orgName: string;
    tpaRule: string;
    claimPrefix: string;
    orgType: string;
    dateStart: string;
    orgCode: string;
    parentOrgs: any[];
    childOrgs: any[];
  };
  isStaff: boolean;
  orgUsersForOrgRole: OrgUsersForOrgRole[]; // You might want to create an interface for this too
}



export interface TileNode {
  name: string;
  description: string;
  uri: string;
  order: number;
  className: string;
  requireAuth: boolean;
  isPublicLink: boolean;
  isEnabled: boolean;
  staffOnly: boolean;
  __typename: string;
}

export interface HomePageLinks {
  tilesNodes: TileNode[];
}

export interface HomePageLinksResponse {
  homePageLinks: HomePageLinks;
}

export interface OrgUsersForOrgRole {
  __typename: string
  roleOrgId: string
  orgRole: OrgRole
}

export interface OrgRole {
  __typename: string
  roles: Roles
}

export interface Roles {
  __typename: string
  id: string
  name: string
  claimPrefix: string
  isTemplate: boolean
  dateStart: string
  dateExpire: any
}
export interface UserPersonalData{
  userName?: string | null
  firstName?: string | null;
  lastName?: string | null;
  email?: string;
  phoneNumber?: string;
  twoFactorEnabled?: boolean;
}
