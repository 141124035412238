<div class="mobile-layout-wrapper">
  <app-header [isOpen]="drawer.opened ? true : false" [mobileOnly]="true" [default]="false" (openNav)="drawer.toggle()">
  </app-header>
  <mat-sidenav-container class="side-nav-container">
    <mat-sidenav class="drawer-content" #drawer [mode]="'over'" [opened]="false" [ngSwitch]="selectedSubMenu"
      autoFocus="false">
      <div class="side-nav" *ngSwitchCase='"default"'>
        <div class="user-profile" *ngIf="profileCard || !hasErrorOccurredForProfile">
          <div class="user-profile-information">
            <!-- Waiting for profile pic -->
            <!-- <img class="user-profile-information-avatar-sidebar" src="assets/images/profile-worker1.jpg"
              alt="Profile photo"> -->
            <div class="user-profile-information-detail">
              <h5 class="text-2xl text-name">{{profileCard?.firstName ? profileCard?.firstName: '' }} {{profileCard?.lastName ?
                profileCard?.lastName: ''}}</h5>
              <!-- Waiting for profile card redesign -->
              <!-- <h5 class="text-company">{{profileCard.usersInOrg.length ? profileCard.usersInOrg[0].org.orgName : 'Company' }}</h5> -->
            </div>
          </div>
          <div class="user-profile-content">
            <span class="text-lg list-element-padding xl">Logged in as:</span>
            <!-- <mat-nav-list class="menu-drawer-profile">
              <a mat-list-item (click)="goToPanel('account-selector')"
                [ngClass]="{'long-text': selectedAccount.length > 27}">
                <p>{{selectedAccount}}</p>
                <mat-icon class="mat-18">navigate_next</mat-icon>
              </a>
            </mat-nav-list> -->
            <div class="user-profile-info">
              <span class="list-element-padding"><b>{{profileCard?.userName}}</b></span>
              <span class="list-element-padding">2FA is turned on</span>
            </div>
            <br>
            <br>
            <mat-nav-list class="menu-drawer-profile-action">
              <a id="menu-edit-profile" mat-list-item disableRipple [href]="profileFortifyUrl" rel="noopener noreferrer"
                (click)="drawer.toggle()" target="_blank">Edit profile</a>
              <br>
              <a id="menu-drawer-logout" mat-list-item disableRipple (click)="showLogoutConfirmation()">Logout</a>
            </mat-nav-list>
          </div>
        </div>
        <mat-divider></mat-divider>
        <mat-nav-list class="menu-drawer">
          <ng-container *ngFor="let menu of menuItems">
            <ng-container *ngIf="menu.target === 'user-portal'">
              <a *ngIf="!notMobileAllowed.includes(menu.label)" mat-list-item
                [routerLink]="menu.route" routerLinkActive="item-selected" (click)="drawer.toggle()">
                <p>{{menu.label}}</p>
              </a>
            </ng-container>
            <ng-container *ngIf="menu.target !== 'user-portal'">
              <a *ngIf="!notMobileAllowed.includes(menu.label)" mat-list-item [attr.href]="menu.route" target="_self"
                (click)="drawer.toggle()"
                (keyup.enter)="drawer.toggle()">
                <p>{{menu.label}}</p>
              </a>
            </ng-container>
          </ng-container>
        </mat-nav-list>
      </div>
      <!-- sub-menu second level -->
      <div class="sub-menu">
        <!-- <div *ngSwitchCase='"profile"'>
          <div class="sub-menu-nav-toolbar">
            <button (click)="goBackToMainMenu(true)" class="sub-menu-nav-toolbar-button" mat-flat-button disableRipple>
              <mat-icon>navigate_before</mat-icon>
              Back to Menu
            </button>
          </div>
          <div class="sub-menu-nav-toolbar-header">
            <h5>Profile</h5>
          </div>
          <mat-nav-list class="sub-menu-navigation-items">
            <a mat-list-item (click)="goToPanel('user-profile', 'User Support')">
              <p>User Support</p>
              <mat-icon class="mat-18">navigate_next</mat-icon>
            </a>
            <a mat-list-item (click)="goToPanel('user-profile', 'Company Support')">
              <p>Company Support</p>
              <mat-icon class="mat-18">navigate_next</mat-icon>
            </a>
          </mat-nav-list>
        </div> -->
        <div *ngSwitchCase='"support"'>
          <div class="sub-menu-nav-toolbar">
            <button (click)="goBackToMainMenu(true)" class="sub-menu-nav-toolbar-button" mat-flat-button disableRipple>
              <mat-icon>navigate_before</mat-icon>
              Back to Menu
            </button>
          </div>
          <div class="sub-menu-nav-toolbar-header">
            <h5>Support</h5>
          </div>
          <mat-nav-list class="sub-menu-navigation-items">
            <a mat-list-item (click)="goToPanel('user-support','Company Support')">
              <p>Company Support</p>
              <mat-icon class="mat-18">navigate_next</mat-icon>
            </a>
            <a mat-list-item (click)="goToPanel('user-support', 'User Support')">
              <p>User Support</p>
              <mat-icon class="mat-18">navigate_next</mat-icon>
            </a>
          </mat-nav-list>
        </div>
        <div *ngSwitchCase='"account-selector"'>
          <div class="sub-menu-nav-toolbar-account">
            <button (click)="goBackToMainMenu(true)" class="sub-menu-nav-toolbar-button" mat-flat-button disableRipple>
              <mat-icon>navigate_before</mat-icon>
            </button>
            <h5>Logged in as {{selectedAccount}}</h5>
          </div>
          <mat-nav-list class="sub-menu-navigation-items">
            <mat-form-field appearance="outline">
              <mat-label>Search</mat-label>
              <input [(ngModel)]="filteredText" name="searchBox" matInput />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <!-- | searchFilter:filteredText -->
            <ng-container *ngFor="let account of accounts ">
              <a mat-list-item class="mat-list-item-account" (click)="setAccount(account)"
                *ngIf="account !== selectedAccount">
                <p>{{account}}</p>
              </a>
            </ng-container>

          </mat-nav-list>
        </div>
        <!-- third level menu -->
        <div *ngSwitchCase='"user-profile"'>
          <div class="sub-menu-nav-toolbar">
            <button (click)="goBackToMainMenu(true)" class="sub-menu-nav-toolbar-button" mat-flat-button disableRipple>
              <mat-icon>navigate_before</mat-icon>
              Back to {{lastItemSelected | titlecase}}
            </button>
          </div>
          <div class="sub-menu-nav-toolbar-header">
            <h5>{{thirdLevelTitle}}</h5>

          </div>
          <mat-nav-list class="sub-menu-navigation-items">
            <a mat-list-item (click)="navigateFromSubLevel('profileexample')">
              <p>Profile 1</p>
            </a>
            <a mat-list-item (click)="navigateFromSubLevel('profileexample')">
              <p>Profile 2</p>
            </a>
            <a mat-list-item (click)="navigateFromSubLevel('profileexample')">
              <p>Profile 3</p>
            </a>
          </mat-nav-list>
        </div>
        <div *ngSwitchCase='"user-support"'>

          <div class="sub-menu-nav-toolbar">
            <button (click)="goBackToMainMenu(true)" class="sub-menu-nav-toolbar-button" mat-flat-button disableRipple>
              <mat-icon>navigate_before</mat-icon>
              Back to {{lastItemSelected | titlecase}}
            </button>
          </div>
          <div class="sub-menu-nav-toolbar-header">
            <h5>{{thirdLevelTitle}}</h5>
          </div>
          <mat-nav-list class="sub-menu-navigation-items">
            <a mat-list-item (click)="navigateFromSubLevel('supportexample')">
              <p>Support 1</p>
            </a>
            <a mat-list-item (click)="navigateFromSubLevel('supportexample')">
              <p>Support 2</p>
            </a>
            <a mat-list-item (click)="navigateFromSubLevel('supportexample')">
              <p>Support 3</p>
            </a>
          </mat-nav-list>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="side-nav-content">
        <app-notification [title]="'Notifications'"></app-notification>
        <!-- Router outlet projection -->
        <ng-content></ng-content>
        <!-- End Router outlet projection -->
        </div>
        <div class="side-footer-content">
          <app-footer [hasContentfulError]="hasErrorOccurred" [footerData]="footer"></app-footer>
        </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>