import { Injectable, inject, signal } from '@angular/core';
import { PaginationState, PaginationVars } from '../graphql/support-console.queries.graphql';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../consts/consts';
import { LOGIN_HISTORY_QUERY, Login, LoginHistoryQueryVars, LoginHistoryResponse } from '../graphql/login-history.queries.graphql';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class LoginHistoryService {
  private _graphqlHostService = inject(GraphqlHostService);

  //New Signals to replace the BehaviorSubjects
  readonly _paginationVars = signal<PaginationVars>(INIT_PAGINATION_VARS);
  readonly _paginationState = signal<PaginationState>(INIT_PAGINATION_STATE);
  readonly _lastPageSizeChange = signal<number>(0);

  /*Loading States*/
  applyPolicy!: WatchQueryFetchPolicy;
  readonly _isLoading = signal<boolean>(false);
  readonly _errorServer = signal<boolean>(false);

  userId: string = '';
  userName: string = '';
  
  // Switch Case for Query Vars
  getDataBasedOnVars(): LoginHistoryQueryVars {
    return {
      first: this._paginationVars()!.first,
      after: this._paginationVars()!.after,
      last: this._paginationVars()!.last,
      before: this._paginationVars()!.before,
      claimedUserName: this.userName,
      date: new Date()
    };
  }

  updatePageState(requestResponse: LoginHistoryResponse): PaginationState {
    let pageState: PaginationState;
      if (requestResponse.userLoginHistory.pageInfo.hasNextPage) {
        pageState = {
          endCursor: requestResponse.userLoginHistory.pageInfo.endCursor,
          startCursor: requestResponse.userLoginHistory.pageInfo.startCursor,
          totalElements: requestResponse.userLoginHistory.totalCount,
          pageSize: this._paginationVars()!.first ? this._paginationVars()!.first : this._paginationVars()!.last,
        };
      } else {
        // If it's the last page, determine the pageSize based on the totalCount
        const pageSizeOnLastPage: number | null = this._paginationVars()!.first ? this._paginationVars()!.first : this._paginationVars()!.last;
        pageState = {
          endCursor: null,
          startCursor: requestResponse.userLoginHistory.pageInfo.startCursor,
          totalElements: requestResponse.userLoginHistory.totalCount,
          pageSize: requestResponse.userLoginHistory.totalCount <= pageSizeOnLastPage! ? pageSizeOnLastPage : this._lastPageSizeChange(),
        };
      }
    return pageState;
  }

  getLoginHistory(policy: FetchPolicy, claimedUserName: string): Promise<Login> {
    this._errorServer.set(false);
    this._isLoading.set(true);
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const vars: LoginHistoryQueryVars = this.getDataBasedOnVars();
    vars.claimedUserName = claimedUserName;
    vars.date = this.getDateMonthBefore();
    const optionalContext = null;
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          LOGIN_HISTORY_QUERY,
          vars,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: LoginHistoryResponse) => {
          this._paginationState.set(this.updatePageState(response));
          resolve(response.userLoginHistory);
          this._isLoading.set(false);
        })
        .catch((error) => {
          this._isLoading.set(false);
          this._errorServer.set(true);
          reject(error);
        });
    });
  }

  getLastSessionMethod(policy: FetchPolicy, claimedUserName: string): Promise<Login> {
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const vars: LoginHistoryQueryVars = {
      first: 1,
      after: null,
      before: null,
      last: null,
      claimedUserName,
      date: this.getDateMonthBefore()
    };
    const optionalContext = null;
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          LOGIN_HISTORY_QUERY,
          vars,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: LoginHistoryResponse) => {
          resolve(response.userLoginHistory);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getDateMonthBefore(): Date {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const dateMonthBefore = new Date();
    dateMonthBefore.setMonth(currentMonth - 1);

    return dateMonthBefore;
  }
}