import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private trackerApi = environment.LcpTrackerApi;
  private isOnLocation$ = new BehaviorSubject<boolean>(true);


  constructor(private http: HttpClient) { }

  handleUserLocation(): void {

    const requestBody = { id: 'userPortal' };

    this.http.post(this.trackerApi, requestBody).subscribe(
      {
        next: (response) => {
          if (response) {
            this.setLocation(true)
          }
        },
        error: (error) => {
          if (error.status) {
            this.setLocation(true)
            return
          }

          if (!(error.status)) {
            this.setLocation(false);
          }
        }
      }
    );
  }

  setLocation(isAvailable: boolean) {
    this.isOnLocation$.next(isAvailable)
  }
  get isLocationAvailable(): Observable<boolean> {
    return this.isOnLocation$.asObservable();
  }

}
