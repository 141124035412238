import { gql } from "apollo-angular";

// 
export const GET_TWO_FACTOR_METHOD = gql`
query getTwoFactorMethod($userId: String!) {
    isTwoFactorEmailCodeEnabled(userId: $userId) {
        data
        id
        message
        status
    }
}`;

export const ENABLE_TWO_FACTOR_EMAIL_OTP = gql`
mutation enableTwoFactorEmailOtp($userId: String!){
    enableTwoFactorEmailCode(userId: $userId) {
        status
        data
        id
        message
    }
}`;

export interface TwoFactorMethod {
    status: number;
    data: boolean;
    id: string;
    message: string;
}

export interface TwoFactorMethodResponse {
    isTwoFactorEmailCodeEnabled: TwoFactorMethod;
}

export interface EnableEmailOtpResponse {
    enableTwoFactorEmailCode: TwoFactorMethod;
}