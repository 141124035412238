
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { getTargetUrl, retrieveDecryptedFromSessionStorage } from 'src/app/shared/helpers';


export const CsoLicenseGuard: CanActivateFn = () => {
    if (getTargetUrl() === '/dashboard') {
        return true;
    }
    
    const router = inject(Router);
    const isLandingRef = retrieveDecryptedFromSessionStorage('land_ref') ?? false;
    if (isLandingRef) {
        router.navigate(['/cso/plans']);
        return false
    }
    return true
};
