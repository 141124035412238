import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-creator-dialog',
  templateUrl: './user-creator-dialog.component.html',
  styleUrls: ['./user-creator-dialog.component.scss']
})
export class UserCreatorDialogComponent {
  @Input() username = '';
  @Input() title = 'Title';
  @Input() confirmButton = 'Accept';
  @Input() cancelButton = 'Cancel';
  @Input() loading = false;
  @Input() orgName = [''];
  @Input() roleName = ['']

  hasOrgs: boolean = false;
  htmlContent!: string;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      username: string;
      title: string;
      orgName:string[];
      roleName:string[];
      confirmButton: string;
      cancelButton: string;
      loading: boolean;
      hasOrgs:boolean;
    },
    public activeDialog: MatDialogRef<UserCreatorDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.username = this.data.username ? this.data.username : '';
    this.title = this.data.title;
    this.orgName = this.data.orgName;
    this.roleName = this.data.roleName;
    this.confirmButton = this.data.confirmButton;
    this.cancelButton = this.data.cancelButton;
    this.loading = this.data.loading;
    this.hasOrgs = this.data.hasOrgs;

  }

  onConfirm(value: boolean): void {
    this.activeDialog.close(value);
  }
}
