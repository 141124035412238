import { Injectable } from '@angular/core';


export const MAX_TIMEOUT_REQUEST_SECONDS: number = 10;

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {

  /**
   * Creates a custom timeout error response object with a predefined message.
   * @param service The name of the service where the error occurred.
   * @param method The name of the method where the error occurred.
   * @returns A custom timeout error response object.
   */
  timeoutErrorResponse(service: string, method: string): any {
    return {
      errorType: 'API TIMEOUT',
      message: `The ${service} API request in the ${method} method took longer than ${MAX_TIMEOUT_REQUEST_SECONDS} seconds to respond.`,
      origin: method,
      data: [],
    };
  }

  /**
  * Creates a custom server error response object with a predefined message.
  * @param service The name of the service where the error occurred.
  * @param method The name of the method where the error occurred.
  * @param error The error object or message from the server.
  * @returns A custom server error response object.
  */
  serverErrorResponse(service: string, method: string, error: any): any {
    return {
      errorType: 'API FAILED',
      message: `The ${service} API request in the ${method} method encountered an error: ${error}`,
      origin: method,
      data: [],
    };
  }

  saveRbsErrorToLocalStorage(): void {
    const key = 'catchRbsError';
    const value =  new Date();
    localStorage.setItem(key, JSON.stringify(value));
  }

  getStoredRbsError(): any {
    const storedErrors = localStorage.getItem('catchRbsError');
    if (storedErrors === null) {
      return null; // Or you can return an empty array or object if appropriate
    }
    return JSON.parse(storedErrors);
  }

  deleteRbsErrorFromLocalStorage(): void {
    const key = 'catchRbsError';
    localStorage.removeItem(key);
  }

}
