import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { getTargetUrl } from 'src/app/shared/helpers';

@Component({
  selector: 'app-login-sso-onboarded',
  templateUrl: './login-sso-onboarded.component.html',
  styleUrls: ['./login-sso-onboarded.component.scss'],
})
export class LoginSSOOnboardedComponent implements OnInit {
  paramId!: string;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly _router: Router
  ) { }

  ngOnInit(): void {

    /**
       * This code snippet is subscribing to the 'forceRefreshSession$()' method from the 'authenticationService'.
       * 'forceRefreshSession$()' returns an Observable which we subscribe to in order to handle the asynchronous operation.
       *
       * The 'next' function is executed when the Observable emits a new value, i.e., when the session refresh is successful.
       * In this case, it navigates to the target URL obtained from the 'getTargetUrl()' function.
       *
       * The 'error' function is executed when the Observable emits an error, i.e., when the session refresh fails.
       * In this case, it navigates to the root ('') route.
    */

    this.authenticationService.forceRefreshSession$().subscribe({
      next: () => {
        this._router.navigateByUrl(getTargetUrl());
      },
      error: () => {
        this._router.navigate(['']);
      },
    });
  }
}
