import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { FooterResponse } from 'src/app/shared/components/footer/footer-interface';
import { PortalSharedService } from 'src/app/shared/services/portal-shared/portal-contentful.service';
import { MENU } from '../menu';
import { MenuOptionsService } from '../services/menu-options.service';


export const portalDataResolver: ResolveFn<FooterResponse[]> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(PortalSharedService).fetchFooterComponent('cache-first');
}

export const UserPortalMenuResolverFn: ResolveFn<MENU[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  menuOptionsService: MenuOptionsService = inject(MenuOptionsService)
): Promise<MENU[]> => {

  return menuOptionsService.getMenuOptions();
}