<section class="login-history-container" mat-dialog-content>
  <div>
    <h5>Login history</h5>
    <div>
      <p class="name">{{data.name}}</p>
      <p class="email">{{data.email}}</p>
    </div>
  </div>
    <div class="login-history">
      <mat-progress-bar *ngIf="isLoadingSignal()" mode="indeterminate"></mat-progress-bar>
      <div class="my-5 login-history-wrapper">
        <div class="login-wrapper pl-0.57 flex flex-col" *ngFor="let item of loginHistoryGraphQL">
          <div class="login-time-wrapper">
            <div class="login-time-date">
              <mat-icon>{{item.node.browserInformation | handleUserAgent:'Device'}}</mat-icon>
              <p>{{item.node.event | date}} at {{item.node.event | date: 'shortTime'}}</p>
            </div>
  
            <div class="login-time-state">
              <span
                class="inline-flex items-center justify-center px-2 py-px mx-2 text-sm font-medium rounded-md ring-1 ring-inset w-44"
                [ngClass]="validateAuthTypeBadgeColor(item.node.authType) ? 'bg-red-50 text-red-700 ring-red-600/10' : 'bg-green-50 text-green-700 ring-green-600/20'">
                <mat-icon class="mr-2">{{validateAuthTypeBadgeColor(item.node.authType) ? 'close' : 'done'}}</mat-icon>
                {{validateAuthTypeMessage(item.node.authType)}}
              </span>
            </div>
          </div>
  
          <div class="login-system-detail">
            <div class="login-system-detail-element">
              <p class="label">IP</p>
              <p class="ml-2 value">{{item.node.gatewayIPAddress}}</p>
            </div>
            <div class="login-system-detail-element">
              <p class="label">Browser</p>
              <p class="value">{{item.node.browserInformation | handleUserAgent:'Browser'}}</p>
            </div>
            <div class="login-system-detail-element">
              <p class="label">Operative System</p>
              <p class="value">{{item.node.browserInformation | handleUserAgent:'Os'}}</p>
            </div>
          </div>
          <div class="divider">
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
      <div *ngIf="errorServerSignal()" class="no-results-table-banner-error">
        <p class="text-center title">Apologies, an error occurred while retrieving the login history. Please try again
          later.</p>
        <p class="subtitle">If the issue persists, please contact our support team for assistance.</p>
      </div>
    </div>
  <div class="dialog-footer">
    <div [hidden]="loginHistoryGraphQL.length === 0">
      <mat-paginator #paginator [length]="this.paginationStateSignal().totalElements" [pageSize]="this.paginationStateSignal().pageSize"
        [pageSizeOptions]="[5, 10]" aria-label="Select page" showFirstLastButtons="true" (page)="handlePage($event)">
      </mat-paginator>
    </div>
    <div class="button-container">
      <button mat-button [mat-dialog-close]="true">Close</button>
    </div>
  </div>
</section>
