import { Component, Input, OnInit } from '@angular/core';
import { UserPersonalData } from 'src/app/features/dashboard/graphql/profile-info.query';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss']
})
export class UserProfileCardComponent implements OnInit {

  avatarName:string | null = null;
  fullName:string | null = null;
  profileFortifyUrl:string = environment.onboardingClaims.lastProfileChange;
  @Input() user:UserPersonalData | null = null;

  ngOnInit(): void {
    if (this.user && this.user.firstName && this.user.lastName) {
      // Obtener la primera letra del firstName y del lastName
      const firstInitial = this.user.firstName.charAt(0).toUpperCase();
      const lastInitial = this.user.lastName.charAt(0).toUpperCase();
      this.fullName = this.user.firstName + ' ' + this.user.lastName;
      // Combinar las iniciales para formar el avatarName
      this.avatarName = firstInitial + lastInitial;
    }else{
      this.fullName = '--'
    }
  }

}
