import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';


// This guard handles General users Roles
export const roleHandlerGuard: CanActivateFn = (snapShop: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    const originRoles: string = snapShop.data['origin'];
    const requiredRoles: string[] = snapShop.data['roles'];

    // Merge roles if 'Fortify_RBS' origin is specified
    let userRoles;
    if (originRoles === 'Fortify_RBS') {
        userRoles = [...authenticationService.fortifyRoles.value, ...authenticationService.rbsRoles.value];
    } else {
        userRoles = originRoles === 'Fortify' ? authenticationService.fortifyRoles.value : authenticationService.rbsRoles.value;
    }

    // Check required roles based on mode
    let hasAllRequiredRoles = false;
    if (snapShop.data['mode'] === 'shouldHaveAll') {
        hasAllRequiredRoles = shouldHaveAll(requiredRoles, userRoles);
    } else if (snapShop.data['mode'] === 'shouldHaveOne') {
        hasAllRequiredRoles = shouldHaveOne(requiredRoles, userRoles);
    }

    // Redirect if required roles are not met
    if (!hasAllRequiredRoles) {
        router.navigate(['/dashboard']);
    }

    return hasAllRequiredRoles;
};



// user should have at least one role
const shouldHaveOne = (requiredRoles: string[], userRoles: string | string[]): boolean => {
    if (!Array.isArray(userRoles)) {
        return requiredRoles.includes(userRoles)
    }
    return userRoles.some(role => requiredRoles.includes(role));
}

// user should have all required roles
const shouldHaveAll = (requiredRoles: string[], userRoles: string | string[]): boolean => {
    if (!Array.isArray(userRoles)) {
        return requiredRoles.includes(userRoles)
    }
    return requiredRoles.every(role => userRoles.includes(role));
}