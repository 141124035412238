import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, take } from 'rxjs';
import {
  Certified,
  LcpTracker,
} from '../../interfaces/auth-interface';
import { environment } from 'src/environments/environment';
import { LocationService } from 'src/app/shared/services/location/location.service';

export const FORTIFY_2_2: string = '2_2';
export const FORTIFY_NET_6: string = '6';
export const SOURCE_TYPE: number = 0;
@Component({
  selector: 'username-credentials',
  templateUrl: './username-credentials.component.html',
  styleUrls: ['./username-credentials.component.scss'],
})
export class UsernameCredentialsComponent implements OnInit, AfterViewInit {
  isDesktop: boolean = false;
  action: string = 'delete';
  username: string = '';
  bottomSheetRef!: MatBottomSheetRef<UsernameCredentialsComponent>;
  matDialogRef!: MatDialogRef<UsernameCredentialsComponent>;
  logo: string = 'assets/images/lcptracker-full-color.svg';
  userEmail = new FormControl('', [Validators.required,  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
  isAlertMessageON: boolean = false;
  trackerImg = './assets/images/icon_LCPtrackerPro.svg';
  certifiedImg = './assets/images/icon_LCPcertified.svg';
  trackerFlag: boolean = false;
  certifiedFlag: boolean = false;
  productSelection: boolean = false;
  contactLink = 'https://lcptracker.com/contact/contact-support';
  fortifyType: boolean = false;

  @ViewChild('usernameEmail') usernameEmail!: ElementRef<HTMLInputElement>;
  @Output() closeComponent: EventEmitter<boolean> = new EventEmitter();
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  isLoading$ = this.isLoadingSubject.asObservable();
  isLocationAvailable = false;
  ErrorMessage = '';

  constructor(
    public authenticationService: AuthenticationService,
    private locationService: LocationService,
  ) {}

  ngOnInit(): void {
    this.isLoadingSubject.next(false);
    this.locationService.isLocationAvailable
      .pipe(take(1))
      .subscribe((isOnLocation: boolean) => {
        this.isLocationAvailable = isOnLocation;
      });
  }

  ngAfterViewInit(): void {
    this.focusOnUsername();
  }

  /**
   * focus on username when dialog / bottom sheet opens up.
   */
  focusOnUsername(): void {
    if (this.usernameEmail !== undefined) {
      this.usernameEmail.nativeElement.focus();
    }
  }
  /**
   * when the username value exists : check with Waldo to determine which fortify version exists.
   */
  login(): void {
    this.ErrorMessage = '';
    if (this.userEmail.value != null) {
      this.isLoadingSubject.next(true);
      if (!this.isLocationAvailable) {
        this.ErrorMessage =
          'We have identified that you are attempting to access LCPtracker at a location outside of the U.S. Please contact LCPtracker Support for further assistance.';
        this.isLoadingSubject.next(false);
        return;
      } else {
        this.isAlertMessageON = false;
        this.retrieveTrackerUserInfo();
      }
    }
  }

  /**
   * Check if user exists in LCPtrackerApi
   * then calls retrieveCertifiedUserInfo
   */
  retrieveTrackerUserInfo(): void {
    if (!this.userEmail.value) {
      return;
    }

    this.authenticationService
      .checkUserExistsTrackerPro(this.userEmail.value)
      .subscribe({
        next: (res: LcpTracker) => {
          this.fortifyType = res.UsesFortify;
          if (this.fortifyType) {
            this.trackerFlag = false;
          } else {
            this.trackerFlag = true;
          }
        },
        error: () => {
          console.error('User not found in LCPtrackerApi');
          this.retrieveCertifiedUserInfo();
        },
        complete: () => {
          this.retrieveCertifiedUserInfo();
        },
      });
  }

  /**
   * Check if user exists in CertifiedApi
   * then calls checkApiSourceResponse
   */
  retrieveCertifiedUserInfo(): void {
    if (!this.userEmail.value) {
      return;
    }

    this.authenticationService
      .checkUserExistsCertified(this.userEmail.value)
      .subscribe({
        next: (res: Certified) => {
          this.fortifyType = res.UsesFortify;
          if (this.fortifyType) {
            this.certifiedFlag = false;
          } else {
            this.certifiedFlag = true;
          }
        },
        error: () => {
          console.error('User not found in CertifiedApi');
          this.checkApiSourceResponse(this.trackerFlag, this.certifiedFlag);
        },
        complete: () => {
          this.checkApiSourceResponse(this.trackerFlag, this.certifiedFlag);
        },
      });
  }

  /**
   * Evaluate tracker and certified flags to select where
   * user wants to be redirected
   */
  checkApiSourceResponse(trackerFlag: boolean, certifiedFlag: boolean): void {
    if (this.fortifyType) {
      this.authenticationService.login(this.userEmail.value || '');
      return;
    }

    if (trackerFlag && !certifiedFlag) {
      //source type = 2 if different to 0, need to redirect fortify v2
      this.trackerRedirect();
      return;
    }

    if (!trackerFlag && certifiedFlag) {
      this.certifiedRedirect();
      return;
    }

    if (trackerFlag && certifiedFlag) {
      this.productSelection = true;
      return;
    } else {
      this.authenticationService.login(this.userEmail.value || '');
    }

    this.isAlertMessageON = true;
  }

  /**
   * If Username only exists in TrackerAPI this method is
   * called to redirect user from User Portal to Tracker CDN
   */
  trackerRedirect(): void {
    location.href = `${environment.applications.lcptracker.login}/login/login/${this.userEmail.value}/password`;
  }

  /**
   * If Username only exists in CertifiedAPI this method is
   * called to redirect user from User Portal to Certified CDN
   */
  certifiedRedirect(): void {
    location.href = `${environment.applications.certified.login}/login/login/${this.userEmail.value}/password`;
  }

  emitCloseComponentEvent() {
    this.closeComponent.emit(true);
  }
}
