import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../../components/custom-snackbar/custom-snackbar.component';
import { OrganizationProviderSelectionTPAComponent } from 'src/app/features/authorization-control/components/organization-provider-selection/organization-provider-selection.component';
import { UserCreatorDialogComponent } from '../../components/user-creator-dialog/user-creator-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UserPortalDialogService {
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  userPortalDialog(
    title: string,
    body: string | any,
    confirmButton: string | null,
    cancelButton: string | null,
    width: string = '430px',
    loading: boolean = false
  ): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      width: width,
      height: 'auto',
      autoFocus: false,
      panelClass: 'custom-mat-dialog-container',
      disableClose: true,
      data: {
        title,
        body,
        confirmButton,
        cancelButton,
        loading
      },
    });
  }

  /**
  * Opens the generic snackBar with the specified message
  * @param message
  */
  openUserPortalSnackBar(message: string, verticalPosition: "top" | "bottom" = "top") {
    this.snackBar.open(message, 'OK', {
      horizontalPosition: 'center',
      verticalPosition: verticalPosition,
      duration: 5000,
    });
  }

  /**
  * Opens a custom snackBar with the specified message
  * @param message
  */
  openCustomUserPortalSnackBar(message: string, action: string): void {
    const snackbarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 6000,
    });
    snackbarRef.instance.message = message;
    snackbarRef.instance.action = action;
  }


  openCustomTPAPickerDialog(
    tpaRule?: string
  ): MatDialogRef<OrganizationProviderSelectionTPAComponent> {
    return this.dialog.open(OrganizationProviderSelectionTPAComponent, {
      width: '600px',
      height: 'auto',
      autoFocus: true,
      panelClass: 'custom-mat-dialog-container',
      disableClose: false,
      data: {
        tpaRule
      }
    });
  }

  userPortalCreationUserDialog(
    title: string,
    username:string,
    orgName:string[],
    roleName:string[],
    confirmButton: string | null,
    cancelButton: string | null,
    hasOrgs:boolean,
    width: string = '600px',
    loading: boolean = false,

  ): MatDialogRef<UserCreatorDialogComponent> {
    return this.dialog.open(UserCreatorDialogComponent, {
      width: width,
      height: 'auto',
      autoFocus: false,
      panelClass: 'custom-mat-dialog-container',
      disableClose: true,
      data: {
        title,
        username,
        orgName,
        roleName,
        confirmButton,
        cancelButton,
        hasOrgs,
        loading
      },
    });
  }

}