import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
//
// Non-Angular : Will need a module for unit testing.
import { MaterialsModule } from './core/materialIo.module';
import { GraphQLModule } from './core/graphql.module';
import { OidcConfigModule } from './core/oidc-config.module';
import { SharedModule } from './shared/shared.module';
//
// Services
import { AuthenticationService } from './features/authentication/services/authentication.service';
import { GraphqlRBSInterceptor } from './shared/services/auth-interceptor/rbs-interceptor';
//
// Route Modules, Features, and other stuff
import { AppComponent } from './app.component';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { ContactModule } from './features/contact/contact.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { SupportModule } from './features/support/support.module';
import { SupportConsoleModule } from './features/support-console/support-console.module';
import { FortifyApiInterceptor } from './shared/services/auth-interceptor/fortify-interceptor';
import { IdentityProviderInterceptor } from './shared/services/auth-interceptor/identity-provider-interceptor';
import { ContentfulInterceptor } from './shared/services/auth-interceptor/contentfult-interceptor';


/*
******************************************************
 We have turned off the IDLE based on the new feature
 (#120886)
******************************************************* 
export const INACTIVITY_TIME_IN_SECONDS = 30;
export const LOG_OUT_COUNTDOWN_IN_SECONDS = 90;

********************************************************/

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    MaterialsModule,
    GraphQLModule,
    DashboardModule,
    ContactModule,
    SupportModule,
    SharedModule,
    SupportConsoleModule,
    /*
    ******************************************************
    We have turned off the IDLE based on the new feature
    (#120886)
    ******************************************************* 
    export const INACTIVITY_TIME_IN_SECONDS = 30;
    UserIdleModule.forRoot({
          idle: INACTIVITY_TIME_IN_SECONDS,
          timeout: LOG_OUT_COUNTDOWN_IN_SECONDS,
        }),
    ********************************************************/
    AuthenticationModule,
    OidcConfigModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideHttpClient(), // NEW HTTP CLIENT MODULE
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GraphqlRBSInterceptor, // Rbs API
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FortifyApiInterceptor, // Fortify API
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdentityProviderInterceptor, // Identity Provider
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentfulInterceptor, // Contentful interceptor
      multi: true,
    },
  ],
})
export class AppModule { }
