<section class="custom-dialog-container">
<!-- Modal Title -->
<h5 class="dialog_title" mat-dialog-title>{{title}}</h5>

<!-- Modal Component Wrapper -->
<div mat-dialog-content  class="modal-wrapper">

  <!-- Modal Content Container -->
  <main class="modal-content">
    <!-- Modal Content/Text -->
    <div [innerHTML]="htmlContent" class="body-row"></div>

    <div class="flex justify-center py-1" *ngIf="loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>


    <!-- Modal Actions/Buttons -->
    <div *ngIf="confirmButton || cancelButton" mat-dialog-actions class="buttons-row">

        <!-- Modal Cancel/Close Button -->
        <button *ngIf="cancelButton" mat-button mat-dialog-close color="primary"
          [id]="'cancel-' + id"
          >{{cancelButton}}
        </button>

        <!-- Modal Confirm Button -->
        <button *ngIf="confirmButton" (click)="onConfirm(true)" mat-raised-button color="primary"
          [id]="'confirm-' + id"
          >{{confirmButton}}
        </button>
      </div>

  </main>
</div>
</section>

