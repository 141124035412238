import { Injectable, inject, signal } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { ChangeLogQueryVars, ChangeLogResponse, ChangeLogs, SUPPORT_CONSOLE_GET_CHANGE_LOGS } from '../graphql/change-log.queties.graphql';
import { environment } from 'src/environments/environment';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../consts/consts';
import { PaginationState, PaginationVars } from '../graphql/support-console.queries.graphql';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {
  private _graphqlHostService = inject(GraphqlHostService);

  //New Signals to replace the BehaviorSubjects
  readonly _paginationVars = signal<PaginationVars>(INIT_PAGINATION_VARS);
  readonly _paginationState = signal<PaginationState>(INIT_PAGINATION_STATE);
  readonly _lastPageSizeChange = signal<number>(0);
  readonly _onSearchText = signal<string>(this.currentDateInit());
  onChangeLogDataChanged: Subject<any> = new Subject();

  /*Loading States*/
  applyPolicy!: WatchQueryFetchPolicy;
  readonly _loading = signal<boolean>(false);
  readonly _errorServer = signal<boolean>(false);

  constructor() { }

  /**
  * Searches and filters the data based on the provided criteria.
  * @param searchCriteria The search criteria to filter the data.
  * @param filter The optional filter criteria to further refine the search.
  */
  searchBy(searchCriteria: string): void {
    this._onSearchText.set(searchCriteria);
  }

  // Switch Case for Query Vars
  getDataBasedOnVars(): ChangeLogQueryVars {
    return {
      dateAt: this._onSearchText() + ' 23:59:59',
      first: this._paginationVars().first,
      after: this._paginationVars().after,
      last: this._paginationVars().last,
      before: this._paginationVars().before,
    };
  }

  currentDateInit(): string {
    const currentDate = Date.now();
    const date = new Date(currentDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // add 1 to get 1-based index
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  }

  updatePageState(requestResponse: ChangeLogResponse): PaginationState {
    let pageState: PaginationState;
    if (requestResponse.editLogs.pageInfo.hasNextPage) {
      pageState = {
        endCursor: requestResponse.editLogs.pageInfo.endCursor,
        startCursor: requestResponse.editLogs.pageInfo.startCursor,
        totalElements: requestResponse.editLogs.totalCount,
        pageSize: this._paginationVars().first ? this._paginationVars().first : this._paginationVars().last,
      };
    } else {
      const pageSizeOnLastPage: number | null = this._paginationVars().first ? this._paginationVars().first : this._paginationVars().last;
      pageState = {
        endCursor: null,
        startCursor: requestResponse.editLogs.pageInfo.startCursor,
        totalElements: requestResponse.editLogs.totalCount,
        pageSize: requestResponse.editLogs.totalCount <= pageSizeOnLastPage! ? pageSizeOnLastPage : this._lastPageSizeChange(),
      };
    }
    return pageState;
  }

  getChangeLogs(policy: FetchPolicy, userId: string): Promise<ChangeLogs> {
    this._loading.set(true);
    this._errorServer.set(false);
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    const vars: ChangeLogQueryVars = this.getDataBasedOnVars();
    vars.userId = userId;
    const optionalContext = null;
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          SUPPORT_CONSOLE_GET_CHANGE_LOGS,
          vars,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: ChangeLogResponse) => {
          this._paginationState.set(this.updatePageState(response));
          this.onChangeLogDataChanged.next(response.editLogs.edges);
          resolve(response.editLogs);
          this._loading.set(false);
        })
        .catch((error) => {
          this._loading.set(false);
          this._errorServer.set(true);
          reject(error);
        });
    });
  }
}