import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { MENU } from './menu';
import { LayoutStateService } from './services/layout-state.service';
import { ActivatedRoute } from '@angular/router';
import { getTargetUrl } from '../../helpers';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit, OnDestroy {

  menu!: MENU[];
  isDesktop = new Observable();
  private _unsubscribeAll = new Subject<boolean>();

  authenticationService = inject(AuthenticationService);
  layoutStateService = inject(LayoutStateService);
  isEnvAllowed: boolean = false;
  private readonly route: ActivatedRoute = inject(ActivatedRoute);


  ngOnInit(): void {
    this.isEnvAllowed = getTargetUrl() === '/cso/verify';
    this.isDesktop = this.layoutStateService.isDesktopLayout$.pipe(takeUntil(this._unsubscribeAll));
    this.getUserPortalMenu();
  }

  /*
* Retrieves user portal menu using route data resolver.
*/
  getUserPortalMenu(): void {
    this.route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (response) => {
        if (response['menu']) {
          this.menu = response['menu'];
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
  }
}
