import { gql } from 'apollo-angular';

export const UPDATE_USER_DATA = gql`
    mutation updateUserData(
        $userName: String!,
        $email: String!,
        $firstName: String!,
        $lastName: String!,
        $phoneNumber: String!
    ){
        updateUserData(
            input:{
                userName: $userName
                email: $email
                firstName: $firstName
                lastName: $lastName
                phoneNumber: $phoneNumber
            }
        ) {
            data {
                updated
            }
            errors{
                message
            }
        }
    }
`;

export interface UpdateUserDataModel {
    errors: {
        message: string
    };
    data: {
        updated: boolean
    }
}

export interface UpdateUserDataResponse {
    updateUserData: UpdateUserDataModel;
}

export interface UpdateUserData {
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}