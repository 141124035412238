import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { UsersService } from './../../services/users.service';
import { PAGINATION, INIT_PAGINATION_STATE, INIT_PAGINATION_VARS, SEARCH_FILTER_OPTIONS } from './../../consts/consts';
import { UsersEdge } from '../../graphql/support-console.queries.graphql';
import { EMPTY_SEARCH_VALUE_FOR_CUSTOM_USER_QUERY } from 'src/app/core/consts/core-userportal-const';
import { PaginationService } from 'src/app/core/services/pagination.service';

export const MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT = '1000';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  private _usersService = inject(UsersService);
  private readonly _paginationService = inject(PaginationService);

  //Pagination Signals
  paginationStateSignal = this._usersService._paginationState;
  paginationVarsSignal = this._usersService._paginationVars;
  // User Data and Loading Signal
  isLoadingSignal = this._usersService._loading;
  usersDataSignal = this._usersService._userData;

  /*Pagination and Search*/
  @ViewChild(MatPaginator) paginatorTop!: MatPaginator;
  combinedPageIndexSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  FILTER_OPTIONS = SEARCH_FILTER_OPTIONS;
  /*Forms*/
  searchCriteria = new FormControl<string>('', Validators.minLength(2));
  filterSearchCriteria = new FormControl<string>(this.FILTER_OPTIONS[0].criteria);
  /*Banner States*/
  expandPanelClicked!: string;
  expandPanelAfterRequest: string | null = null;
  showNotSupportBanner: boolean = false;
  twoFAtooltipText: string = '';
  /*Login History*/
  showFullAuthTypeBadge: boolean = false;
  editUserInfo: boolean = false;

  /*Handle Unsubcriptions*/
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _breakpointObserver: BreakpointObserver,
    public _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.bannerState();
    this.initValuesPaginationAndSearch();
    this.loginHistoryAuthTypeBadgeStyle();
    this.onFilterCriteriaFieldChanges();
    this.onSearchCriteriaFieldChanges();
    this.getUsers();
  }

  /**
   * Init the values related to pagination and search text.
   * This method is called to restore initial values for pagination variables,
   * pagination state, and search text in the UsersService.
   */
  initValuesPaginationAndSearch(): void {
    // Init pagination variables to initial values
    this._usersService._paginationVars.set(INIT_PAGINATION_VARS);
    // Init pagination state to initial values
    this._usersService._paginationState.set(INIT_PAGINATION_STATE);
    // Init last page size change
    this._usersService._lastPageSizeChange.set(0);
    // Clear the search text by setting it to an empty string
    this._usersService._onSearchText.set(EMPTY_SEARCH_VALUE_FOR_CUSTOM_USER_QUERY);
  }

  loadUserDataOnExpansionPanelOpen(user: UsersEdge): void {
    this.expandPanelClicked = user.node.id;
  }

  updateUsersFromChild($event: any) {
    this.getUsers();
    this.expandPanelAfterRequest = $event.expandPanelAfterRequest;
  }

  setExpandPanelAfterRequest(event: any) {
    this.expandPanelAfterRequest = event.expandPanelAfterRequest;
  }

  /**
   * Handles the search criteria text from the form input
   */
  onSearchCriteriaFieldChanges(): void {
    this.searchCriteria.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(650),
        distinctUntilChanged(),
      )
      .subscribe(formValue => {
        // When the form value is not empty and meets the minimum length requirement for searching
        if (formValue?.trim() && formValue.length >= PAGINATION.MINIMUM_LENGTH_TO_SEARCH) {
          const filter = this.filterSearchCriteria.value;
          this._usersService.searchAndFilterBy(formValue.trim(), filter);
          this.resetPaginationToInitialPage();
          this.getUsers();
        } else {
          // When the form value is empty or does not meet the minimum length requirement
          formValue ? this.evaluateValueLengthReset(formValue.length) : this.evaluateValueLengthReset(0);
        }
      });
  }

  /**
  * Listens for changes in the filter criteria selection.
  * Performs actions based on the selected value change.
  */
  onFilterCriteriaFieldChanges(): void {
    this.filterSearchCriteria.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe(selectedValue => {
      //Call a function or update the search criteria based on the selected value
      if (selectedValue && (this.searchCriteria?.value ?? '').length >= 2) {
        this.resetPaginationToInitialPage();
        const searchValue = this.searchCriteria?.value ?? ''; // Provide a default value of an empty string if it's undefined
        this._usersService.searchAndFilterBy(searchValue.toString(), selectedValue);
        // Update the search criteria or perform any other desired actions
        this.getUsers();
      }
    });
  }

  /**
   * Listens to changes in the screen width to display a banner
   * "Support Console does not have mobile support"
   */
  bannerState(): void {
    this._breakpointObserver
      .observe(`(max-width: ${MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT}px)`).pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((result): void => {
        this.showNotSupportBanner = result.matches;
      });
  }


  /**
   * Resets the pagination to the initial page forcefully
   */
  resetPaginationToInitialPage(): void {
    this._usersService._paginationVars.set(INIT_PAGINATION_VARS);
    this.expandPanelAfterRequest = null;
    this.paginatorTop.firstPage();
  }

  /**
   * Called when the clean Search Box button is clicked
   * Cleans the results of the search and resets the data and pagination
   * @param $clicked
   */
  cleanPaginationAndSearch(clicked?: true): void {
    this.usersDataSignal.set(null);
    this.paginatorTop.firstPage();
    this._usersService._onSearchText.set(EMPTY_SEARCH_VALUE_FOR_CUSTOM_USER_QUERY);
    if (clicked) {
      this.searchCriteria.reset();
      this._usersService._onfilterByCriteria.set('userName');
      this.filterSearchCriteria.patchValue('userName');
    }
    this.expandPanelAfterRequest = null;
    this._usersService._paginationVars.set(INIT_PAGINATION_VARS);
  }

  /**
   * Calls the service to get a list of users
   */
  async getUsers(): Promise<void> {
    try {
      await this._usersService.getUsers('network-only');
    } catch (error) {
      console.error('Something went wrong for getUsers', error);
    }
  }

  /**
   * Listens to changes in the screen width to change
   * the style of the AuthType Badge in Login History Preview
   */
  loginHistoryAuthTypeBadgeStyle(): void {
    this._breakpointObserver
      .observe(`(max-width: 1500px)`).pipe(
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((result): void => {
        this.showFullAuthTypeBadge = result.matches;
      });
  }

  /**
   * Evaluates from Child component the 2FA Method to show in tooltip
   * @param $event
   */
  showTwoFactorIndicator($event: any) {
    this.twoFAtooltipText = $event;
  }

  /**
   * Evaluates the length of the search string to clean up the data
   * or reset the default search
   * @param value
   */
  evaluateValueLengthReset(value: number): void {
    if (value === 0) {
      this.cleanPaginationAndSearch();
      this.getUsers();
    } else {
      this.cleanPaginationAndSearch();
    }
  }

 /****************************** PAGINATION METHODS **********************************/

  /**
   * Handles the pagination events integrated the global pagination service 
   * to calcute the correct pagination vars based on the page events
   */
  handlePage($event: PageEvent): void {
    let pageMovedToFirstOrLast = false;

    // SET NEW CURRENT PAGE SIZE
    if (this.paginationStateSignal()!.pageSize !== $event.pageSize) {
      const paginationSettings = this._paginationService.handlePageSizeChange($event);
      this._usersService._paginationVars.set(paginationSettings);
    }

    //FIRST PAGE
    if ($event.pageIndex === 0) {
      pageMovedToFirstOrLast = true;
      const paginationSettings = this._paginationService.goToFirstPage($event);
      this._usersService._paginationVars.set(paginationSettings);
    }

    //LAST PAGE
    if ($event.pageIndex == this._paginationService.getNumberOfPages(this.paginationStateSignal() ?? INIT_PAGINATION_STATE)) {
      pageMovedToFirstOrLast = true;
      this._usersService._lastPageSizeChange.set($event.pageSize);
      const paginationSettings = this._paginationService.goToLastPage($event);
      this._usersService._paginationVars.set(paginationSettings);
    }

    // PREVIOUS PAGE
    if ($event.previousPageIndex !== undefined && $event.pageIndex < $event.previousPageIndex && !pageMovedToFirstOrLast) {
      const paginationSettings = this._paginationService.handlePreviousPage($event, this.paginationStateSignal() ?? INIT_PAGINATION_STATE);
      this._usersService._paginationVars.set(paginationSettings);
    }

    // NEXT PAGE
    if ($event.previousPageIndex !== undefined && $event.pageIndex > $event.previousPageIndex && !pageMovedToFirstOrLast) {
      const paginationSettings = this._paginationService.handleNextPage($event, this.paginationStateSignal() ?? INIT_PAGINATION_STATE);
      this._usersService._paginationVars.set(paginationSettings);
    }

    this.getUsers();
  }
  /****************************** END OF PAGINATION METHODS **********************************/
  
  lockedEnd(lockedEnd: any): boolean {
    const now = new Date();
    const apiDate = new Date(lockedEnd);
    return apiDate > now;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }
}
