import { userDataGuard } from './shared/layouts/portal/guards/userData.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { CheckValidSessionGuard, AuthenticationGuard } from './features/authentication/guards/authentication.guard';
import { LandingComponent } from './features/authentication/pages/landing/landing.component';
import { PortalComponent } from './shared/layouts/portal/portal.component';
import { portalDataResolver, UserPortalMenuResolverFn } from './shared/layouts/portal/resolvers/portal-data.resolver';
import { SessionActiveGuard } from './features/authentication/guards/sessionActive.guard';
import { UserOnboardingGuard } from './features/authentication/guards/onboarded.guard';
import { emailHostGuard } from './shared/guards/supportStaff.guard';
import { whitelistGuard } from './features/support-console/guards/whitelist.guard';
import { roleHandlerGuard } from './shared/guards/roleHandler.guard';
import { CsoLicenseGuard } from './features/payment-cso/guards/cso-payment.guard';
import { environment } from 'src/environments/environment';
import { PostLogoutComponent } from './features/authentication/pages/post-logout/post-logout.component';
import { UnderConstructionComponent } from './shared/components/under-construction/under-construction.component';
import { handleCanaryGuard } from './shared/services/auth-canary/guard/handle-canary.guard';
import { ALLOWED_ENVIRONMENTS_GUARD } from './shared/helpers';


const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivate: [SessionActiveGuard, CheckValidSessionGuard],
    title: 'Welcome to LCPtracker',
  },
  {
    path: 'login',
    loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'cso',
    loadChildren: () => import('./features/payment-cso/payment-cso.module').then(m => m.PaymentCsoModule),
  },
  {
    path: '',
    children: [
      {
        path: '',
        component: PortalComponent,
        resolve: {
          menu: UserPortalMenuResolverFn,
          footer: portalDataResolver,
        },
        canActivate: [AuthenticationGuard, UserOnboardingGuard, userDataGuard, CsoLicenseGuard],
        children: [
          {
            path: 'dashboard',
            loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
            title: 'Dashboard - User Portal - LCPtracker',
          },
          {
            path: 'contact',
            loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule),
            title: 'Contact - User Portal - LCPtracker',
          },
          {
            path: 'support',
            loadChildren: () => import('./features/support/support.module').then(m => m.SupportModule),
            title: 'Support - User Portal - LCPtracker',
          },
          {
            path: 'console',
            loadChildren: () => import('./features/support-console/support-console.module').then(m => m.SupportConsoleModule),
            canActivate: [whitelistGuard, emailHostGuard, roleHandlerGuard,],
            title: 'Console - User Portal - LCPtracker',
            data: { roles: ['SupportStaff', 'SupportStaffManager'], mode: 'shouldHaveOne', origin: 'Fortify_RBS' }
          },
          {
            path: 'user-creator',
            loadChildren: () => import('./features/user-creator/user-creator.module').then(m => m.UserCreatorModule),
            canActivate: [roleHandlerGuard],
            title: 'Creator - User Portal - LCPtracker',
            data: { roles: ['CreateNewUserInUserPortal', 'SupportStaffManager'], mode: 'shouldHaveOne', origin: 'Fortify_RBS' }
          },
          {
            path: 'authorization-control',
            loadChildren: () => import('./features/authorization-control/authorization-control.module').then(m => m.AuthorizationControlModule),
            title: 'Authorization Control',
          },
          {
            path: 'delete-user',
            loadChildren: () => import('./features/delete-users/delete-users.module').then(m => m.DeleteUsersModule),
            title: 'Delete Users - User Portal - LCPtracker',
          },
          {
            path: 'feature-management',
            loadChildren: () => import('./features/experimental-features-management/experimental-features-management.module').then(m => m.ExperimentalFeaturesManagementModule),
            title: 'Experimental Features Panel - User Portal - LCPtracker',

          },
          {
            path: 'test-module',
            component: UnderConstructionComponent,
            title: 'Canary - LCPtracker',
            canActivate: [ALLOWED_ENVIRONMENTS_GUARD,handleCanaryGuard],
            data: { featureKey: 'Experimental-TestModule' }
          },
        ]
      },
    ]
  },
  {
    path: 'post-logout',
    component: PostLogoutComponent,
    title: 'Log out - User Portal - LCPtracker',
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      useHash: !(environment.env === 'local' || environment.env === 'dev')
    }
  ),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
