import { Injectable, inject } from '@angular/core';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { GET_PROVIDER_INFO_BY_USER_ID, UserExternalLogin, UserExternalLoginResponse } from '../graphql/providers-by-userId';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProvidersByUserService {

  /*Dependency Injections For Angular 14*/
  private _graphqlHostService = inject(GraphqlHostService);
  constructor() { }

  applyPolicy!: WatchQueryFetchPolicy;
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  isServerErrorSubject = new BehaviorSubject<boolean>(false);
  isServerError$ = this.isServerErrorSubject.asObservable();

  getExternalProvidersByUser(policy: FetchPolicy, userId: string): Promise<UserExternalLogin[]> {
    this.isLoadingSubject.next(true);
    if (policy !== null) {
      this.applyPolicy = policy;
    } else {
      this.applyPolicy = 'cache-first';
    }
    let vars = {userId}
    const optionalContext = null;
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          GET_PROVIDER_INFO_BY_USER_ID,
          vars,
          optionalContext,
          this.applyPolicy,
        )
        .then((response: UserExternalLoginResponse) => {
          resolve(response.userExternalLoginsByUserId);
          this.isLoadingSubject.next(false);
        })
        .catch((error) => {
          this.isLoadingSubject.next(false);
          this.isServerErrorSubject.next(true);
          reject(error);
        });
    });
  }
}
