import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handleUserAgent'
})
export class HandleUserAgentPipe implements PipeTransform {

  transform(userAgent: string, target: 'Os' | 'Browser' | 'Device'): string {

    if (!userAgent && target != 'Device') {
      return 'Unknown'
    }

    if (!userAgent && target == 'Device') {
      return 'laptop_mac'
    }

    if (target === 'Browser') {
      return this.getBrowser(userAgent)
    }

    if (target === 'Os') {
      return this.getOperatingSystem(userAgent)
    }

    if(target === 'Device' ){
     return this.getDevice(userAgent)
    }
    return userAgent
  }


  getBrowser(userAgent: string): string {
    const browsers = [
      { name: 'Opera', identifier: 'OPR' },
      { name: 'Edge', identifier: 'Edg' },
      { name: 'Chrome', identifier: 'Chrome' },
      { name: 'Firefox', identifier: 'Firefox' },
      { name: 'Safari', identifier: 'Safari' }
    ];
    const foundBrowser = browsers.find(browser => userAgent.includes(browser.identifier));
    return foundBrowser ? foundBrowser.name : 'Unknown';
  }

  getOperatingSystem(userAgent: string): string {
    const operatingSystems = [
      { name: 'iOS', identifier: 'iPhone' },
      { name: 'Android', identifier: 'Android' },
      { name: 'Windows', identifier: 'Win' },
      { name: 'Mac OS', identifier: 'Mac' },
      { name: 'Linux', identifier: 'Linux' }
    ];

    const foundOS = operatingSystems.find(os => userAgent.includes(os.identifier));
    return foundOS ? foundOS.name : 'Unknown OS';
  }

  getDevice(userAgent: string): string{
    if(userAgent.includes('Mobile')){
      return 'phone_android'
    }
    return 'laptop_mac'
  }


}
