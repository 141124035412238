
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';


export const emailHostGuard: CanActivateFn = () => {

    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const staffEmails = ['cikume.com', 'lcptracker.com']
    const isStaffMember = staffEmails.some(staffEmail => authenticationService.userName.value.includes(staffEmail))

    if (!isStaffMember) {
        router.navigate(['/dashboard']);
    }
    return isStaffMember;
};

