<!-- Modal Component Wrapper -->
<div class="modal-wrapper">

  <!-- Modal Title -->
  <h5 mat-dialog-title>User Services Agreement</h5>

  <!-- Modal Content Container -->
  <div mat-dialog-content class="modal-content">

    <div class="mb-1">
      <p>Welcome back!</p>
      <p>We have some exciting updates in our system. Let's get started:</p>
    </div>

    <!-- License list -->
    <div class="body-row">
      <ul>
        <li *ngFor="let item of orgLicenseDataByOrg">
          <p>A user from <strong>{{item.orgName}}</strong> has selected a <br>
            <strong>{{item.value.LicType | extractLicType}}</strong> for your organization.</p>
        </li>
      </ul>
    </div>

    <mat-checkbox color="primary" [formControl]="checkForm">Click here to agree the <a href="https://lcptracker.com/user-services-agreement/" target="_blank">User Services
        Agreement</a></mat-checkbox>

    <!-- Modal Actions/Buttons -->
    <div mat-dialog-actions align="end">
      <!-- Modal Cancel/Close Button -->
      <button mat-button mat-dialog-close color="primary">Decline</button>
      <!-- Modal Confirm/Close Button -->
      <button mat-raised-button mat-dialog-close cdkFocusInitial color="primary" (click)="onConfirm(true)"
        [disabled]="!checkForm.value">Continue</button>
    </div>
  </div>
</div>